

import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/component/shadcn/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { cn } from "@/lib/utils";

interface CalendarPopupProps {
    date?: Date | undefined;
    onSelect: (date?: Date) => void;
    buttonText?: string;
}

export default function CalendarPopup({ date, onSelect, buttonText = "Pick a date" }: CalendarPopupProps) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    className={cn(
                        "text-xs w-[300px] flex flex-row items-center gap-2 px-3 text-left font-normal ml-auto outline outline-1 outline-slate-200 justify-between data-[state=open]:bg-muted shadow-sm",
                        !date && "text-muted-foreground"
                    )}
                >
                    <div>
                        {date ? (
                            format(date, "PPP")
                        ) : (
                            <span className="text-xs">{buttonText}</span>
                        )}
                    </div>
                    <CalendarIcon className="h-4 w-4 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={onSelect}
                    disabled={(date) =>
                       date < new Date("1900-01-01")
                    }
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    );
}
