import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { FancyMultiSelect } from "@/component/MultiSelect";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Integration, ScopeResponse } from "@/interfaces/serverData";
import {
    ExclamationTriangleIcon,
    InfoCircledIcon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge, ChevronDownIcon, Separator } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import { useSearch } from "../SearchContext";

interface SLAPopupProps {
    selectedSource: string;
    setSelectedSource: React.Dispatch<React.SetStateAction<string>>;
    options: ScopeResponse[];
    setOptions: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    selectedChannels: ScopeResponse[];
    setSelectedChannels: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    breachTargetInput: string;
    setBreachTargetInput: React.Dispatch<React.SetStateAction<string>>;
    breachTargetTimeUnit: string;
    setBreachTargetTimeUnit: React.Dispatch<React.SetStateAction<string>>;
    notificationTimeInput: string;
    setNotificationTimeInput: React.Dispatch<React.SetStateAction<string>>;
    notificationTimeUnit: string;
    setNotificationTimeUnit: React.Dispatch<React.SetStateAction<string>>;
    showSelectedChannels: boolean;
    setShowSelectedChannels: React.Dispatch<React.SetStateAction<boolean>>;
    saveSLA: () => void;
    icon?: React.ElementType;
    handleSLASourceChange: (newSLASource: string) => void;
    triggerElement: React.ReactNode;
    subChannelsView: boolean;
    editing: boolean;
    clearSLADetails: () => Promise<void>;
}

const SLAPopup: React.FC<SLAPopupProps> = ({
    selectedSource,
    setSelectedSource,
    options,
    setOptions,
    selectedChannels,
    setSelectedChannels,
    breachTargetInput,
    setBreachTargetInput,
    breachTargetTimeUnit,
    setBreachTargetTimeUnit,
    notificationTimeInput,
    setNotificationTimeInput,
    notificationTimeUnit,
    setNotificationTimeUnit,
    showSelectedChannels,
    setShowSelectedChannels,
    saveSLA,
    icon,
    handleSLASourceChange,
    triggerElement,
    subChannelsView,
    editing,
    clearSLADetails,
}) => {
    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const [open, setOpen] = useState<boolean>(false);
    const [filteredSLAs, setFilteredSLAs] = useState([
        {
            label: "Slack",
            value: "Slack",
            color: "",
            key: "Slack"
        },
        {
            label: "CommunitySlack",
            value: "CommunitySlack",
            color: "",
            key: "CommunitySlack"
        },
        {
            label: "Discord",
            value: "Discord",
            color: "",
            key: "Discord"
        },
        {
            label: "Google",
            value: "Google",
            color: "",
            key: "Google"
        },
        {
            label: "Gmail",
            value: "Gmail",
            color: "",
            key: "Gmail"
        },
        {
            label: "GitHubTicket",
            value: "GitHubTicket",
            color: "",
            key: "GitHubTicket"
        },
    ]);

    const api = useApi();

    useEffect(() => {
        switch (selectedSource) {
            case "Slack":
            case "Discord":
            case "CommunitySlack":
            case "GitHubTicket": {
                api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${selectedSource}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        const dataItems: ScopeResponse[] =
                            res.data.data?.asm_ticket_channels;
                        setOptions(dataItems);
                    })
                    .catch((res) => { });
                break
            }
            case "Gmail": {
                api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            const integrationsResponse: Integration[] =
                                res.data.data;
                            const dataItems: ScopeResponse[] = [];
                            for (const integration of integrationsResponse) {
                                const scope: ScopeResponse = {
                                    key: integration.id,
                                    name: integration.unique_name
                                };
                                dataItems.push(scope);
                            }
                            setOptions(dataItems)
                        }
                    })
                    .catch((res) => {
                        console.error("Error fetching scope data:", res);
                    });
            }
        }
    }, [selectedSource, api, setOptions]);

    useEffect(() => {
        if (loading) {
            const updatedSLAs = filteredSLAs
                .filter((broadcast) => searchBarFilters.has(broadcast.value))
                .map((broadcast) => {
                    if (broadcast.value === "Google") {
                        return {
                            ...broadcast,
                            label: "Gmail",
                            value: "Gmail",
                        };
                    }
                    return broadcast;
                });
            setFilteredSLAs(updatedSLAs);
        }
    }, [searchBarFilters, loading]);

    const defaultSource = "Source...";

    const convertToSeconds = (value: number, unit: string) => {
        switch (unit) {
            case "seconds":
                return value;
            case "minutes":
                return value * 60;
            case "hours":
                return value * 60 * 60;
            case "days":
                return value * 24 * 60 * 60;
            default:
                return value;
        }
    };

    const validation = async () => {
        const breachLimitInSeconds = convertToSeconds(
            Number(breachTargetInput),
            breachTargetTimeUnit,
        );
        const notificationTimeInSeconds = convertToSeconds(
            Number(notificationTimeInput),
            notificationTimeUnit,
        );

        // Validate that notification time is less than breach limit time
        if (breachLimitInSeconds === 0) {
            setErrorMsg("Make sure breaching limit is not set to 0");
            setValidationErr(true);
        } else if (
            notificationTimeUnit !== "never" &&
            notificationTimeInSeconds > breachLimitInSeconds
        ) {
            setErrorMsg(
                "Make sure notification time is less than breach limit",
            );
            setValidationErr(true);
        } else if (selectedSource === defaultSource || selectedSource === "") {
            setErrorMsg("Please select a source!");
            setValidationErr(true);
        } else {
            saveSLA();
            setOpen(false);
            setValidationErr(false);
        }
    };

    const [slaTitle, setSLATitle] = useState<string>("New SLA");

    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErr, setValidationErr] = useState<boolean>(false);

    useEffect(() => {
        if (editing) {
            setSLATitle("Edit SLA");
        }
    }, [editing]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="pt-10 max-w-[850px] w-full">
                <div className="ml-10 mr-10">
                    <DialogHeader className="justify-left text-left items-left pb-5">
                        <DialogTitle>{slaTitle}</DialogTitle>
                        <DialogDescription>
                            SLAs (aka Service Level Agreements) are rules
                            for how quickly you want to respond and resolve
                            customer issues.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-row justify-between items-start">
                            <div className="flex flex-col items-start gap-2">
                                <p className="text-sm font-medium">
                                    Channel
                                </p>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Badge
                                            color="gray"
                                            size="2"
                                            radius="full"
                                            variant="outline"
                                            className="px-2 py-1 text-xs rounded-xl flex items-center justify-between min-w-[100px]"
                                        >
                                            <div className="flex items-center">
                                                {icon !== undefined &&
                                                    React.createElement(
                                                        icon,
                                                        {
                                                            className:
                                                                "w-4 h-4",
                                                        },
                                                    )}
                                                <p className="pl-1 pr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                                                    {selectedSource !== ""
                                                        ? selectedSource
                                                        : defaultSource}
                                                </p>
                                            </div>
                                            {/* ChevronDownIcon is placed outside the text/icon container to ensure it's at the end */}
                                            <ChevronDownIcon className="ml-auto" />
                                        </Badge>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        align="start"
                                        className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                    >
                                        <FilterDropdownElement
                                            type="SLA"
                                            filters={
                                                new Map<
                                                    string,
                                                    Set<string>
                                                >()
                                            }
                                            handleItemSelect={(
                                                type: string,
                                                option: { label: string; value: string; key: string; color: string },
                                            ) =>
                                                () => {
                                                    if (type === "SLA") {
                                                        handleSLASourceChange(
                                                            option.value,
                                                        );
                                                    }
                                                }}
                                            topics={[]}
                                            users={[]}
                                            extraOptions={filteredSLAs}
                                            customerGroups={[]}
                                            isSavedViewFilter={false}
                                            categories={[]}
                                            teams={[]}
                                            channels={
                                                new Map<
                                                    string,
                                                    ScopeResponse[]
                                                >()
                                            }
                                        />
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                            {selectedSource !== "" &&
                                selectedSource !== "Google" && (
                                    <Button
                                        className="text-xs outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                        size="sm"
                                        variant="outline"
                                        onClick={() => {
                                            setShowSelectedChannels(true);
                                        }}
                                        type="button"
                                    >
                                        add subchannels
                                        <PlusIcon />
                                    </Button>
                                )}
                        </div>
                        {(subChannelsView || showSelectedChannels) && (
                            <Card className="shadow-none mx-8">
                                <CardContent className="py-4 px-4 flex flex-row gap-5 justify-between items-center">
                                    <div className="flex flex-col gap-2 grow">
                                        <div className="flex flex-row gap-1">
                                            <p className="text-xs font-normal">
                                                Select subchannels
                                            </p>
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <InfoCircledIcon />
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        <p>
                                                            If you don't see
                                                            your channel
                                                            here, make sure
                                                            to check if it's
                                                            been selected
                                                            under Manage
                                                            Integrations
                                                            &gt; Customer
                                                            Channels for
                                                            this source.
                                                        </p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                        <FancyMultiSelect
                                            setSelectedChannels={
                                                setSelectedChannels
                                            }
                                            selectedChannels={
                                                selectedChannels
                                            }
                                            options={options}
                                            placeholder="subchannels..."
                                        />
                                    </div>
                                    <Button
                                        type="button"
                                        variant="outline"
                                        size="sm"
                                        className="flex-none"
                                        onClick={() => {
                                            setSelectedChannels([]);
                                            setShowSelectedChannels(false);
                                        }}
                                    >
                                        <TrashIcon />
                                    </Button>
                                </CardContent>
                            </Card>
                        )}
                        <Separator className="my-3" size="4" />

                        <div className="flex flex-col">
                            <div className="flex flex-row gap-1 items-center">
                                <p className="text-sm font-medium">
                                    Notification
                                </p>
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <InfoCircledIcon />
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>
                                                Make sure to set up and
                                                enable notifications under
                                                the Alerts tab.
                                            </p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>
                            <p className="text-xs font-small">
                                Get notified for when an issue breaches its
                                limit, and an additional notification as a
                                warning.
                            </p>
                        </div>
                        <div className="flex flex-row gap-8 justify-center">
                            {/* Ensure that all grid items align properly */}

                            <div className="flex flex-col items-center gap-2">
                                <p className="text-sm font-medium">
                                    Breach Limit
                                </p>

                                <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 py-1 shadow-sm bg-white h-11">
                                    <Input
                                        type="number"
                                        value={breachTargetInput}
                                        onChange={(event) =>
                                            setBreachTargetInput(
                                                event.target.value,
                                            )
                                        }
                                        className="px-3 min-w-[5rem] max-w-full text-center bg-transparent border-none outline-none"
                                        min="1"
                                        style={{
                                            width: `${Math.max(5, breachTargetInput.length)}rem`,
                                        }}
                                    />

                                    <Separator
                                        orientation="vertical"
                                        className="bg-[#D8D8D8] w-[1px] h-8 mx-3"
                                    />

                                    <select
                                        value={breachTargetTimeUnit}
                                        onChange={(event) =>
                                            setBreachTargetTimeUnit(
                                                event.target.value,
                                            )
                                        }
                                        className="text-xs text-gray-700 bg-white border-none focus:outline-none cursor-pointer"
                                    >
                                        <option value="hours">hours</option>
                                        <option value="days">days</option>
                                        <option value="minutes">
                                            minutes
                                        </option>
                                        <option value="seconds">
                                            seconds
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-col items-center gap-2">
                                <p className="text-sm font-medium">
                                    Breach Warning Notification
                                </p>

                                <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 py-1 shadow-sm bg-white h-11">
                                    {notificationTimeUnit !== "never" && (
                                        <Input
                                            type="number"
                                            value={notificationTimeInput}
                                            onChange={(event) =>
                                                setNotificationTimeInput(
                                                    event.target.value,
                                                )
                                            }
                                            className="px-3 min-w-[5rem] max-w-full text-center bg-transparent border-none outline-none"
                                            min="1"
                                            style={{
                                                width: `${Math.max(5, breachTargetInput.length)}rem`,
                                            }}
                                        />
                                    )}
                                    {notificationTimeUnit !== "never" && (
                                        <Separator
                                            orientation="vertical"
                                            className="bg-[#D8D8D8] w-[1px] h-8 mx-3"
                                        />
                                    )}
                                    <select
                                        value={notificationTimeUnit}
                                        onChange={(event) =>
                                            setNotificationTimeUnit(
                                                event.target.value,
                                            )
                                        }
                                        className="text-xs text-gray-700 bg-white border-none focus:outline-none cursor-pointer"
                                    >
                                        <option value="hours">hours</option>
                                        <option value="days">days</option>
                                        <option value="minutes">
                                            minutes
                                        </option>
                                        <option value="seconds">
                                            seconds
                                        </option>
                                        <option value="never">never</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter className="flex flex-col">
                        <div className="pt-4 justify-end text-end items-end">
                            <Button
                                className="bg-iris9"
                                type="submit"
                                onClick={validation}
                            >
                                Save
                            </Button>
                        </div>

                        {validationErr && (
                            <Alert variant="destructive" className="mt-4">
                                <div className="flex flex-row gap-3 items-center">
                                    <ExclamationTriangleIcon className="h-4 w-4" />
                                    <div>
                                        <AlertTitle>Error</AlertTitle>
                                        <AlertDescription>
                                            {errorMsg}
                                        </AlertDescription>
                                    </div>
                                </div>
                            </Alert>
                        )}
                    </DialogFooter>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SLAPopup;
