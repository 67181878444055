import { Button } from "@/component/shadcn/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSub, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { type AccountsLabel, AccountsLabelsType } from "@/interfaces/serverData";
import { getAccountsLabelIcon, getBadgeForAccountsLabel } from "@/utilities/methods";
import { DropdownMenuSubContent, DropdownMenuSubTrigger } from "@radix-ui/react-dropdown-menu";
import { CaretRightIcon, CheckIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface LabelsDropdownProps<TData> {
    selectedLabels: Map<AccountsLabelsType, AccountsLabel | undefined>;
    toggleLabel: (label: AccountsLabel, selectedLabels: Map<AccountsLabelsType, AccountsLabel | undefined>) => void;
    accountType: string;
}

export function LabelsDropdown<TData>({
    selectedLabels,
    toggleLabel,
    accountType
}: LabelsDropdownProps<TData>) {
    const { id } = useParams(); // team ID
    const api = useApi()
    const [filteredLabelsByGroup, setFilteredLabelsByGroup] = useState<Map<AccountsLabelsType, AccountsLabel[]>>(new Map());

    const fetchAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response: AxiosResponse<{ data: AccountsLabel[] }> =
            await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch accounts labels");
    };

    const fetchTeamAccountsLabels = async (): Promise<AccountsLabel[]> => {
        const { url } = ContactsAPI.getAccountsLabels;
        const response = await api.get(`${URLS.serverUrl}${url}/team/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const {
        data: accountsLabels = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<AccountsLabel[]>({
        queryKey: id ? [`teamAccountsLabels_${id}`] : ["accountsLabels"],
        queryFn: id
            ? () => fetchTeamAccountsLabels()
            : () => fetchAccountsLabels(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });


    useEffect(() => {
        const newLabelsByGroup = new Map<AccountsLabelsType, AccountsLabel[]>([
            [AccountsLabelsType.Tier, accountsLabels.filter(al => al.type === AccountsLabelsType.Tier)],
            [AccountsLabelsType.Stage, accountsLabels.filter(al => al.type === AccountsLabelsType.Stage)],
        ])
        if (accountType === "Company") {
            newLabelsByGroup.set(AccountsLabelsType.CompanyType, accountsLabels.filter(al => al.type === AccountsLabelsType.CompanyType))
        }
        setFilteredLabelsByGroup(newLabelsByGroup);
    }, [accountsLabels])

    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                asChild
                className="hover:bg-muted col-span-3 w-full"
                type="button"
                onClick={(e) => e.stopPropagation()}
            >
                <div className="w-full">
                    <Button
                        variant="ghost"
                        className="flex flex-wrap items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted !h-auto p-1.5"
                        type="button"
                    >
                        {(selectedLabels.size ?? 0) > 0 && Array.from(selectedLabels.values()).some(label => label !== undefined) ? (
                            Array.from(selectedLabels.values()).map((label: AccountsLabel | undefined) => {
                                if (label) {
                                    return getBadgeForAccountsLabel(label);
                                }
                            })
                        ) : (
                            "None"
                        )}
                        <Pencil2Icon />
                    </Button>
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="start"
                className="fixed w-[300px] p-0 bg-white rounded-md shadow-lg"
            >
                <div className="h-full max-h-[200px] overflow-y-auto">
                    {Array.from(filteredLabelsByGroup.entries()).map((entry) => {
                        const type = entry[0]
                        const labels = entry[1]
                        return (
                            <DropdownMenuSub key={type}>
                                <DropdownMenuSubTrigger className="flex items-center justify-between px-3 py-2 focus:outline-none focus:bg-accent" onClick={(e) => e.stopPropagation()} >
                                    <div className="flex items-center gap-2 py-0.5 text-xs">
                                        {getAccountsLabelIcon(type)}
                                        {type}
                                    </div>
                                    <CaretRightIcon />
                                </DropdownMenuSubTrigger>
                                <DropdownMenuSubContent className="rounded-md border bg-popover">
                                    {labels.map(label => {
                                        const isSelected =
                                            Array.from(selectedLabels.values()).map((l: AccountsLabel | undefined) => l?.id).includes(label.id);
                                        return (
                                            <DropdownMenuItem
                                                key={label.id}
                                                onSelect={() => {
                                                    toggleLabel(label, selectedLabels)
                                                }}
                                                className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                            >
                                                <div
                                                    className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${isSelected
                                                        ? "bg-primary text-primary-foreground"
                                                        : "opacity-50"
                                                        }`}
                                                >
                                                    {isSelected && (
                                                        <CheckIcon className="h-3 w-3" />
                                                    )}
                                                </div>
                                                {getBadgeForAccountsLabel(label)}
                                            </DropdownMenuItem>
                                        )
                                    })}
                                </DropdownMenuSubContent>
                            </DropdownMenuSub>
                        )
                    })}
                </div>
            </DropdownMenuContent>
        </DropdownMenu>)
}