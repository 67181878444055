import type { GetTopicsResponse, Ticket } from "@/interfaces/serverData";
import { memo, useEffect, useState } from "react";

import InsightsSection from "@/Ticket/InsightsSection";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { InsightDisplay } from "./InsightDisplay";
import { Dialog, DialogContent, DialogTrigger } from "@/component/shadcn/ui/dialog";

interface SavedInsightDialogPopupProps {
    ticketWithInsight: Ticket;
}

function SavedInsightDialogPopup({
    ticketWithInsight,
}: SavedInsightDialogPopupProps) {
    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );
    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );

    const api = useApi();

    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const sidebarLabels = "font-medium text-xs text-gray9";

    return (
        <Dialog>
            <DialogTrigger>
                <button type="button" className="w-full">
                    <div className="flex flex-row justify-between items-center w-full">
                        <div className={sidebarLabels}>Insight</div>
                    </div>
                    <InsightsSection
                        issue={ticketWithInsight}
                        showLinkInsight={false}
                        userID={""}
                    />
                </button>
            </DialogTrigger>

            <DialogContent className="py-10 max-w-[1200px] max-h-[750px] w-full h-full">
                <InsightDisplay
                    insight={ticketWithInsight.insights[0]}
                    userID={""}
                    topicsMap={topicsMap}
                    insightSelectedIsSaved={true}
                    issueCounts={ticketWithInsight.issueCounts}
                    trend={ticketWithInsight.trend}
                />
            </DialogContent>
        </Dialog>
    );
}

export default memo(SavedInsightDialogPopup);
