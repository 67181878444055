import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { Textarea } from "@/component/shadcn/ui/textarea";
import { API, URLS } from "@/constant";
import { GradientPicker } from "@/design/GradientPicker";
import { useApi } from "@/interfaces/api";
import { type GetTopicsResponse, type SaveTopicPayload, type Teams, teamSchema } from "@/interfaces/serverData";
import { toggleTeamSelection } from "@/utilities/methods";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import type { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { type TagsRowState, workspace } from "./DataTable/constants";
import { TeamBadges } from "./TeamBadges";

interface TopicsDialogProps {
    type: string;
    triggerElement: React.ReactNode;
    teams: Teams[];
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    refetchTopics: (options?: RefetchOptions) => Promise<QueryObserverResult<GetTopicsResponse[], Error>>
    setLoadingState: React.Dispatch<React.SetStateAction<number>>;
    saveTag: (id: string, rowStateInfo: Partial<TagsRowState>) => void;
    editingObject?: TagsRowState;
    currentTeam?: Teams;
    noDialog?: boolean
}

export const TopicsDialog: React.FC<
    TopicsDialogProps
> = ({ type, triggerElement, teams, setToastOpen, setToastText, setToastSymbol, refetchTopics, setLoadingState, saveTag, editingObject, currentTeam, noDialog }) => {
    const { id } = useParams(); // team ID
    const api = useApi();
    const defaultBadgeColor = "#9B9EF0";
    const timerRef = useRef(0);

    const [tagIsOpen, setTagIsOpen] = useState(false);

    const tagFormSchema = z.object({
        tagName: z
            .string()
            .min(2, { message: "The tag name must be at least 2 characters." })
            .max(70, { message: "The tag name must be at most 70 characters." }),
        tagDescription: z.string().max(1000, {
            message: "The tag description must be at most 1000 characters.",
        }),
        color: z.string(),
        tagTeams: z.array(teamSchema).optional(),
    });
    const tagForm = useForm({
        resolver: zodResolver(tagFormSchema),
        defaultValues: {
            tagTeams: editingObject?.teams ?? ((id && currentTeam) ? [currentTeam] : [workspace]) ?? [],
            tagName: editingObject?.tag_name ?? "",
            tagDescription: editingObject?.description ?? "",
            color: editingObject?.colorSelected ?? defaultBadgeColor,
        },
    });
    const { control: tagControl, setValue: tagSetValue } = tagForm;

    const handleRemoveTeam = (editingObject: TagsRowState) => {
        let newTagTeams: Teams[];
        // If it's a workspace tag, add all the teams except the current one
        if (editingObject.teams.length === 0) {
            const validTeams = [
                ...teams.filter((team) => team.id !== "workspace"),
            ];
            newTagTeams = validTeams.filter((team) => team.id !== currentTeam?.id);
        } else {
            newTagTeams = editingObject.teams.filter((team) => team.id !== currentTeam?.id);
        }
        const rowStateInfo: Partial<TagsRowState> = {
            teams: newTagTeams,
        };
        saveTag(editingObject?.id ?? "", rowStateInfo);
    };

    const tagOnSubmit = (values: z.infer<typeof tagFormSchema>) => {
        if (editingObject) {
            const rowStateInfo: Partial<TagsRowState> = {
                id: editingObject?.id ?? "",
                colorSelected: values.color ?? defaultBadgeColor,
                tag_name: values.tagName,
                description: values.tagDescription,
                teams: values.tagTeams,
            };
            saveTag(editingObject?.id ?? "", rowStateInfo);
            setTagIsOpen(false);
        } else {
            createTag(values)
        }
    };

    function createTag(values: z.infer<typeof tagFormSchema>) {
        const requestData: SaveTopicPayload = {
            name: values.tagName,
            description: values.tagDescription,
            color: values.color ?? defaultBadgeColor,
            type: "User",
            team_ids: id
                ? [id]
                : (values.tagTeams
                    ?.map((team) => team.id)
                    .filter((teamID) => teamID !== "workspace") ?? []),
        };
        if (id) {
            requestData.team_ids = [id];
        }
        api.post(URLS.serverUrl + API.addTopic, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setTagIsOpen(false);
                    refetchTopics();
                    tagForm.reset();
                    setToastSymbol(CheckCircledIcon);
                    setToastText("Created Tag!");
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
                setTagIsOpen(false);
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    }

    const deleteTag = (id: string) => {
        api.delete(`${URLS.serverUrl}${API.deleteTopic}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    refetchTopics();
                    setToastSymbol(CheckCircledIcon);
                    setToastText("Deleted Tag!");
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                }
            })
            .catch((res) => {
                console.log("failed to edit tag");
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    };

    const tagToggleTeam = (team: Teams, tagTeams: Teams[]) => {
        tagSetValue("tagTeams", toggleTeamSelection(team, tagTeams));
    };

    return (
        <div>
            {noDialog ? (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <div onClick={() => {
                    if (editingObject) {
                        handleRemoveTeam(editingObject);
                    }
                }}>
                    {triggerElement}
                </div>
            ) : (
                <Dialog
                    open={tagIsOpen}
                    onOpenChange={setTagIsOpen}
                >
                    <DialogTrigger asChild>{triggerElement}</DialogTrigger>
                    <DialogContent className="max-w-[850px] w-full">
                        <div className="mx-8 my-3">
                            {(() => {
                                switch (type) {
                                    case "Edit":
                                    case "Create":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>{editingObject ? "Edit Tag" : "Add Tag"}</DialogTitle>
                                                    <DialogDescription>
                                                        {editingObject ? `Edit the existing tag: ${editingObject?.tag_name}` :
                                                            "Add a new tag with a description. We'll use this as a way to tag incoming customer support requests."}

                                                    </DialogDescription>
                                                </DialogHeader>

                                                <Form {...tagForm}>
                                                    <form
                                                        onSubmit={tagForm.handleSubmit(
                                                            tagOnSubmit,
                                                        )}
                                                        className="space-y-8"
                                                    >
                                                        <FormField
                                                            control={tagForm.control}
                                                            name="tagName"
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="tagName">
                                                                            Name
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                id="tagName"
                                                                                className="col-span-3"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage className="text-xs" />
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={tagForm.control}
                                                            name="tagDescription"
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="tagDescription">
                                                                            Description
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <Textarea
                                                                                id="tagDescription"
                                                                                className="col-span-3"
                                                                                placeholder="Please be concise but descriptive, this will passed to the AI to help categorize issues."
                                                                                {...field}
                                                                                rows={2}
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={tagForm.control}
                                                            name="color"
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel>
                                                                            Color
                                                                        </FormLabel>
                                                                        <FormControl>
                                                                            <GradientPicker
                                                                                id={
                                                                                    "dialog"
                                                                                }
                                                                                background={field.value}
                                                                                setBackground={(id, rowState) =>
                                                                                    tagForm.setValue(
                                                                                        "color",
                                                                                        rowState.colorSelected ?? defaultBadgeColor
                                                                                    )
                                                                                }
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </FormItem>
                                                            )}
                                                        />
                                                        <FormField
                                                            control={tagControl}
                                                            name="tagTeams"
                                                            render={({ field }) => (
                                                                <FormItem>
                                                                    <div className="grid grid-cols-4 items-center gap-4">
                                                                        <FormLabel htmlFor="tagDescription">
                                                                            Teams
                                                                        </FormLabel>
                                                                        {id ? (
                                                                            <div className="flex flex-col gap-2 col-span-3">
                                                                                <Button
                                                                                    variant="ghost"
                                                                                    className="flex items-center gap-2 justify-start p-0 w-full data-[state=open]:bg-muted overflow-hidden"
                                                                                    type="button"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    <TeamBadges
                                                                                        teams={editingObject?.teams ?? ((id && currentTeam) ? [currentTeam] : [workspace]) ?? []}
                                                                                        defaultIsWorkspace={true}
                                                                                    />
                                                                                </Button>
                                                                            </div>
                                                                        ) : (
                                                                            <FormControl>
                                                                                <TeamsDropdown
                                                                                    selectedTeams={
                                                                                        field?.value ??
                                                                                        []
                                                                                    }
                                                                                    teams={
                                                                                        teams
                                                                                    }
                                                                                    toggleTeam={
                                                                                        tagToggleTeam
                                                                                    }
                                                                                    defaultIsWorkspace={
                                                                                        true
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                        )}
                                                                    </div>
                                                                    <FormMessage />
                                                                </FormItem>
                                                            )}
                                                        />

                                                        <DialogFooter className="justify-end text-end items-end">
                                                            <Button
                                                                className="bg-iris9"
                                                                type="submit"
                                                            >
                                                                Save
                                                            </Button>
                                                        </DialogFooter>
                                                    </form>
                                                </Form>
                                            </>
                                        );
                                    case "Delete":
                                        return (
                                            <>
                                                <DialogHeader className="justify-left text-left items-left pb-5">
                                                    <DialogTitle>Are you absolutely sure?</DialogTitle>
                                                    <DialogDescription>
                                                        {`This action cannot be undone. This will permanently delete the Tag "${editingObject?.tag_name}" from the Workspace.`}
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <DialogFooter className="justify-end text-end items-end gap-3">
                                                    <Button
                                                        className="bg-iris9 py-0.5"
                                                        type="submit"
                                                        onClick={() => setTagIsOpen(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className="bg-red-500 py-0.5"
                                                        type="submit"
                                                        onClick={() => {
                                                            if (editingObject) {
                                                                deleteTag(editingObject.id);
                                                            }
                                                            setTagIsOpen(false)
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogFooter>
                                            </>
                                        );
                                    default:
                                        return null; // You could return a default view or nothing
                                }
                            })()}
                        </div>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    )
};
