import { InsightListType } from "@/Insights/useInsightsData";
import { Button } from "@/component/shadcn/ui/button";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    StartStopPair,
    TimerAnalytics,
    TimerAnalyticsResponse,
} from "@/interfaces/serverData";
import type { Teams } from "@/interfaces/serverData";
import TimezoneDropdown from "@/reusable_components/timestampDropdown";
import { Card, Flex, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import type React from "react";
import type { DateRange } from "react-day-picker";
import { Bar, BarChart } from "recharts";
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";

import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import { type FilterOption, FilterType } from "@/IssuesTable/constants";
import { Calendar } from "@/component/shadcn/ui/calendar";
import type { TickerTimerResponse } from "@/interfaces/serverData";
import { getFilterOption } from "@/utilities/methods";
import { CalendarIcon } from "@radix-ui/react-icons";
import saveAs from "file-saver";

interface BarListRow {
    name: string;
    value: number;
    color: string;
}

export interface TickerTimerPageProps {
    teamID: string;
    setTeamID: React.Dispatch<React.SetStateAction<string>>;
    listType: InsightListType;
    range: DateRange | undefined;
    setRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    playgroundFilters: Map<string, Set<string>>;
    setPlaygroundFilters: React.Dispatch<
        React.SetStateAction<Map<string, Set<string>>>
    >;
    handleFilterSelect: (
        type: string,
        option: { label: string; value: string; key: string; color: string },
    ) => () => void;
    teamsQueryData: Teams[] | undefined;
}

export function TickerTimerPage({
    teamID,
    setTeamID,
    range,
    setRange,
    playgroundFilters,
    setPlaygroundFilters,
    handleFilterSelect,
    listType,
    teamsQueryData,
}: TickerTimerPageProps) {
    const [data, setData] = useState<BarListRow[]>([]);

    const api = useApi();
    const dataQuery = useQuery<TimerAnalyticsResponse>({
        queryKey: [
            "ticketTimer",
            teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );
            const sourceString = sourceFilter.join("&");
            const res = await api.get(URLS.serverUrl + API.timerAnalytics, {
                params: {
                    source: sourceString,
                    teamID: teamID,
                    start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                    end: range?.to?.toJSON() ?? new Date().toJSON(),
                },
                headers: { "Content-Type": "application/json" },
            });
            return res.data.data;
        },
    });

    const exportMutation = useMutation({
        mutationFn: async () => {
            const res = await api.post(
                URLS.serverUrl + API.timerAnalyticsExport,
                {
                    teamID: teamID,
                    start: range?.from?.toISOString(),
                    end: range?.to?.toISOString(),
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            return res.data.data;
        },
    });

    const convertToCSVRaw = (data: StartStopPair[], timeZone: string) => {
        const headers = [
            "Ticket Number",
            "Source",
            "Start Time",
            "End Time",
            "Duration (minutes)",
            "Start User",
            "End User",
            "Original Timestamp",
            "Assignee",
            "Topics",
        ];

        const rows = data?.map((item) => [
            item.ticket_number,
            item.source,
            `${new Date(item.start_time).toLocaleDateString("en-US", { timeZone })} ${new Date(item.start_time).toLocaleTimeString("en-US", { timeZone })}`,
            Number.isNaN(new Date(item.end_time).getTime())
                ? "Timer Still Running"
                : `${new Date(item.end_time).toLocaleDateString("en-US", { timeZone })} ${new Date(item.end_time).toLocaleTimeString("en-US", { timeZone })}`,
            Number.isNaN(new Date(item.end_time).getTime()) ? 0 : item.duration,
            item.start_user,
            item.end_user,
            `${new Date(item.original_timestamp).toLocaleDateString("en-US", { timeZone })} ${new Date(item.original_timestamp).toLocaleTimeString("en-US", { timeZone })}`,
            item.assignee_user_id,
            item.topic?.join(" / "),
        ]);
        return [headers, ...rows];
    };

    function downloadRawCSV(data: StartStopPair[], timezone: string) {
        const csvData = convertToCSVRaw(data, timezone);
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const date = new Date().toISOString().split("T")[0];
        saveAs(blob, `timer_raw_export_${date}.csv`);
    }

    const convertToCSV = (
        data: TimerAnalytics[] | undefined,
        timeZone: string,
    ) => {
        const headers = [
            "Ticket Number",
            "Duration (in hours)",
            "Original Timestamp",
            "Assignee User ID",
            "Topics",
        ];
        const rows = data?.map((item) => [
            item.ticket_number,
            item.total_duration,
            item.original_timestamp,
            item.assignee_user_id,
            item.topic?.join(" / "),
        ]);
        if (rows) {
            return [headers, ...rows];
        }
        return [];
    };

    function downloadCSV(data: TimerAnalytics[] | undefined, timezone: string) {
        const csvData = convertToCSV(data, timezone);
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const date = new Date().toISOString().split("T")[0];
        saveAs(blob, `timer_export_${date}.csv`);
    }

    const handleExport = async (timezone: string) => {
        try {
            downloadCSV(dataQuery.data?.data, timezone);
            const rawData: TickerTimerResponse =
                await exportMutation.mutateAsync();
            downloadRawCSV(rawData?.data, timezone);
        } catch (error) {
            console.error("Error exporting tickets:", error);
        }
    };

    useEffect(() => {
        if (dataQuery.isSuccess) {
            const data = dataQuery.data?.data?.map((item) => ({
                name: `ASM-${item.ticket_number}`,
                value: item.total_duration,
                color: "blue",
            }));
            setData(data);
        }
    }, [dataQuery.isSuccess]);

    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const filterOptions: FilterOption[] = [
        getFilterOption("Source", FilterType.MenuThenSelect, true),
    ];
    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2 mt-2">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="flex items-center gap-2 text-muted-foreground text-xs"
                        >
                            <CalendarIcon className="h-3 w-3" />
                            {range?.from ? (
                                format(range.from, "PP")
                            ) : (
                                <span>Oldest</span>
                            )}
                            <div>to</div>
                            {range?.to ? (
                                format(range.to, "PP")
                            ) : (
                                <span>Latest</span>
                            )}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                        />
                    </PopoverContent>
                </Popover>

                {Array.from(playgroundFilters.entries()).map(
                    ([type, values]) => (
                        <div key={type}>
                            <Filter
                                type={type}
                                values={values}
                                filters={playgroundFilters}
                                setFilters={setPlaygroundFilters}
                                topics={[]}
                                users={[]}
                                customerGroups={[]}
                                categories={[]}
                                isSavedViewFilter={false}
                                teams={[]}
                                channels={new Map()}
                                filterOptions={filterOptions}
                            />
                        </div>
                    ),
                )}
                <FilterDropdown
                    className="h-9 min-w-[60px]"
                    filters={playgroundFilters}
                    filterOptions={filterOptions}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    handleItemSelect={handleFilterSelect}
                    handleMenuClick={handleMenuClick}
                    topics={[]}
                    users={[]}
                    customerGroups={[]}
                    teams={[]}
                    categories={[]}
                    channels={new Map()}
                />

                {listType === InsightListType.General && (
                    <Select
                        value={teamID}
                        onValueChange={(value) => setTeamID(value)}
                    >
                        <SelectTrigger className="text-xs py-1 mx-5">
                            <SelectValue placeholder="Select Team" />
                        </SelectTrigger>
                        <SelectContent>
                            {teamsQueryData?.map((team) => (
                                <SelectItem key={team.id} value={team.id}>
                                    {team.team_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                )}
                <TimezoneDropdown
                    onTimezoneSelect={(timezone) => {
                        handleExport(timezone);
                    }}
                    button={
                        <Button
                            className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                            size="sm"
                            variant="outline"
                        >
                            Download CSV
                        </Button>
                    }
                />
            </div>
            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Timer
                    </Text>
                    <Text size="3" color="gray" mb="2">
                        View how much time is spent on each ticket.
                    </Text>
                    <ResponsiveContainer height={400}>
                        <BarChart data={data} margin={{ left: 50 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis
                                dataKey="value"
                                label={{
                                    value: "Duration (hours)",
                                    angle: -90,
                                    position: "insideLeft",
                                    offset: -35,
                                }}
                            />
                            <Legend />
                            <Tooltip />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </Flex>
            </Card>
        </div>
    );
}
