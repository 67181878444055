import { ExternalIssuesSection } from "@/Ticket/ExternalIssuesSection";
import type {
    Category,
    GetTopicsResponse,
    GetUserResponse,
    Insight,
    InsightData,
    InsightInfoResponse,
    Teams,
} from "@/interfaces/serverData";
import {
    CalendarIcon,
} from "@radix-ui/react-icons";
import { Badge, DataList } from "@radix-ui/themes";
import { memo, useMemo } from "react";
import { InsightStatusDropdown } from "./InsightStatusDropdown";
import { handleAccountBadgeClick, insightAccountBadge, processInsightMetadata } from "@/utilities/methods";
import { Card } from "@/component/shadcn/ui/card";
import { AnnouncementsSection } from "@/Ticket/AnnouncementsSection";
import { DollarSignIcon } from "lucide-react";
import type { QueryObserverResult, RefetchOptions, UseQueryResult } from "@tanstack/react-query";
import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { useApi } from "@/interfaces/api";
import { API, URLS } from "@/constant";

interface DisplayCardContentProps {
    type: string;
    insight: Insight;
    updateInsightState: ((newState: Partial<Insight>) => void) | undefined;
    insightUserInfo: GetUserResponse | undefined;
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    categories: Category[];
    insightSelectedIsSaved?: boolean;
    teamsQuery?: UseQueryResult<Teams[], Error>
    orgID?: string;
    refetch?: (options?: RefetchOptions) => Promise<QueryObserverResult<InsightInfoResponse | null, Error>>
}

function DisplayCardContent({
    type,
    insight,
    updateInsightState,
    insightUserInfo,
    topicsMap,
    userID,
    categories,
    insightSelectedIsSaved,
    teamsQuery,
    orgID,
    refetch
}: DisplayCardContentProps) {
    const api = useApi()
    const created_date = useMemo(() => {
        try {
            const d = new Date(insight.created_at);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${insight.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return insight.created_at;
        }
    }, [insight.created_at]);

    const updated_date = useMemo(() => {
        try {
            const d = new Date(insight.updated_at);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${insight.updated_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return insight.updated_at;
        }
    }, [insight.updated_at]);

    const toggleTeam = (team: Teams, tagTeams: Teams[]) => {
        const isExistingTeam = tagTeams.find(t => t.id === team.id && t.team_name === team.team_name)
        let added_teams: Teams[] = []
        let removed_teams: Teams[] = []
        // Handle General Team
        if (team.id === orgID) {
            // Can't untoggle general team, just needs to click another team to untoggle General
            if (!isExistingTeam) {
                // Remove all existing teams
                removed_teams = tagTeams
            }
        } else {
            added_teams = isExistingTeam ? [] : [team]
            removed_teams = isExistingTeam ? [team] : []
        }
        const requestData: InsightData = {
            existing_insight_id: insight.id,
            added_teams: added_teams.map(t => t.id),
            removed_teams: removed_teams.map(t => t.id),
            user_id: userID,
        };
        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                if (refetch) {
                    refetch()
                }
                // Attempt to update the state because the refetch takes a while
                for (const team of added_teams) {
                    insight.teams.push(team);
                }
                for (const team of removed_teams) {
                    insight.teams = insight.teams.filter(t => t.id !== team.id)
                }
                console.log("Updated teams successfully");
            } else {
                console.log("Call to update teams failed");
            }
        });
    }

    switch (type) {
        case "Attributes": {
            const minDataListWidth = "80px";
            const [isAIGenerated, topic, category] = insight
                ? processInsightMetadata(insight)
                : [false, "", ""];

            return (
                <DataList.Root size="1" className="-mt-2 p-1.5 gap-5">
                    <DataList.Item align="center">
                        <DataList.Label className="text-xs w-4">
                            Monetary Value
                        </DataList.Label>
                        <DataList.Value>
                            <div className="text-xs flex items-center mx-2.5">
                                <Badge
                                    color={"gray"}
                                    size="2"
                                    radius="medium"
                                    variant="outline"
                                    className="ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex items-center gap-0.5"
                                >
                                    <DollarSignIcon strokeWidth={1.5} size={11} />
                                    <p className="text-xs">{(Number.isInteger(insight.monetary_value ?? 0))
                                        ? (insight.monetary_value ?? 0).toString()
                                        : (insight.monetary_value ?? 0).toFixed(2)}</p>
                                    <p className="text-xs">per month</p>
                                </Badge>
                            </div>
                        </DataList.Value>
                    </DataList.Item>
                    {insight.accounts && insight.accounts?.length !== 0 &&
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Customers
                            </DataList.Label>
                            <DataList.Value>
                                <div className="text-xs flex items-center gap-2 mx-2.5">
                                    {insight.accounts?.sort((a, b) => b.value_per_month - a.value_per_month)
                                        .map(c => insightAccountBadge(c, handleAccountBadgeClick))}
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    }
                    {updateInsightState && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Status
                            </DataList.Label>
                            <DataList.Value>
                                <InsightStatusDropdown
                                    insight={insight}
                                    updateInsightState={updateInsightState}
                                    userID={userID}
                                />
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    {insightSelectedIsSaved &&
                        <DataList.Item align="center">
                            <DataList.Label
                                minWidth={`${minDataListWidth}`}
                                className="text-xs"
                            >
                                Team
                            </DataList.Label>
                            <DataList.Value className="flex flex-row flex-wrap mx-2.5">
                                <TeamsDropdown
                                    selectedTeams={insight.teams ?? []}
                                    teams={teamsQuery?.data || []}
                                    toggleTeam={toggleTeam}
                                    defaultIsWorkspace={false}
                                    orgID={orgID}
                                />
                            </DataList.Value>
                        </DataList.Item>
                    }
                    {insightSelectedIsSaved && insightUserInfo && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Created
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex items-center gap-2 text-xs mx-2.5">
                                    <Card className="rounded-sm flex flex-row gap-1 px-2.5 py-1 items-center shadow-none">
                                        <CalendarIcon />
                                        <p className="text-xs">{created_date}</p>
                                    </Card>
                                    <Badge
                                        color={"gray"}
                                        size="2"
                                        radius="medium"
                                        variant="outline"
                                    >
                                        <div
                                            className={
                                                "lb-avatar rounded-lg w-5 h-4"
                                            }
                                        >
                                            {insightUserInfo.picture_url && (
                                                <img
                                                    className="lb-avatar-image"
                                                    src={
                                                        insightUserInfo.picture_url
                                                    }
                                                    alt={`${insightUserInfo.first_name} ${insightUserInfo.last_name}`}
                                                />
                                            )}
                                            <span>
                                                {`${insightUserInfo.first_name} ${insightUserInfo.last_name}`}
                                            </span>
                                        </div>
                                        <div className="lb-comment-author text-xs font-medium">
                                            {`${insightUserInfo.first_name} ${insightUserInfo.last_name}`}
                                        </div>
                                    </Badge>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    {insightSelectedIsSaved &&
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Updated
                            </DataList.Label>
                            <DataList.Value>
                                <div className="text-xs flex items-center mx-2.5">
                                    <Card className="rounded-sm flex flex-row gap-1 px-2.5 py-1 items-center shadow-none">
                                        <CalendarIcon />
                                        <p className="text-xs">{updated_date}</p>
                                    </Card>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    }
                </DataList.Root>
            );
        }
        case "Announcements": {
            return (
                <div className="-mt-2 m-1">
                    <AnnouncementsSection
                        insight={insight}
                        updateInsightState={updateInsightState}
                        userID={userID}
                    />
                </div>
            );
        }
        case "External Issues": {
            return (
                <div className="-mt-2 m-1">
                    <ExternalIssuesSection
                        insight={insight}
                        updateInsightState={updateInsightState}
                        userID={userID}
                    />
                </div>
            );
        }
        default:
            return <div />;
    }
}

export default memo(DisplayCardContent);
