import { CalendarIcon } from "@radix-ui/react-icons";
import { Card } from "@/component/shadcn/ui/card";
import { useMemo } from "react";

type DateInput = string | Date | undefined;

export default function CalendarBadge({ date }: { date: DateInput }) {
    const formattedDate = useMemo(() => {
        if (!date) return null;

        try {
            if (date instanceof Date) {
                return date.toLocaleDateString();
            }
            const d = new Date(date);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert date input "${date}" to a valid date. Error: ${err}`,
            );
            return typeof date === 'string' ? date : null;
        }
    }, [date]);

    if (!formattedDate) return null;

    return (
        <Card className="rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-1 py-1 items-center">
            <CalendarIcon />
            <p className="text-xs ">
                {formattedDate}
            </p>
        </Card>
    );
}
