import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectTrigger,
    SelectItem,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { saveAs } from "file-saver";
import { URLS, API } from "@/constant";
import { InsightListType } from "@/Insights/useInsightsData";
import { useApi } from "@/interfaces/api";
import type { Teams } from "@/interfaces/serverData";
import { type FilterOption, FilterType } from "@/IssuesTable/constants";
import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import { getFilterOption } from "@/utilities/methods";
import { CalendarIcon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import type { DateRange } from "react-day-picker";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import TimezoneDropdown from "@/reusable_components/timestampDropdown";

interface SLABreachCount {
    ticket_number: number;
    date: string;
    url: string;
    assignee: string;
    topics: string;
    source: string;
    bot_category: string;
}
export interface SLABreachCountProps {
    listType: InsightListType;
    teamsQueryData?: Teams[];
    teamID: string;
    setTeamID: (teamID: string) => void;
}

interface SLABreachCountData {
    date: string;
    count: number;
}

export default function SLABreachCountComponent({
    listType,
    teamsQueryData,
    teamID,
    setTeamID,
}: SLABreachCountProps) {
    const api = useApi();
    const { data, isLoading, error } = useQuery<SLABreachCount[]>({
        queryKey: ["slaBreachCount"],
        queryFn: async () => {
            const res = await api.get(
                `${URLS.serverUrl}${API.getSLABreachCount}`,
            );
            if (res.status !== 200) {
                throw new Error("Failed to fetch SLA breach count");
            }
            return res.data.data.results;
        },
    });

    console.log("data is", data)

    const mapSLABreachCountToChartData = (
        data: SLABreachCount[],
    ): SLABreachCountData[] => {
        const groupedData: {
            [key: string]: { count: number };
        } = {};

        // Iterate over SLA breach count data
        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            // Extract the date from the `start` field
            const date = new Date(item.date).toISOString().split("T")[0];

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].count += 1;
        });

        const result = Object.entries(groupedData).map(([date, { count }]) => ({
            date,
            count,
        }));
        return result;
    };

    const convertToCSV = (data: SLABreachCount[], timeZone: string) => {
        const headers = ["Date", "Count"];
        const groupedData: {
            [key: string]: { count: number };
        } = {};

        // Iterate over SLA breach count data
        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            // Extract the date from the `start` field
            const date = new Date(item.date).toISOString().split("T")[0];

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].count += 1;
        });

        // flatten the object to an array
        const rows = Object.entries(groupedData).map(([date, { count }]) => [
            new Date(date).toLocaleDateString("en-US", {
                timeZone,
            }),
            count,
        ]);
        return [headers, ...(rows as string[][])];
    };

    const convertRawToCSV = (data: SLABreachCount[], timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Source",
            "Interaction Type",
            "Topics",
        ];
        const rows = data.map((item) => {
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    const downloadCSV = (data: SLABreachCount[], timezone: string) => {
        const csvData = convertToCSV(data ?? [], timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_sla_breach_count.csv");
    };

    const downloadRawCSV = (data: SLABreachCount[], timezone: string) => {
        const csvData = convertRawToCSV(data ?? [], timezone);
        const csvContent = csvData.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_sla_breach_count.csv");
    };

    const [range, setRange] = useState<DateRange>();
    const [playgroundFilters, setPlaygroundFilters] = useState<
        Map<string, Set<string>>
    >(new Map());
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    const filterOptions: FilterOption[] = [
        getFilterOption("Source", FilterType.MenuThenSelect, true),
    ];
    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );
    const handleFilterSelect = useCallback(
        (
            type: string,
            option: {
                label: string;
                value: string;
                key: string;
                color: string;
            },
        ) =>
            () => {
                const newFilters = new Map(playgroundFilters);
                if (newFilters.has(type)) {
                    const currValues = new Set(newFilters.get(type));
                    if (currValues?.has(option.value)) {
                        currValues.delete(option.value);
                        if (currValues.size === 0) {
                            newFilters.delete(type);
                        } else {
                            newFilters.set(type, currValues);
                        }
                    } else {
                        currValues.add(option.value);
                        newFilters.set(type, currValues);
                    }
                } else {
                    newFilters.set(type, new Set([option.value]));
                }
                setPlaygroundFilters(newFilters);
            },
        [playgroundFilters],
    );

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>An error occurred: {(error as Error).message}</div>;

    return (
        <Card>
            <CardHeader>
                <CardTitle>SLA Breach Count</CardTitle>
                <CardDescription>
                    Number of times SLA was breached per ticket
                </CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-row items-center gap-2 mt-2">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant="outline"
                                className="flex items-center h-9 gap-2 text-muted-foreground text-xs"
                            >
                                <CalendarIcon className="h-3 w-3" />
                                {range?.from ? (
                                    format(range.from, "PP")
                                ) : (
                                    <span>Oldest</span>
                                )}
                                <div>to</div>
                                {range?.to ? (
                                    format(range.to, "PP")
                                ) : (
                                    <span>Latest</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="range"
                                selected={range}
                                onSelect={setRange}
                            />
                        </PopoverContent>
                    </Popover>

                    {Array.from(playgroundFilters.entries()).map(
                        ([type, values]) => (
                            <div key={type}>
                                <Filter
                                    type={type}
                                    values={values}
                                    filters={playgroundFilters}
                                    setFilters={setPlaygroundFilters}
                                    topics={[]}
                                    users={[]}
                                    customerGroups={[]}
                                    isSavedViewFilter={false}
                                    categories={[]}
                                    teams={[]}
                                    channels={new Map()}
                                    filterOptions={filterOptions}
                                />
                            </div>
                        ),
                    )}
                    <FilterDropdown
                        className="h-9 min-w-[60px]"
                        filters={playgroundFilters}
                        filterOptions={filterOptions}
                        activeMenu={activeMenu}
                        setActiveMenu={setActiveMenu}
                        handleItemSelect={handleFilterSelect}
                        handleMenuClick={handleMenuClick}
                        topics={[]}
                        users={[]}
                        customerGroups={[]}
                        teams={[]}
                        categories={[]}
                        channels={new Map()}
                    />

                    {listType === InsightListType.General && (
                        <Select
                            value={teamID}
                            onValueChange={(value) => setTeamID(value)}
                        >
                            <SelectTrigger className="text-xs py-1 mx-5">
                                <SelectValue placeholder="Select Team" />
                            </SelectTrigger>
                            <SelectContent>
                                {teamsQueryData?.map((team) => (
                                    <SelectItem key={team.id} value={team.id}>
                                        {team.team_name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    )}

                    <TimezoneDropdown
                        onTimezoneSelect={(timezone) => {
                            downloadCSV(data ?? [], timezone);
                            downloadRawCSV(data ?? [], timezone);
                        }}
                        button={
                            <Button
                                className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                Download CSV
                            </Button>
                        }
                    />
                </div>

                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={mapSLABreachCountToChartData(data ?? [])}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}
