import { API, ContactsAPI, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import { Box, Button, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ReactComponent as HubSpotSvg } from "../../../images/integrations/hubspot.svg";
import { HubSpotSchemaImport } from "./HubSpotImportPage";
import IntegrationHeader from "./IntegrationHeader";
export const importForm = z.object({
    company_schema_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_domain: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_image_url: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_contract_value: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_contract_frequency: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_plan_type: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_schema_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_first_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_last_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_email: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_image_url: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_contract_value: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_contract_frequency: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_plan_type: z.object({
        key: z.string(),
        name: z.string(),
    }),
});

export const HubSpotIntegration = () => {
    const navigate = useNavigate();
    const loremIpsum =
        "Lorem ipsum sit, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const hubspotContinue = () => {
        const client_id = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/hubspot`;
        const hubspot_url = `https://app.hubspot.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_url}&scope=crm.schemas.deals.read%20crm.objects.subscriptions.read%20crm.schemas.subscriptions.read%20crm.schemas.commercepayments.read%20oauth%20crm.objects.commercepayments.read%20crm.objects.invoices.read%20crm.schemas.invoices.read%20crm.schemas.custom.read%20crm.objects.custom.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.contacts.read%20crm.objects.contacts.read%20crm.schemas.companies.read`;
        window.open(hubspot_url, "_self");
    };

    const api = useApi();

    const [loadingState, setLoadingState] = useState<number>(0);

    useEffect(() => {
        const requestData = {
            types: ["HubSpot"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.HubSpot) {
                            setIntegrationEnabled(true);
                        }
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const [showImport, setShowImport] = useState<boolean>(false);

    const [loadingSavedSchema, setLoadingSavedSchema] = useState(
        loadingTypes.loading,
    );

    type GetCRMSavedSchemaResponse = {
        schema: z.infer<typeof importForm>;
        exists: boolean;
    };

    const savedSchemaQuery = useQuery<GetCRMSavedSchemaResponse>({
        queryKey: ["savedSchema"],
        queryFn: async () => {
            const { url } = ContactsAPI.getSavedSchema;
            const resp = await api.get(URLS.serverUrl + url, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (resp.status === 200) {
                setLoadingSavedSchema(loadingTypes.loaded);
                console.log(resp.data.data);
                return resp.data.data;
            } else {
                setLoadingSavedSchema(loadingTypes.error);
                return null;
            }
        },
    });

    useEffect(() => {
        if (
            loadingSavedSchema === loadingTypes.loaded &&
            savedSchemaQuery.data?.exists === true
        ) {
            console.log("setting show import to true");
            setShowImport(true);
        }
    }, [savedSchemaQuery.data, loadingSavedSchema]);

    const [open, setOpen] = useState(false);
    const [toastText, setToastText] = useState("");
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="HubSpot"
                        description="Connect your HubSpot account to see customer data in Assembly."
                        SvgIcon={HubSpotSvg}
                    />
                    <Flex className="w-full justify-end">
                        <div className="flex flex-col gap-2">
                            {!integrationEnabled ? (
                                <Button onClick={hubspotContinue}>
                                    Enable
                                </Button>
                            ) : (
                                <Button disabled>Enabled</Button>
                            )}
                            {integrationEnabled &&
                                loadingSavedSchema === loadingTypes.loaded &&
                                savedSchemaQuery.data?.exists === false && (
                                    <Button onClick={() => setShowImport(true)}>
                                        Import HubSpot Data into Assembly
                                    </Button>
                                )}
                        </div>
                    </Flex>
                </Flex>

                {loadingState === 0 && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <Text key={index}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}

                {(loadingState === 2 || error) && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {loadingSavedSchema === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
            </Box>
            <Box mt={"2%"} ml={"8%"} mr={"8%"}>
                {showImport && (
                    <HubSpotSchemaImport
                        importData={savedSchemaQuery.data?.schema}
                        exists={savedSchemaQuery.data?.exists ?? false}
                        setOpen={setOpen}
                        setToastText={setToastText}
                        setToastSymbol={setToastSymbol}
                    />
                )}
            </Box>

            <AssemblyToastProvider
                open={open}
                setOpen={setOpen}
                toastText={toastText}
                toastSymbol={ToastSymbol}
            />
        </div>
    );
};
