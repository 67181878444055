import { Button } from "@/component/shadcn/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import type { Table } from "@tanstack/react-table";

interface AccountsDataTablePaginationProps<TData> {
    table: Table<TData>;
    setPageSize: React.Dispatch<React.SetStateAction<number>>;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function AccountsDataTablePagination<TData>({
    table,
    setPageSize,
    setPageIndex,
}: AccountsDataTablePaginationProps<TData>) {
    const pageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    return (
        <Flex direction="row-reverse" align="center" justify="between">
            <Flex direction="row" align="center" gap="6" className="pb-2">
                <Flex direction="row" align="center" gap="2">
                    <p className="text-sm font-medium">Rows per page</p>
                    <Select
                        value={`${pageSize}`}
                        onValueChange={(value) => {
                            const newPageSize = Number(value);
                            table.setPageSize(newPageSize);  // update page size in table
                            setPageSize(newPageSize);            // sync pageSize with parent state
                        }}
                    >
                        <SelectTrigger className="h-8 w-[70px]">
                            <SelectValue placeholder={`${pageSize}`} />
                        </SelectTrigger>
                        <SelectContent side="top">
                            {[10, 25, 50, 100].map((size) => (
                                <SelectItem key={size} value={`${size}`} className="p-2">
                                    {size}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </Flex>
                <div className="text-sm font-medium">
                    {`Page ${pageIndex + 1} of ${table.getPageCount()}`}
                </div>
                <Flex direction="row" align="center" gap="2">
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.setPageIndex(0);
                            setPageIndex(0);  // Go to the first page
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <DoubleArrowLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.previousPage();
                            setPageIndex(pageIndex - 1);  // Update offset for previous page
                        }}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.nextPage();
                            setPageIndex(pageIndex + 1);  // Update offset for next page
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        <ChevronRightIcon className="h-4 w-4" />
                    </Button>
                    <Button
                        variant="outline"
                        className="hidden h-8 w-8 p-0 lg:flex hover:bg-muted cursor-pointer"
                        onClick={() => {
                            table.setPageIndex(table.getPageCount() - 1);
                            setPageIndex((table.getPageCount() - 1) * pageSize);  // Go to last page
                        }}
                        disabled={!table.getCanNextPage()}
                    >
                        <DoubleArrowRightIcon className="h-4 w-4" />
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    );
}
