import {
    type FilterType,
    insightStatuses,
    sources,
    ticketStatuses,
} from "@/IssuesTable/constants";
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import {
    type Account,
    type AccountMonetaryRecord,
    type AccountsLabel,
    AccountsLabelsType,
    type Category,
    type CustomerGroup,
    type CustomerTicket,
    type GetTopicsResponse,
    type GetUserResponse,
    type Insight,
    type InsightData,
    type QueriesWithPaginationResponse,
    type Query,
    type ScopeResponse,
    type Teams,
    type Ticket,
    type TicketStatus,
    type TicketTag,
    type TicketTopics,
    type Topic,
    type UserResponse,
    type Workflow,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";

import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { VariableNode } from "@/component/textEditor/nodes/TextVariableNode";
import { AccountsListType } from "@/pages/Admin/CRM/Accounts";
import {
    type TextMatchTransformer,
    type Transformer,
    TRANSFORMERS as originalTransformers,
} from "@lexical/markdown";
import { createLinkMatcherWithRegExp } from "@lexical/react/LexicalAutoLinkPlugin";
import {
    AvatarIcon,
    CaretUpIcon,
    CheckCircledIcon,
    CrossCircledIcon,
    DrawingPinIcon,
    EnvelopeOpenIcon,
    ExclamationTriangleIcon,
    type LapTimerIcon,
    OpenInNewWindowIcon,
    PersonIcon,
    QuestionMarkCircledIcon,
    ReaderIcon,
    ResetIcon,
    type StopwatchIcon,
} from "@radix-ui/react-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Badge } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type {
    InfiniteData,
    QueryClient,
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import { TextNode } from "lexical";
import _ from "lodash";
import {
    ActivityIcon,
    DollarSignIcon,
    Eclipse,
    HotelIcon,
    HouseIcon,
    ImageIcon,
    LayersIcon,
    ListChecksIcon,
    MailboxIcon,
    MailsIcon,
    MoonStarIcon,
    MoveRightIcon,
    PlusIcon,
    SparklesIcon,
    StarIcon,
    StoreIcon,
    TagIcon,
    TargetIcon,
    TrendingDownIcon,
    TrendingUpIcon,
    UsersIcon,
} from "lucide-react";
import { createPortal } from "react-dom";
import ReactDOMServer from "react-dom/server";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { f } from "@liveblocks/react/dist/suspense-i9xgTMdV";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];

export function getColor(input: string): BadgeColor {
    switch (input) {
        case "Query":
            return "green";
        case "Feature":
            return "blue";
        case "Bug":
            return "red";
        default:
            return "gray";
    }
}

export function getColorLight(input: string): string {
    switch (input) {
        case "Query":
            return "#5BB98B";
        case "Feature":
            return "#5EB1EF";
        case "Bug":
            return "#EB8E90";
        default:
            return "#BBBBBB";
    }
}

export function getTopicColor(input: string): BadgeColor {
    switch (input) {
        case "autoresponder setup":
            return "green";
        case "channel activity":
            return "pink";
        default:
            return "iris";
    }
}

export function processInsightMetadata(
    insight: Insight,
): [boolean, string, string] {
    if (insight.metadata) {
        const metadata = new Map(Object.entries(JSON.parse(insight.metadata)));
        const isAIGenerated =
            metadata.has("ai_generated") &&
            Boolean(metadata.get("ai_generated"));
        const topicGeneratedFrom: string =
            (metadata.get("topic_generated_from") as string) ?? "";
        const categoryGeneratedFrom: string =
            (metadata.get("category_generated_from") as string) ?? "";
        return [isAIGenerated, topicGeneratedFrom, categoryGeneratedFrom];
    }
    return [false, "", ""];
}

export async function saveAIGeneratedInsight(
    insight: Insight,
    queryClient: QueryClient,
    userID: string,
    api: AxiosInstance,
    teamID?: string,
) {
    const requestData: InsightData = {
        title: insight.title,
        description: insight.description,
        user_id: userID,
        related_issues_added: insight.related_issues.map((ri) => ri.id),
        metadata: insight.metadata,
    };

    if (teamID && teamID !== "") {
        requestData.team_id = teamID;
    }

    try {
        const res = await api.post(
            URLS.serverUrl + API.saveInsight,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status === 200) {
            console.log(`Saved insight id ${res.data.data} successfully`);
            await queryClient.refetchQueries({
                queryKey: ["insights"],
                exact: true,
            });
            return res.data.data;
        } else {
            console.log("Call to update status failed");
            return "";
        }
    } catch (error) {
        console.error("Error saving insight:", error);
        return "";
    }
}

export function getExternalIssueIcon(url: string): React.ElementType {
    const icon = url.toLowerCase().includes("linear")
        ? integrationBackEndDataMappingToSvg.get("Linear")
        : url.toLowerCase().includes("github")
            ? integrationBackEndDataMappingToSvg.get("GitHubTicket")
            : url.toLowerCase().includes("atlassian")
                ? integrationBackEndDataMappingToSvg.get("Jira")
                : url.toLowerCase().includes("intercom")
                    ? integrationBackEndDataMappingToSvg.get("Intercom")
                    : undefined;

    return icon || ReaderIcon;
}

export function getExternalIssueText(url: string): string {
    const text = url.toLowerCase().includes("linear")
        ? "Linear"
        : url.toLowerCase().includes("github")
            ? "GitHub"
            : url.toLowerCase().includes("atlassian")
                ? "Jira"
                : url.toLowerCase().includes("intercom")
                    ? "Intercom"
                    : "";
    return text;
}

export const areQueriesEqual = (query1: Query, query2: Query): boolean => {
    return (
        query1.id === query2.id ||
        query1.title === query2.title ||
        query1.query === query2.query
    );
};

export const addToCustomSet = (
    query: Query,
    set: Set<Query>,
    compareFn: (q1: Query, q2: Query) => boolean,
) => {
    if (
        !Array.from(set).some((existingQuery) =>
            compareFn(existingQuery, query),
        )
    ) {
        set.add(query);
    }
};

export function getPlural(type: string) {
    switch (type) {
        case "Source": {
            return "sources";
        }
        case "Broadcast": {
            return "broadcasts";
        }
        case "Tag": {
            // backend category -> tag -> "Interaction Type" on UI
            return "interaction types";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "tags";
        }
        case "Status":
        case "Insight Status": {
            return "statuses";
        }
        case "Assignee": {
            return "assignees";
        }
        case "Team": {
            return "Teams";
        }
    }
}

export function getLowercase(type: string) {
    switch (type) {
        case "Tag": {
            // backend category -> tag -> "Interaction Type" on UI
            return "interaction type";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "tag";
        }
        case "CommunitySlack": {
            return "Community Slack channels";
        }
        case "Slack":
        case "Discord": {
            return `${type} channels`;
        }
        case "API": {
            return "API keys";
        }
        default: {
            return type.toLowerCase();
        }
    }
}

export function getTypeName(type: string) {
    switch (type) {
        case "Tag": {
            // backend category -> tag -> "Interaction Type" on UI
            return "Interaction Type";
        }
        case "Topic": {
            // backend topics -> "Tag" on UI
            return "Tag";
        }
        case "Status":
        case "Insight Status": {
            return "Status";
        }
        default: {
            return type;
        }
    }
}

export function getOptions(
    type: string,
    topics: Topic[],
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
    categories: Category[],
    teams: Teams[],
    channels: Map<string, ScopeResponse[]>,
    organizationSources?: string[],
) {
    switch (type) {
        case "Source": {
            if (organizationSources !== undefined) {
                return sources.filter((broadcast) =>
                    organizationSources.includes(broadcast.value),
                );
            } else {
                return sources;
            }
        }
        case "Tag": {
            return categories.map((category) => ({
                label: category.name,
                value: category.name,
                color: category.color,
                key: category.id,
            }));
        }
        case "Topic": {
            return topics;
        }
        case "Status": {
            return ticketStatuses;
        }
        case "Insight Status": {
            return insightStatuses;
        }
        case "Assignee": {
            const options = users.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
                color: "",
                key: user.id
            }));
            options.push({
                color: "",
                label: "No Assignee",
                value: "noAssignee",
                key: "NoAssignee"
            });
            return options;
        }
        case "Customer Group": {
            const options = customerGroups.map((cg) => ({
                label: cg.group_name,
                value: cg.group_name,
                color: "",
                key: cg.id ?? "",
            }));
            return options;
        }
        case "Team": {
            const options = teams.map((team) => ({
                label: team.team_name,
                value: team.team_name,
                color: "",
                key: team.id
            }));
            return options;
        }
        case "Slack":
        case "Discord":
        case "CommunitySlack": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- #${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
                key: channel.key
            }));
        }
        case "Gmail": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- ${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
                key: channel.key
            }));
        }
        case "API": {
            return channels?.get(type)?.map((channel) => ({
                label: `${type} -- ${channel.name}`,
                value: `${type} -- ${channel.name} -- ${channel.key}`,
                color: "",
                key: channel.key
            }));
        }
    }
}

export function getStatusFullIcon(status: string) {
    const Icon = getStatusIcon(status ?? "Unknown");
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFEFD6] border border-[#FFDFB5] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EEF1F0] border border-[#E6E9E8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFF6F8] border border-[#FFE9ED] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EDF2FE] border border-[#E1E9FF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "New": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#e8f5fc] border border-[#bbdced] shadow-sm">
                    <Icon className="h-3.5 w-3.5" />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon />
                </div>
            );
        }
    }
}

export function getStatusIcon(status: string) {
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TargetIcon {...props} color="#EC9455" />
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ResetIcon {...props} color="#868E8B" />
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => <ExclamationTriangleIcon {...props} color="#f57695" />;
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof MoonStarIcon>) => (
                <MoonStarIcon
                    strokeWidth={1.5}
                    size={16}
                    {...props}
                    color="#3358D4"
                />
            );
        }
        case "New": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <MailsIcon {...props} color="#8acaed" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementsFullIcon(status: string) {
    const Icon = getAnnouncementStatusIcon(status);
    switch (status) {
        case "Draft": {
            return (props: React.ComponentProps<typeof ReaderIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#F4FAFF] border border-[#D5EFFF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Sent": {
            return (props: React.ComponentProps<typeof EnvelopeOpenIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FDF7FD] border border-[#F7DEF8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementStatusIcon(status: string) {
    switch (status) {
        case "Draft": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ReaderIcon {...props} color="#0090FF" />
            );
        }
        case "Sent": {
            return (props: React.ComponentProps<typeof EnvelopeOpenIcon>) => (
                <EnvelopeOpenIcon {...props} color="#AB4ABA" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getTrendFullIcon(trend: string, size = 3.5) {
    const Icon = getTrendIcon(trend ?? "Unknown");
    const sizeClass = `w-${size} h-${size}`;
    switch (trend) {
        case "Increasing": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#f5e4e9] border border-[#fad2dd] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Increasing Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Decreasing": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#dff5df] border border-[#bce6bc] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Decreasing Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Stagnant": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#d5e9ed] border border-[#bfdbe0] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Stagnant Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        case "Variable": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center rounded-lg p-1 bg-[#ebebf7] border border-[#cccced] shadow-sm">
                                <Icon className={sizeClass} />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            <p>Variable Trend</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon className={sizeClass} />
                </div>
            );
        }
    }
}

export function getTrendIcon(trend: string) {
    switch (trend) {
        case "Increasing": {
            return (props: React.ComponentProps<typeof TrendingUpIcon>) => (
                <TrendingUpIcon {...props} color="#d45b7b" />
            );
        }
        case "Decreasing": {
            return (props: React.ComponentProps<typeof TrendingDownIcon>) => (
                <TrendingDownIcon {...props} color="#4d804d" />
            );
        }
        case "Stagnant": {
            return (props: React.ComponentProps<typeof MoveRightIcon>) => (
                <MoveRightIcon {...props} color="#2f7180" />
            );
        }
        case "Variable": {
            return (props: React.ComponentProps<typeof ActivityIcon>) => (
                <ActivityIcon {...props} color="#5B5BD6" />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    return _.isEqual(arr1, arr2);
}

export function formatUsernames(content: string) {
    const regex = /<@([^>]+)>/g;

    return content.replace(regex, (match, username) => {
        return `<span class="mention">@${username}</span>`;
    });
}

export const emojiMap = new Map<string, string>([
    ["large_green_circle", "🟢"],
    ["large_orange_circle", "🟠"],
    ["large_red_circle", "🔴"],
    ["flag-us", "🇺🇸"], // United States
    ["flag-ca", "🇨🇦"], // Canada
    ["flag-gb", "🇬🇧"], // United Kingdom
    ["flag-fr", "🇫🇷"], // France
    ["flag-de", "🇩🇪"], // Germany
    ["flag-it", "🇮🇹"], // Italy
    ["flag-es", "🇪🇸"], // Spain
    ["flag-jp", "🇯🇵"], // Japan
    ["flag-kr", "🇰🇷"], // South Korea
    ["flag-br", "🇧🇷"], // Brazil
    ["flag-cn", "🇨🇳"], // China
    ["flag-in", "🇮🇳"], // India
    ["flag-au", "🇦🇺"], // Australia
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-se", "🇸🇪"], // Sweden
    ["flag-no", "🇳🇴"], // Norway
    ["flag-nz", "🇳🇿"], // New Zealand
    ["flag-mx", "🇲🇽"], // Mexico
    ["flag-pt", "🇵🇹"], // Portugal
    ["flag-ch", "🇨🇭"], // Switzerland
    ["flag-at", "🇦🇹"], // Austria
    ["flag-be", "🇧🇪"], // Belgium
    ["flag-dk", "🇩🇰"], // Denmark
    ["flag-fi", "🇫🇮"], // Finland
    ["flag-gr", "🇬🇷"], // Greece
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-ua", "🇺🇦"], // Ukraine
    ["flag-ph", "🇵🇭"], // Philippines
    ["flag-za", "🇿🇦"], // South Africa
    ["flag-tw", "🇹🇼"], // Taiwan
    ["flag-id", "🇮🇩"], // Indonesia
    ["satellite_antenna", "📡"],
    ["waving_white_flag", "🏳️"],
]);

export function formatEmojis(content: string) {
    return content.replace(/(:[\w-]+:)/g, (match) => {
        const emojiName = match.slice(1, -1);
        // console.log("emoji name is", emojiName);
        return emojiMap.get(emojiName) || match;
    });
}

export function cleanText(text: string): string {
    // Replace HTML tags with an empty string
    const htmlTagRegex = /<[^>]*>/g;
    let cleanedText = text.replace(htmlTagRegex, " ");

    // Remove images
    cleanedText = cleanedText.replace(/!\[.*?\]\(.*?\)/g, "");
    // Remove headers
    cleanedText = cleanedText.replace(/^#.*$/gm, "");
    // Remove blockquotes
    cleanedText = cleanedText.replace(/^> .*/gm, "");

    // Replace newlines with a single space and trim leading/trailing spaces
    return cleanedText.replace(/(\r\n|\n|\r)/gm, " ").trim();
}

export function getHtmlStringFromReactContent(
    reactElement: React.ReactNode,
): string {
    return ReactDOMServer.renderToString(reactElement);
}

export const getIconForType = (type: string) => {
    switch (type) {
        case "Tag":
            return <TagIcon className="w-3 h-3" />;
        case "Topic":
            return <ListChecksIcon className="w-3 h-3" />;
        case "Status":
        case "Insight Status":
            return <StarIcon className="w-3.5 h-3.5" />;
        case "Assignee":
            return <PersonIcon className="w-3.5 h-3.5" />;
        default:
            return null;
    }
};

export function saveTag(
    tag: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    const requestData: TicketTag = {
        ids: ids,
        tag: tag,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ bot_category: tag });
            console.log(`Updated tag to ${tag} successfully`);
        } else {
            console.log("Call to update tag failed");
        }
    });
}

export const toggleTopicSelection = (
    topic: string,
    issueState: Query,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
    topicsMap: Map<string, GetTopicsResponse>,
) => {
    const allTopics: string[] = issueState.topic;
    const isSelected = allTopics.includes(topic);
    let newTopicsSelected: string[];
    let addedTopics: string[] = [];
    let deletedTopics: string[] = [];

    if (isSelected) {
        newTopicsSelected = allTopics.filter((t) => t !== topic) || [];
        deletedTopics = [topic];
    } else {
        newTopicsSelected = [...(allTopics || []), topic];
        addedTopics = [topic];
    }

    updateIssueState({ topic: newTopicsSelected });
    saveTopics(addedTopics, deletedTopics, api, ids, userID);
};

export function saveTopics(
    addedTopics: string[],
    deletedTopics: string[],
    api: AxiosInstance,
    ids: string[],
    userID: string,
) {
    const requestData: TicketTopics = {
        ids: ids,
        added_topics: addedTopics,
        deleted_topics: deletedTopics,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
        } else {
            console.log("Call to update topics failed");
        }
    });
}

export function saveStatus(
    status: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >,
) {
    updateIssueState({ disabled: true });
    const requestData: TicketStatus = {
        ids: ids,
        status: status,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            refetch();
            updateIssueState({ ticket_status: status });
            console.log(`Updated status to ${status} successfully`);
        } else {
            console.log("Call to update status failed");
        }
    });
}

export function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ assignee_user_id: assigneeID });
            console.log(`Updated assignee to ${assigneeID} successfully`);
        } else {
            console.log("Call to update assignee failed");
        }
    });
}

export const mapsEqual = (
    mapA: Map<string, Set<string>>,
    mapB: Map<string, Set<string>>,
) => {
    if (mapA.size !== mapB.size) return false;
    for (const [key, value] of Array.from(mapA.entries())) {
        if (!mapB.has(key) || mapB.get(key) !== value) {
            return false;
        }
    }
    return true;
};

export const toggleTeamSelection = (team: Teams, tagTeams: Teams[]) => {
    let newTagTeams = Array.from(tagTeams);
    if (newTagTeams.map((t) => t.id).includes(team.id)) {
        // Can't untoggle workspace if that's the only option selected
        if (team.team_name !== "Workspace") {
            // Delete the team if it's already in tag teams
            newTagTeams = newTagTeams.filter((t) => t.id !== team.id);
        }
    } else {
        // Workspace represents an organization level setting so if selected, it should not include other teams
        if (
            team.team_name === "Workspace" ||
            newTagTeams.map((t) => t.team_name).includes("Workspace")
        ) {
            newTagTeams = [team];
        } else {
            // Add the team if it's not there yet
            newTagTeams.push(team);
        }
    }
    return newTagTeams;
};

export const toggleCompanySelection = (
    company: Account,
    customerCompany: Account | undefined,
) => {
    // Removing customer from company
    if (customerCompany?.id === company.id) {
        return undefined;
    } else {
        return company;
    }
};

// Temporary solution to resolve the slightly different Ticket & Query types
// TODO: these two types should be combined in the future
export function convertTicketToQuery(ticket: Ticket): Query {
    return {
        id: ticket.id,
        ticket_identifier: ticket.ticket_identifier,
        ticket_number: ticket.number,
        number: ticket.number,
        title: ticket.title,
        query: ticket.query,
        created_at: ticket.created_at,
        ticket_updated_at: ticket.updated_at,
        bot_category: ticket.bot_category,
        source: ticket.source || undefined,
        topic: ticket.topic,
        ticket_status: ticket.ticket_status,
        url: ticket.url,
        source_specific_id: ticket.source_specific_id,
        ai_response: ticket.ai_response || undefined,
        internal_note: ticket.internal_note || undefined,
        assignee_user_id: ticket.assignee_user_id || undefined,
        external_issues: ticket.external_issues.map((issue) =>
            issue.toString(),
        ),
        breaching: ticket.breaching || undefined,
        user_info: {
            id: ticket.assignee_user_id || "",
            name: "", // Placeholder for the name
            email: "", // Placeholder for email
        },
        assembly_responded: false, // Placeholder value
        business_hours_id: ticket.business_hours_id || undefined,
        original_timestamp: ticket.created_at, // Placeholder for the original timestamp
        disabled: false, // Placeholder for the disabled flag
        teams: ticket.teams,
        user: ticket.assignee_user_id || "Unknown", // Use assignee_user_id or a fallback
        time_created: ticket.created_at,
        time_responded: ticket.updated_at,
        time_closed: "", // Placeholder, should be set if ticket has a closed date
        source_unique_name: ticket.source_unique_name,
    };
}

export const insightRelatedInteractionsBadge = (count: number) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="soft"
                        className="m-0.5"
                    >
                        <div className="flex flex-row items-center">
                            <CaretUpIcon />
                            <p className="pl-0.5">{count}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>

                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[85px] text-center z-50">
                        <p>Interaction Count</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const insightMonetaryValueBadge = (monetary_value: number) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="ring-[0.8px] text-gray-700 ring-[#E0E1E6] flex items-center gap-0.5 "
                    >
                        <DollarSignIcon strokeWidth={1.5} size={11} />
                        <p className="text-xs">
                            {Number.isInteger(monetary_value)
                                ? monetary_value.toString()
                                : monetary_value.toFixed(2)}
                        </p>
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[120px] text-center z-50">
                        <p>Monetary Value Per Month</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const handleAccountBadgeClick = (
    account: AccountMonetaryRecord,
    event?: React.MouseEvent,
) => {
    if (event) {
        event.stopPropagation();
    }
    switch (account.type) {
        case "Company":
            window.open(
                `${process.env.REACT_APP_CALLBACK_URL}accounts/${account.id}`,
                "_blank",
            );
            return;
        case "Customer":
            window.open(
                `${process.env.REACT_APP_CALLBACK_URL}accounts/individual/${account.id}`,
                "_blank",
            );
            return;
        default:
            return;
    }
};

export const insightAccountBadge = (
    c: AccountMonetaryRecord,
    handleClick: (
        account: AccountMonetaryRecord,
        event?: React.MouseEvent,
    ) => void,
) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color={c.type === "Company" ? "blue" : "orange"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="text-xs px-2 py-1.5 flex items-center gap-1 text-gray-700 bg-gray-50 cursor-pointer"
                        key={c.id}
                        onClick={(event) => handleAccountBadgeClick(c, event)}
                    >
                        {c.image_url !== "" && c.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={c.image_url}
                                    alt={c.name}
                                />
                            </div>
                        ) : (
                            <div className="lb-avatar rounded w-4 h-4">
                                <ImageIcon className="w-4 h-4" />
                            </div>
                        )}
                        {c.name}
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[105px] text-center z-50 flex items-center gap-1">
                        <p>
                            {c.type === "Company"
                                ? "Company Customer"
                                : "Individual Customer"}
                        </p>
                        <OpenInNewWindowIcon className="w-5 h-5" />
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const insightAdditionalAccountsBadge = (
    numRemainingCustomers: number,
) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="gray"
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="text-xs px-2 py-1.5 flex items-center gap-0.5 text-gray-700 bg-gray-50"
                    >
                        <PlusIcon className="w-3 h-3" />
                        {numRemainingCustomers}
                    </Badge>
                </TooltipTrigger>
                {createPortal(
                    <TooltipContent className="bg-[#5B5BD6] max-w-[88px] text-center z-50">
                        <p>{`+${numRemainingCustomers} More Customer${numRemainingCustomers === 1 ? "" : "s"}`}</p>
                    </TooltipContent>,
                    document.body,
                )}
            </Tooltip>
        </TooltipProvider>
    );
};

export const getFilterOption = (
    type: string,
    filterType: FilterType,
    showTrigger: boolean,
) => {
    return {
        type: type,
        filterType: filterType,
        showTrigger: showTrigger,
    };
};

export const lastInteractionBadge = (last_ticket: CustomerTicket) => {
    let lastTicketDate = new Date(last_ticket?.created_at);
    if (Number.isNaN(lastTicketDate.getTime()) || !last_ticket?.created_at) {
        lastTicketDate = new Date();
    }

    const today = new Date();

    const isToday =
        lastTicketDate.getDate() === today.getDate() &&
        lastTicketDate.getMonth() === today.getMonth() &&
        lastTicketDate.getFullYear() === today.getFullYear();

    let date: string;
    if (isToday) {
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleTimeString(userLocale, {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } else {
        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        date = lastTicketDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="iris"
                        size="1"
                        radius="medium"
                        variant="soft"
                        className="text-[11px] px-2 py-1 items-center"
                    >
                        <div className="flex flex-row gap-1.5">
                            <MailboxIcon className="h-4 w-4" />
                            <p>{date}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Last Interaction</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const ticketCountBadge = (status: string, count: number) => {
    const Icon = getStatusIcon(status ?? "Unknown");
    const type = status === "NeedsResponse" ? "Needs Response" : status;
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        size="1"
                        color="gray"
                        radius="medium"
                        variant={undefined}
                        className={`text-xs text-gray px-2 py-1 items-center border ${type === "Breaching" ? "bg-[#FFF6F8] border-[#FFE9ED]" : "bg-[#EEF1F0] border-[#E6E9E8]"}`}
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            {Icon ? (
                                <Icon />
                            ) : (
                                <QuestionMarkCircledIcon className="w-2 h-2" />
                            )}
                            <p>{type}</p>
                            <p>{count}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>{`Number of ${type} Interactions`}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const getNavLink = (
    account: Account,
    listType: AccountsListType,
    teamID?: string,
) => {
    if (account.account_type === "Company") {
        if (listType === AccountsListType.Team && teamID) {
            return `/teams/${teamID}/accounts/${account.id}`;
        } else {
            return `/accounts/${account.id}`;
        }
    }
    if (listType === AccountsListType.Team && teamID) {
        return `/teams/${teamID}/accounts/individual/${account.id}`;
    } else {
        return `/accounts/individual/${account.id}`;
    }
};

export const assigneeBadge = (
    assignee_user_id: string | undefined,
    users: GetUserResponse[],
) => {
    const foundUser: GetUserResponse | undefined = users.find(
        (user) => user.id === assignee_user_id,
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;
    return (
        <TooltipProvider>
            {assignee_user_id && assignee_user_id !== "" ? (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                            {pictureURL && (
                                <img
                                    className="lb-avatar-image"
                                    src={pictureURL}
                                    alt={userName}
                                />
                            )}
                            <span>{userName ?? ""}</span>
                        </div>
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>{`Assignee: ${userName}`}</p>
                    </TooltipContent>
                </Tooltip>
            ) : (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <AvatarIcon className="w-6 h-6 mx-1" />
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>No Assignee</p>
                    </TooltipContent>
                </Tooltip>
            )}
        </TooltipProvider>
    );
};

export const getBadgeForTeam = (team_name: string) => {
    switch (team_name) {
        case "Workspace":
            return (
                <ScnBadge
                    variant="outline"
                    className="gap-1 py-1 px-1.5 text-xs font-normal"
                >
                    <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                        <HotelIcon
                            className="text-blue9"
                            strokeWidth={1.5}
                            size={12}
                        />
                    </div>
                    Workspace
                </ScnBadge>
            );
        case "General":
            return (
                <ScnBadge
                    variant="outline"
                    className="gap-1 py-1 px-1.5 text-xs font-normal"
                >
                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                        <HouseIcon
                            className="text-iris9"
                            strokeWidth={1.5}
                            size={12}
                        />
                    </div>
                    General
                </ScnBadge>
            );
        default:
            return (
                <ScnBadge
                    variant="outline"
                    className="gap-1 py-1 px-1.5 text-xs font-normal"
                >
                    <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                        <UsersIcon
                            className="text-red9"
                            strokeWidth={1.5}
                            size={12}
                        />
                    </div>
                    {team_name}
                </ScnBadge>
            );
    }
};

export const getAccountsLabelType = (type: AccountsLabelsType) => {
    switch (type) {
        case AccountsLabelsType.Tier:
            return "Tier";
        case AccountsLabelsType.Stage:
            return "Stage";
        case AccountsLabelsType.CompanyType:
            return "Company Type";
    }
};

export const getAccountsLabelIcon = (
    type: AccountsLabelsType,
    color?: string,
    size?: number,
) => {
    const sizeClass = size ? `h-${size} w-${size}` : "h-4 w-4";
    switch (type) {
        case AccountsLabelsType.Tier:
            return <SparklesIcon color={color} className={sizeClass} />;
        case AccountsLabelsType.Stage:
            return <Eclipse color={color} className={sizeClass} />;
        case AccountsLabelsType.CompanyType:
            return <StoreIcon color={color} className={sizeClass} />;
    }
};

export const getBadgeForAccountsLabel = (
    label: AccountsLabel,
    condensed?: boolean,
) => {
    return (
        <div className="flex items-center flex-wrap gap-2">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>
                        {condensed ? (
                            <ScnBadge
                                className="flex items-center gap-2 font-normal px-2 py-1.5"
                                variant="outline"
                            >
                                {getAccountsLabelIcon(label.type, label.color)}
                            </ScnBadge>
                        ) : (
                            <ScnBadge
                                className="flex items-center gap-2 font-normal px-2 py-1.5"
                                variant="outline"
                            >
                                {getAccountsLabelIcon(label.type, label.color)}
                                {label.name}
                            </ScnBadge>
                        )}
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p className="flex items-center gap-1">
                            <p className="font-semibold">
                                {getAccountsLabelType(label.type)}:
                            </p>{" "}
                            {label.name}
                        </p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

export function convertAccountLabelsToMap(
    labels: AccountsLabel[],
    accountType: string,
): Map<AccountsLabelsType, AccountsLabel | undefined> {
    const newLabelsByGroup = new Map<
        AccountsLabelsType,
        AccountsLabel | undefined
    >([
        [
            AccountsLabelsType.Tier,
            labels.find((al) => al.type === AccountsLabelsType.Tier) ??
            undefined,
        ],
        [
            AccountsLabelsType.Stage,
            labels.find((al) => al.type === AccountsLabelsType.Stage) ??
            undefined,
        ],
    ]);

    if (accountType === "Company") {
        newLabelsByGroup.set(
            AccountsLabelsType.CompanyType,
            labels.find((al) => al.type === AccountsLabelsType.CompanyType) ??
            undefined,
        );
    }
    return newLabelsByGroup;
}

export const convertMarkdownToPlainText = (markdown: string): string => {
    let plainText = markdown;

    // Remove bold formatting (both **bold** and __bold__)
    plainText = plainText.replace(/(\*\*|__)(.*?)\1/g, "$2");

    // Remove italic formatting (both *italic* and _italic_)
    plainText = plainText.replace(/(\*|_)(.*?)\1/g, "$2");

    // Remove strikethrough formatting (~~strikethrough~~)
    plainText = plainText.replace(/~~(.*?)~~/g, "$1");

    // Remove inline code formatting (e.g. `code`)
    plainText = plainText.replace(/`(.*?)`/g, "$1");

    // Remove unordered list formatting (e.g. - item or * item)
    plainText = plainText.replace(/^(\*|\-|\+)\s+(.*)/gm, "$2");

    // Remove ordered list formatting (e.g. 1. item)
    plainText = plainText.replace(/^\d+\.\s+(.*)/gm, "$1");

    // Remove blockquote formatting (e.g. > blockquote)
    plainText = plainText.replace(/^>\s+(.*)/gm, "$1");

    // Remove any remaining Markdown headers (e.g. # Header, ## Header, ### Header)
    plainText = plainText.replace(/^#+\s+/gm, "");

    // Return the plain text without any Markdown formatting
    return plainText.trim();
};

export function substituteVariablesInMarkdown(
    content: string,
    customerInfo?: UserResponse,
    account?: Account,
    aiResponse?: string,
): string {
    return content.replace(/{{(.*?)}}/g, (match, variableName) => {
        let value: string;
        switch (variableName) {
            case "customer name":
                value = account?.name || customerInfo?.name || match;
                break;
            case "customer username":
                value = customerInfo?.username || match;
                break;
            case "customer email":
                value = customerInfo?.email || match;
                break;
            case "company name":
                if (account?.company) {
                    value = account.company?.name || match;
                } else {
                    value = match;
                }
                break;
            case "ai response":
                value = aiResponse || match;
                break;
            default:
                value = match; // Return the original match if variable name is not recognized
                break;
        }
        return value;
    });
}

// Define your custom transformer for variables
export const VARIABLE_TRANSFORMER: TextMatchTransformer = {
    dependencies: [TextNode],

    // Export function: Convert the TextVariableNode back to the format {{variable}}
    export: (node, exportChildren, exportFormat) => {
        if (node instanceof VariableNode) {
            return `{{${node.getTextContent()}}}`;
        }
        return null;
    },

    importRegExp: /{{([^}]+)}}/,
    regExp: /{{([^}]+)}}/,

    // Replace function: When a match is found, replace it with a TextVariableNode
    replace: (node, match) => {
        if (match?.[1]) {
            const variableNode = new VariableNode(match[1]);
            node.replace(variableNode);
        }
    },
    // Trigger (not required but can be used to explicitly specify how to trigger transformation)
    trigger: "{{",
    type: "text-match",
};

export const TRANSFORMERS: Transformer[] = [
    ...originalTransformers,
    VARIABLE_TRANSFORMER,
] as Transformer[];

// Component to render {{variable}} tags
export const VariableTag = ({ variable }: { variable: string }) => {
    return (
        <span
            style={{
                backgroundColor: "transparent",
                border: "1px solid #5B5BD6",
                padding: "2px 5px",
                margin: "1px 3px",
                borderRadius: "6px",
                color: "#5B5BD6",
                fontSize: "14px",
                fontWeight: "bold",
                userSelect: "none",
            }}
        >
            {variable}
        </span>
    );
};

export const renderVariables = (text: string) => {
    const regex = /{{(.*?)}}/g;
    return text.replace(regex, (match, content) => {
        return `<span style="background-color: transparent; 
                                border: 1px solid #5B5BD6; 
                                padding: 1px 4px; 
                                margin: 1px 2px; 
                                border-radius: 6px; 
                                color: #5B5BD6; 
                                font-size: 11px; 
                                line-height: 1.4;
                                display: inline-block;
                                user-select: none;" 
                    contenteditable="false">
                    ${content}
                </span>`;
    });
};

const urlRegExp = new RegExp(
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);
export function validateUrl(url: string): boolean {
    return url === "https://" || urlRegExp.test(url);
}

const URL_REGEX =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
const EMAIL_REGEX =
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const MATCHERS = [
    createLinkMatcherWithRegExp(URL_REGEX, (text) => {
        return text;
    }),
    createLinkMatcherWithRegExp(EMAIL_REGEX, (text) => {
        return `mailto:${text}`;
    }),
];

export const getTopicColors = (topics: GetTopicsResponse[]) => {
    return topics.map((topic) => ({
        color: topic.color ?? "#9B9EF0",
        label: topic.topic_name,
        value: topic.topic_name,
        key: topic.id
    }));
};

export const getInteractionsHeading = (
    listType: IssueListType,
    viewName?: string,
    teamName?: string,
    includeInteractions?: boolean
): string => {
    if (listType === IssueListType.View && viewName) {
        return includeInteractions ? `${viewName} Interactions` : viewName
    } else if (listType === IssueListType.Team && teamName) {
        return includeInteractions ? `${teamName} Interactions` : teamName
    }
    switch (listType) {
        case IssueListType.Inbox:
            return "Inbox"
        case IssueListType.Issues:
            return "Interactions"
        default:
            return "Interactions"
    }
};

export const getInteractionsIcon = (
    listType: IssueListType,
    size: number
) => {
    const iconSize = `${size}`;
    switch (listType) {
        case IssueListType.Inbox:
            return <DrawingPinIcon className={`h-${iconSize} w-${iconSize}`} />;
        case IssueListType.Issues:
            return <MailboxIcon className={`h-${iconSize} w-${iconSize}`} />;
        case IssueListType.Team:
            return <UsersIcon className={`h-${iconSize} w-${iconSize}`} />;
        case IssueListType.View:
            return <LayersIcon className={`h-${iconSize} w-${iconSize}`} />;
        default:
            return <MailboxIcon className={`h-${iconSize} w-${iconSize}`} />;
    }
};

export const SavedViewBadge = (name: string) => {
    return <ScnBadge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal">
        <div className="flex items-center justify-center rounded-lg p-1 bg-gray3 border border-gray4 shadow-sm">
            <LayersIcon strokeWidth={1.5}
                size={12} />
        </div>
        {name}
    </ScnBadge>
}

export const lastTriggered = (workflow: Workflow) => {
    let lastTriggeredDate = new Date(workflow?.last_triggered);
    if (Number.isNaN(lastTriggeredDate.getTime()) || !workflow?.last_triggered) {
        lastTriggeredDate = new Date();
    }

    const today = new Date();

    const isToday =
        lastTriggeredDate.getDate() === today.getDate() &&
        lastTriggeredDate.getMonth() === today.getMonth() &&
        lastTriggeredDate.getFullYear() === today.getFullYear();

    let date: string;
    if (isToday) {
        const userLocale = navigator.language || "en-US";
        date = lastTriggeredDate.toLocaleTimeString(userLocale, {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    } else {
        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        date = lastTriggeredDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }

    return workflow.last_triggered ? (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="iris"
                        size="1"
                        radius="medium"
                        variant="soft"
                        className="text-[11px] px-2 py-1 items-center"
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            <TargetIcon className="text-[#5e6ad2]" strokeWidth={1.5} size={12} />
                            <p>{date}</p>
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Last Triggered</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    ) : (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Badge
                        color="iris"
                        size="1"
                        radius="medium"
                        variant="soft"
                        className="text-[11px] px-2 py-1 items-center"
                    >
                        <div className="flex flex-row items-center gap-1.5">
                            <TargetIcon className="text-[#5e6ad2]" strokeWidth={1.5} size={12} />
                            Never
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Never Triggered</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export const workflowTypeBadge = (workflow: Workflow) => {
    return <Badge
        variant="soft"
        className="px-2 py-1 text-gray-500 rounded-sm text-[11px]"
        style={{
            backgroundColor:
                workflow
                    .type
                    ?.color,
        }}
    >
        {
            workflow
                .type
                .name
        }
    </Badge>
}

export const workflowFrequencyBadge = (workflow: Workflow) => {
    return <TooltipProvider>
        <Tooltip>
            <TooltipTrigger asChild>
                <Badge
                    color="gray"
                    size="1"
                    radius="full"
                    variant="soft"
                    className="m-0.5 text-[11px]"
                >
                    <div className="flex flex-row items-center gap-0.5">
                        <CaretUpIcon />
                        {workflow.freq}
                    </div>
                </Badge>
            </TooltipTrigger>
            <TooltipContent className="bg-[#5B5BD6]">
                <p>Frequency</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}

export const workflowEnabled = (workflow: Workflow) => {
    if (workflow.enabled) {
        return <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <CheckCircledIcon className="text-green-500 w-5 h-5" />
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Workflow is Enabled</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    } else {
        return <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <CrossCircledIcon className="text-red-500 w-5 h-5" />
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6]">
                    <p>Workflow is Disabled</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    }
}

export function capitalizeFirstLetter(str: string): string {
    if (!str) return str; // Check for empty string
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stepToNodeMetadata(
    type: string,
    name: string,
    subtype: string,
    metadata: Map<string, any>
) {
    switch (type) {
        case "trigger":
            return { type: Array.from(metadata.keys())[0], name: name };
        case "condition":
            const filters = new Map<string, Set<{ label: string; value: string; key: string; color: string }>>();
            let i = 0
            for (const [key, value] of metadata.entries()) {
                switch (key) {
                    case "source": {
                        // TODO: add support for channels
                        for (const [sourceKey, sourceValue] of Object.entries(value)) {
                            filters.set(`Source_${i}`, new Set([{
                                label: sourceKey,
                                value: sourceKey,
                                key: sourceKey,
                                color: ""
                            }]))
                            i += 1
                        }
                        break;
                    }
                    case "label": {
                        for (const [labelKey, labelValue] of Object.entries(value)) {
                            for (const label of (labelValue as { key: string, name: string, color: string }[])) {
                                filters.set(`${capitalizeFirstLetter(labelKey)}_${i}`, new Set([{
                                    label: label.name,
                                    value: label.name,
                                    key: label.key,
                                    color: label.color ?? "" // TODO: grab the label color or store it in metadata
                                }]));
                                i += 1;
                            }
                        }
                        break;
                    }
                    case "team": {
                        for (const team of Array.from(value) as { key: string, name: string }[]) {
                            filters.set(`Team_${i}`, new Set([{
                                label: team.name,
                                value: team.name,
                                key: team.key,
                                color: ""
                            }]));
                            i += 1;
                        }
                        break;
                    }
                    case "customer_group": {
                        for (const cg of Array.from(value) as { key: string, name: string }[]) {
                            filters.set(`Customer Group_${i}`, new Set([{
                                label: cg.name,
                                value: cg.name,
                                key: cg.key,
                                color: ""
                            }]));
                            i += 1;
                        }
                        break;
                    }
                }
            }
            return { subtype: subtype, name: name, filters: filters };
        case "wait":
            return { unit: metadata.get("unit"), time: metadata.get("time") };
        case "action":
            return { subtype: subtype, name: name };
        default:
            return {}; // default case, returns an empty object if type doesn't match
    }
}

export function nodeToStepMetadata(
    type: string,
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    metadata: Map<string, any>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): Map<string, any> {
    const stepMetadata: Map<string, any> = new Map();
    switch (type) {
        case "trigger": {
            stepMetadata.set(metadata.get("type"), {})
            return stepMetadata;
        }
        case "condition": {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            for (const [typeIndex, value] of (metadata.get("filters") ?? [])) {
                const conditionType = typeIndex.toLowerCase().split("_")[0];
                switch (conditionType) {
                    case "source": {
                        let currSources = stepMetadata.get("source")
                        for (const sourceType of value) {
                            const sourceTypeVal = sourceType.value
                            if (!currSources) {
                                stepMetadata.set("source", new Map())
                                currSources = new Map()
                            }
                            // If source type is already in list, just add the key name
                            if (currSources.has(sourceTypeVal)) {
                                // TODO: implement channel specific logic
                            } else {
                                // Otherwise add the source type
                                currSources.set(sourceTypeVal, [{ key: "*ALL*", name: "*ALL*" }])
                                stepMetadata.set("source", currSources)
                            }
                        }
                        stepMetadata.set("source", currSources)
                        break
                    }
                    case "topic":
                    case "tag": {
                        let currLabelTypes = stepMetadata.get("label")
                        if (!currLabelTypes) {
                            stepMetadata.set("label", new Map())
                            currLabelTypes = new Map()
                        }
                        let currLabels = stepMetadata.get("label").get(conditionType)
                        for (const label of value) {
                            if (!currLabels) {
                                currLabels = [{ key: label.key, name: label.label }]
                            } else {
                                currLabels.append({ key: label.key, name: label.label })
                            }
                        }
                        currLabelTypes.set(conditionType, currLabels)
                        stepMetadata.set("label", currLabelTypes)
                        break
                    }
                    case "team": {
                        let currTeams = stepMetadata.get("team")
                        for (const team of value) {
                            if (!currTeams) {
                                currTeams = [{ key: team.key, name: team.label }]
                            } else {
                                currTeams.push({ key: team.key, name: team.label })
                            }
                        }
                        stepMetadata.set("team", currTeams)
                        break
                    }
                    case "customer group": {
                        let currCustomerGroups = stepMetadata.get("customer_group")
                        for (const cg of value) {
                            if (!currCustomerGroups) {
                                currCustomerGroups = [{ key: cg.key, name: cg.label }]
                            } else {
                                currCustomerGroups.push({ key: cg.key, name: cg.label })
                            }
                        }
                        stepMetadata.set("customer_group", currCustomerGroups)
                        break
                    }
                }
            }
            return stepMetadata;
        }
        case "wait": {
            stepMetadata.set("time", metadata.get("time") ?? 0);
            stepMetadata.set("unit", metadata.get("unit") ?? "days");
            return stepMetadata;
        }
        case "action": {
            // template, message, label, assignee, sla, ai responding, teams
            // TODO: add other dropdown for selecting specific template / assignee / label / etc
            switch (metadata.get("subtype")) {
                case "send_template": {
                    stepMetadata.set("template", [{ key: "", name: "" }])
                    // const template = metadata.get("template")
                    // stepMetadata.set("template", [{ key: template.key, name: template.label }])
                    break;
                }
                case "send_message": {
                    // TODO: store the message
                    stepMetadata.set("template", [{ key: "", name: "" }])
                    break;
                }
                case "add_assignee": {
                    stepMetadata.set("assignee", [{ key: "", name: "" }])
                    // const assignee = metadata.get("assignee")
                    // stepMetadata.set("assignee", [{ key: assignee.key, name: assignee.label }])
                    break;
                }
                // TODO: process the different label types
                case "add_label": {
                    const label = metadata.get("label")
                    stepMetadata.set("label", [{ key: label.key, name: label.label }])
                    break;
                }
            }
            return stepMetadata;
        }
        default:
            return new Map(); // Return an empty Map for the default case
    }
}
