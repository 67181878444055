import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/component/shadcn/ui/table";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetTopicsResponse,
    GetUserResponse,
    Integration,
    OrgInfoResponse,
    ScopeResponse,
    Topic,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import {
    AllSidesIcon,
    CheckCircledIcon,
    Cross2Icon,
    DotsHorizontalIcon,
    ExclamationTriangleIcon,
    Pencil2Icon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Box, Flex } from "@radix-ui/themes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CheckIcon, TrashIcon, XIcon } from "lucide-react";
import type React from "react";
import { useRef, useState } from "react";
import WorkflowPopup from "./WorkflowPopup";

import { supportedThenConstants } from "./constants";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
export interface Action {
    action: string;
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    metadata: Object;
}

export interface Workflow {
    id?: string;
    enabled?: boolean;
    name: string;
    when: string;
    if: {
        source: string;
        channels: ScopeResponse[];
        topic: string;
    };
    then: Action[];
    org_id?: string;
    last_triggered?: string;
    created_at?: string;
    updated_at?: string;
}

const Workflows: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
        null,
    );
    const [errorMsg, setErrorMsg] = useState("");
    const [validationErr, setValidationErr] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const topicsQuery = useQuery<Topic[]>({
        queryKey: ["topics"],
        queryFn: () =>
            api.get(`${URLS.serverUrl}${API.getTopics}`).then((res) => {
                const topics: GetTopicsResponse[] = res.data.data;

                const newTopics: Topic[] = [];

                const map = new Map<string, GetTopicsResponse>();

                for (const topic of topics) {
                    newTopics.push({
                        color: (topic.color && topic.color !== "") ? topic.color : "#9B9EF0",
                        label: topic.topic_name,
                        value: topic.id,
                        key: topic.id
                    });
                    map.set(topic.topic_name, topic);
                }
                return newTopics;
            }),
    });

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: ["Slack", "CommunitySlack", "Discord", "Google", "API"],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab Google Integrations
                if (orgInfo.Google) {
                    api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab API integrations
                if (orgInfo.API) {
                    api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/API`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("API", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }

            return theMap;
        },
    });

    const api = useApi();
    const queryClient = useQueryClient();

    const {
        data: workflowsData,
        isLoading: isLoadingWorkflows,
        error: workflowsError,
    } = useQuery<Workflow[]>({
        queryKey: ["workflows"],
        queryFn: () =>
            api.get(`${URLS.serverUrl}${API.getWorkflows}`).then((res) => {
                const data: Workflow[] = res.data.data;
                return data;
            }),
    });

    const clearWorkflowDetails = () => {
        setSelectedSource(undefined);
        setSelectedChannels([]);
        setSelectedTopic(undefined);
        setSelectedWorkflow(null);
        setSelectedThenTopic("");
        setSelectedTag("");
        setSelectedAssignee("");
    };

    const addWorkflowMutation = useMutation({
        mutationFn: (newWorkflow: Workflow) =>
            api.post(`${URLS.serverUrl}${API.saveWorkflow}`, newWorkflow),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
            setOpen(false);
        },
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onError: (error: any) => {
            setErrorMsg(`Creation failed: ${error.message}`);
            setValidationErr(true);
        },
    });

    const updateWorkflowMutation = useMutation({
        mutationFn: (updatedWorkflow: Workflow) =>
            api.patch(
                `${URLS.serverUrl}${API.updateWorkflow}/${updatedWorkflow.id}`,
                updatedWorkflow,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
            setOpen(false);
        },
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onError: (error: any) => {
            setErrorMsg(`Update failed: ${error.message}`);
            setValidationErr(true);
        },
    });

    const toggleWorkflowMutation = useMutation({
        mutationFn: (workflow: Workflow) =>
            api.patch(`${URLS.serverUrl}${API.updateWorkflow}/${workflow.id}`, {
                enabled: !workflow.enabled,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
        },
        onError: (error) => {
            console.error("Toggle workflow failed:", error.message);
            setValidationErr(true);
        },
    });

    const deleteWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            return api.delete(`${URLS.serverUrl}${API.deleteWorkflow}/${id}`); // Make sure this is the right endpoint and id is passed correctly
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
            setDeleteDialogOpen(false);
        },
        onError: (error) => {
            console.error("Delete workflow failed:", error.message);
        },
    });

    const [supportedWhen, setSupportedWhen] = useState<string[]>([
        "Issue is Created",
    ]);
    const [supportedThen, setSupportedThen] = useState<string[]>([
        supportedThenConstants.AssignIssueTo,
        supportedThenConstants.AssignTag,
        supportedThenConstants.AssignTopic,
    ]);

    const [supportedFields, setSupportedFields] = useState<string[]>([
        "Source",
        "Channel",
        "Topics",
    ]);

    const [icon, setIcon] = useState<React.ElementType>();

    const [selectedSource, setSelectedSource] = useState<string | undefined>(
        undefined,
    );
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [selectedTopic, setSelectedTopic] = useState<string | undefined>(
        undefined,
    );
    const [selectedThenTopic, setSelectedThenTopic] = useState<string>("");
    const [selectedTag, setSelectedTag] = useState<string>("");
    const [selectedAssignee, setSelectedAssignee] = useState<string>("");


    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users1"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const tagsQuery = useQuery<Category[]>({
        queryKey: ["tags1"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Workflows</h2>
                <p className=" text-sm text-gray11">
                    Manage your smart routing workflows
                </p>
                <div className="flex flex-col items-end gap-2">
                    <WorkflowPopup
                        workflow={undefined}
                        triggerElement={
                            <Button
                                onClick={() => {
                                    clearWorkflowDetails(); // Clear the state before creating a new workflow
                                }}
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                size="sm"
                                variant="outline"
                            >
                                Add Workflow
                                <PlusIcon />
                            </Button>
                        }
                        selectedSource={selectedSource}
                        setSelectedSource={setSelectedSource}
                        options={channelsQuery?.data ?? new Map()}
                        supportedFields={supportedFields}
                        setSupportedFields={setSupportedFields}
                        selectedChannels={selectedChannels}
                        setSelectedChannels={setSelectedChannels}
                        selectedTopic={selectedTopic}
                        setSelectedTopic={setSelectedTopic}
                        addWorkflow={addWorkflowMutation}
                        updateWorkflow={updateWorkflowMutation}
                        icon={icon}
                        editing={false}
                        clearWorkflowDetails={clearWorkflowDetails}
                        supportedWhen={supportedWhen}
                        setSupportedWhen={setSupportedWhen}
                        supportedThen={supportedThen}
                        setSupportedThen={setSupportedThen}
                        users={usersQuery.data ?? []}
                        topics={topicsQuery.data ?? []}
                        tags={tagsQuery.data ?? []}
                        selectedTag={selectedTag}
                        setSelectedTag={setSelectedTag}
                        selectedAssignee={selectedAssignee}
                        setSelectedAssignee={setSelectedAssignee}
                        selectedThenTopic={selectedThenTopic}
                        setSelectedThenTopic={setSelectedThenTopic}
                    />
                </div>

                {isLoadingWorkflows && <div>Loading workflows...</div>}

                {workflowsError && (
                    <Alert variant="destructive">
                        <ExclamationTriangleIcon className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>
                            Failed to load workflows. Please try again later.
                        </AlertDescription>
                    </Alert>
                )}

                {!isLoadingWorkflows &&
                    !workflowsError &&
                    (!workflowsData || workflowsData.length === 0) && (
                        <p>
                            No workflows found. Create a new workflow to get
                            started.
                        </p>
                    )}

                {workflowsData && workflowsData?.length > 0 && (
                    <Table>
                        <TableHeader>
                            <TableRow key={"headerGroup"}>
                                <TableHead>Name</TableHead>
                                <TableHead>Source</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {workflowsData.map((workflow) => (
                                <TableRow key={workflow.id}>
                                    <TableCell key={`${workflow.id}/name`}>
                                        {
                                            <Flex direction="column">
                                                <p className="text-base">
                                                    {workflow.name}
                                                </p>
                                                {/* <p className="text-sm text-slate-500">
                                                    Last triggered{" "}
                                                    {workflow.last_triggered
                                                        ? intlFormatDistance(
                                                              workflow.last_triggered,
                                                              new Date(),
                                                          )
                                                        : "never"}
                                                </p> */}
                                            </Flex>
                                        }
                                    </TableCell>
                                    <TableCell key={`${workflow.id}/source`}>
                                        {workflow.if.source && (
                                            (() => {
                                                const Icon = integrationBackEndDataMappingToSvg.get(workflow.if.source) ?? AllSidesIcon;
                                                return (
                                                    <div className="flex items-center gap-2 size-6">
                                                        <Icon className="w-4 h-4" />
                                                    </div>
                                                );
                                            })()
                                        )}
                                    </TableCell>
                                    <TableCell key={`${workflow.id}/trigger`}>
                                        {workflow.enabled !== undefined &&
                                            workflow.enabled ? (
                                            <div className="bg-green-500 rounded-full p-1 flex items-center justify-center w-[32px]">
                                                <CheckIcon className="text-white w-4 h-4" />
                                            </div>
                                        ) : (
                                            <div className="bg-red-500 rounded-full p-1 flex items-center justify-center w-[32px]">
                                                <XIcon className="text-white w-4 h-4" />
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell key={`${workflow.id}/actions`}>
                                        <DropdownMenu modal={false}>
                                            <DropdownMenuTrigger asChild>
                                                <Button
                                                    variant="ghost"
                                                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                                >
                                                    <DotsHorizontalIcon className="h-4 w-4" />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent
                                                align="end"
                                                className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                                                onClick={(event) =>
                                                    event.stopPropagation()
                                                }
                                            >
                                                <DropdownMenuItem asChild>
                                                    <AlertDialog
                                                        open={deleteDialogOpen}
                                                        onOpenChange={
                                                            setDeleteDialogOpen
                                                        }
                                                    >
                                                        <AlertDialogTrigger
                                                            asChild
                                                        >
                                                            <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex items-center gap-2">
                                                                <TrashIcon className="h-4 w-4" />
                                                                <p>Delete</p>
                                                            </div>
                                                        </AlertDialogTrigger>
                                                        <AlertDialogContent>
                                                            <div>
                                                                <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                                    <AlertDialogTitle>
                                                                        Are
                                                                        you
                                                                        absolutely
                                                                        sure?
                                                                    </AlertDialogTitle>
                                                                    <AlertDialogDescription>
                                                                        This
                                                                        action
                                                                        cannot
                                                                        be
                                                                        undone.
                                                                        This
                                                                        will
                                                                        permanently
                                                                        delete
                                                                        this
                                                                        workflow.
                                                                    </AlertDialogDescription>
                                                                </AlertDialogHeader>
                                                            </div>
                                                            <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                                <AlertDialogCancel>
                                                                    Cancel
                                                                </AlertDialogCancel>
                                                                <AlertDialogAction
                                                                    onClick={() => {
                                                                        deleteWorkflowMutation.mutate(
                                                                            workflow.id ??
                                                                            "",
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </AlertDialogAction>
                                                                <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                                    <Cross2Icon />
                                                                </AlertDialogCancel>
                                                            </AlertDialogFooter>
                                                        </AlertDialogContent>
                                                    </AlertDialog>
                                                </DropdownMenuItem>
                                                <DropdownMenuItem asChild>
                                                    <WorkflowPopup
                                                        workflow={workflow}
                                                        triggerElement={
                                                            <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex items-center gap-2">
                                                                <Pencil2Icon className="h-4 w-4" />
                                                                <p>Edit</p>
                                                            </div>
                                                        }
                                                        selectedSource={
                                                            selectedSource
                                                        }
                                                        setSelectedSource={
                                                            setSelectedSource
                                                        }
                                                        options={
                                                            channelsQuery?.data ??
                                                            new Map()
                                                        }
                                                        supportedFields={
                                                            supportedFields
                                                        }
                                                        setSupportedFields={
                                                            setSupportedFields
                                                        }
                                                        selectedChannels={
                                                            selectedChannels
                                                        }
                                                        setSelectedChannels={
                                                            setSelectedChannels
                                                        }
                                                        selectedTopic={
                                                            selectedTopic
                                                        }
                                                        setSelectedTopic={
                                                            setSelectedTopic
                                                        }
                                                        addWorkflow={
                                                            addWorkflowMutation
                                                        }
                                                        updateWorkflow={
                                                            updateWorkflowMutation
                                                        }
                                                        icon={icon}
                                                        editing={true}
                                                        clearWorkflowDetails={
                                                            clearWorkflowDetails
                                                        }
                                                        supportedWhen={
                                                            supportedWhen
                                                        }
                                                        setSupportedWhen={
                                                            setSupportedWhen
                                                        }
                                                        supportedThen={
                                                            supportedThen
                                                        }
                                                        setSupportedThen={
                                                            setSupportedThen
                                                        }
                                                        users={
                                                            usersQuery.data ??
                                                            []
                                                        }
                                                        topics={
                                                            topicsQuery.data ??
                                                            []
                                                        }
                                                        tags={
                                                            tagsQuery.data ?? []
                                                        }
                                                        selectedTag={
                                                            selectedTag
                                                        }
                                                        setSelectedTag={
                                                            setSelectedTag
                                                        }
                                                        selectedAssignee={
                                                            selectedAssignee
                                                        }
                                                        setSelectedAssignee={
                                                            setSelectedAssignee
                                                        }
                                                        selectedThenTopic={
                                                            selectedThenTopic
                                                        }
                                                        setSelectedThenTopic={
                                                            setSelectedThenTopic
                                                        }
                                                    />
                                                </DropdownMenuItem>
                                                <DropdownMenuItem asChild>
                                                    {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                                                    <div
                                                        onClick={() => {
                                                            toggleWorkflowMutation.mutate(
                                                                workflow,
                                                            );
                                                        }}
                                                        className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex items-center gap-2"
                                                    >
                                                        {workflow.enabled ? (
                                                            <XIcon className="h-4 w-4" />
                                                        ) : (
                                                            <CheckIcon className="h-4 w-4" />
                                                        )}
                                                        <p className="text-xs">
                                                            {workflow.enabled
                                                                ? "Disable"
                                                                : "Enable"}
                                                        </p>
                                                    </div>
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </div>
    );
};

export default Workflows;
