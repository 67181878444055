import {
    type Edge,
    type Node,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    AddWorkflowRequest,
    AddWorkflowStep,
    Label,
} from "@/interfaces/serverData";
import { CaretDownIcon, CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { Badge, Box } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkflowEditor from "./WorkflowEditor";
import { nodeToStepMetadata } from "@/utilities/methods";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { boolean } from "zod";

export const NewWorkflow = () => {
    const api = useApi();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [labels, setLabels] = useState<Label[]>();
    const [workflowName, setWorkflowName] = useState("");
    const [workflowDescription, setWorkflowDescription] = useState("");
    const [workflowType, setWorkflowType] = useState<Label>();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    useEffect(() => {
        if (labels && labels.length > 0) {
            setWorkflowType(labels[0]);
        }
    }, [labels]);

    const navigateToWorklowPage = async (withState?: string) => {
        if (withState) {
            if (withState === "success") {
                navigate("/preferences/workflows_v2", {
                    state: {
                        showToast: true,
                        toastText: "Created Workflow!",
                    }
                });
            } else {
                navigate("/preferences/workflows_v2", {
                    state: {
                        showToast: true,
                        toastText: "Oops! Something's wrong. Please try again at a later time.",
                    }
                });
            }

        } else {
            navigate("/preferences/workflows_v2")
        }
    };

    function serializeStepMetadata(stepMetadata: Map<string, any>): object {
        const plainObject: { [key: string]: any } = {};
        stepMetadata.forEach((value, key) => {
            if (value instanceof Map) {
                // If it's a Map, recursively convert it to an object
                plainObject[key] = Object.fromEntries(value);
            } else if (Array.isArray(value)) {
                // If it's an array, just use it as is
                plainObject[key] = value;
            } else {
                plainObject[key] = value;
            }
        });
        return plainObject;
    }

    // TODO: turn this into a mutation
    const saveWorkflow = async () => {
        // Edge mapping of child node id => parent node id
        const childToParent = new Map<string, string[]>();
        for (const e of edges) {
            const edge = e as unknown as Edge;
            if (childToParent.has(edge.target)) {
                const val: string[] = childToParent.get(edge.target) ?? [];
                val.push(edge.source);
                childToParent.set(edge.target, val);
            } else {
                childToParent.set(edge.target, [edge.source]);
            }
        }

        const steps: AddWorkflowStep[] = [];
        console.log("nodes are ", nodes);
        for (let i = 0; i < nodes.length; i++) {
            const node: Node = nodes[i];
            const type = node.type;
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const nodeData: Record<string, any> = node.data;
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const nodeMetadata: Map<string, any> = new Map(Object.entries(nodeData.metadata));
            const step: AddWorkflowStep = {
                id: node.id,
                name: nodeMetadata.get("name") ?? "",
                // type: WorkflowStepType[type as keyof typeof WorkflowStepType],
                type: type ?? "",
                // subtype: subtype ? WorkflowStepSubtype[type as keyof typeof WorkflowStepSubtype] : undefined,
                subtype: nodeMetadata.get("subtype") ?? "",
                metadata: serializeStepMetadata(nodeToStepMetadata(type ?? "", nodeMetadata)),
                // TODO: handle when parent ids are more than one element (v shape)
                // TODO: probably store two instances of the same child node in workflow_steps
                parent_id: childToParent.get(node.id)?.[0] || "",
            };
            steps.push(step);
        }
        const requestData: AddWorkflowRequest = {
            name: workflowName,
            description: workflowDescription,
            type_id: workflowType?.id,
            steps: steps,
        };

        console.log("request data is", requestData);

        api.post(URLS.serverUrl + API.saveWorkflowNew, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            // TODO: show toast about whether the workflow was created successfully or not
            if (res.status === 200) {
                console.log(
                    `Created workflow ${res.data.data?.id} successfully`,
                );
                queryClient.invalidateQueries({ queryKey: ["workflowsNew"] });
                navigateToWorklowPage("success")
            } else {
                console.log("Failed to create workflow");
                navigateToWorklowPage("error")
            }
        });
    };

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getLabels}/WorkflowType`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data !== "") {
                        setLabels(res.data.data);
                    }
                }
            })
            .catch((res) => {
                console.log("Could not grab workflow type labels");
            });
    }, []);

    return (
        <Box className="h-screen">
            <ReactFlowProvider>
                <Box mt={"3%"} ml={"3%"} mr={"3%"} className="flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <h2 className="text-2xl font-semibold flex items-center gap-2">
                                    Create Workflow
                                </h2>
                                <p className="text-sm text-gray11">
                                    Drag a block to start building your
                                    workflow.
                                </p>
                            </div>
                            <div className="flex items-center gap-4">
                                <Input
                                    className="text-sm font-medium w-[350px]"
                                    placeholder="New Workflow Name..."
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        setWorkflowName(event.target.value);
                                    }}
                                />
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild type="button">
                                        <Badge
                                            size="3"
                                            className="text-gray-500"
                                            style={{
                                                backgroundColor:
                                                    workflowType?.color,
                                            }}
                                            variant="soft"
                                        >
                                            {workflowType?.name}
                                            <CaretDownIcon />
                                        </Badge>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent
                                        align="start"
                                        className="fixed w-[150px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                    >
                                        {labels?.map((type) => (
                                            <DropdownMenuItem
                                                key={type.id}
                                                onClick={() =>
                                                    setWorkflowType(type)
                                                }
                                            >
                                                <Badge
                                                    variant="soft"
                                                    className="px-2 py-1 text-gray-500 rounded-sm"
                                                    style={{
                                                        backgroundColor:
                                                            type?.color,
                                                    }}
                                                >
                                                    {type.name}
                                                </Badge>
                                            </DropdownMenuItem>
                                        ))}
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                            <Input
                                className="text-xs font-medium w-[700px] h-8"
                                placeholder="Description..."
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setWorkflowDescription(event.target.value);
                                }}
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <Button
                                className="bg-gray-200 text-sm"
                                variant="secondary"
                                onClick={() => navigateToWorklowPage()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="bg-[#5B5BD6] text-sm"
                                onClick={() => saveWorkflow()}
                            >
                                Save Workflow
                            </Button>
                        </div>
                    </div>
                    <WorkflowEditor
                        nodes={nodes}
                        edges={edges}
                        setNodes={setNodes}
                        setEdges={setEdges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                    />
                </Box>
            </ReactFlowProvider>
        </Box>
    );
};

export default NewWorkflow;
