import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
} from "@/component/shadcn/ui/breadcrumb";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS, loadingTypes } from "@/constant";
import type {
    Announcement,
    CustomerGroup,
    InsightInfoResponse,
    ScopeResponse,
    Ticket,
    UploadedFile,
    UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { zodResolver } from "@hookform/resolvers/zod";
import { Liveblocks } from "@liveblocks/node";
import { ClientSideSuspense } from "@liveblocks/react";
import { RoomProvider } from "@liveblocks/react";
import { useAuthInfo } from "@propelauth/react";
import {
    CheckCircledIcon,
    ChevronLeftIcon,
    CrossCircledIcon,
    ExclamationTriangleIcon,
    PaperPlaneIcon,
    ResetIcon,
    SpeakerLoudIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { PlusIcon } from "@radix-ui/react-icons";
import { Callout, Flex, Heading, Text } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import { Badge } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { z } from "zod";
import { useApi } from "../../interfaces/api";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
import { useSearch } from "../SearchContext";
import CollaborativeEditor from "./CollaborativeEditor";
import { Loading } from "./Loading";
import {
    AnnouncementsState,
    AnnouncementsType,
    AnnouncementsTypeMap,
} from "./constants";

import { withLexicalDocument } from "@liveblocks/node-lexical";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ListItemNode, ListNode } from "@lexical/list";

import { Card } from "@/component/shadcn/ui/card";

import * as Toast from "@radix-ui/react-toast";

import type { BroadcastInfo } from "./AnnouncementsEditingPage";

import { TrashIcon } from "@radix-ui/react-icons";

import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/component/shadcn/ui/dialog";
import Markdown from "react-markdown";
import { Avatars } from "./Avatars";

import SavedInsightDialogPopup from "@/Insights/SavedInsightDialogPopup";
import { FancyMultiSelect } from "@/component/MultiSelect";
import { defaultTicket } from "@/interfaces/defaultTypes";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import slackifyMarkdown from "slackify-markdown";
import { EmojiNode } from "../../component/textEditor/nodes/EmojiNode";
import { AnnouncementsBroadcastSources } from "./AnnouncementBroadcastSources";
import { AnnouncementsBulkReplies } from "./AnnouncementBulkReply";

const formSchema = z.object({
    broadcastName: z
        .string()
        .min(2, { message: "The topic name must be at least 2 characters." })
        .max(70, { message: "The topic name must be at most 70 characters." }),
    sourceMetadata: z.array(z.object({ source: z.string() })),
    customerGroupMetadata: z.array(z.object({ customer_group: z.string() })),
});

const liveblocks = new Liveblocks({
    secret: `${process.env.REACT_APP_LIVEBLOCKS_SECRET_KEY}`,
});

interface AnnouncementsProps {
    id: string | undefined;
    announcement: Announcement;
    sourceFields: BroadcastInfo[];
    customerGroups: ScopeResponse[];
}

export const AnnouncementsNewDraft: React.FC<AnnouncementsProps> = ({
    id,
    announcement,
    sourceFields,
    customerGroups,
}) => {
    const location = useLocation();
    const from = location.state?.from || "announcements";
    const [uploadedFiles, setUploadedFiles] = useState<
        (UploadedFile | UploadedFileWithMetadata)[]
    >([]);

    useEffect(() => {
        setUploadedFiles(announcement.files || []);
    }, [announcement.files]);

    // const initialStorage = useMemo(() => {
    //     return new LiveList<UploadedFileLive>(
    //         announcement.files?.map(file => new LiveObject({ src: file.src, alt: file.alt }))
    //     );
    // }, [announcement.files]);
    // console.log("initial storage is ", initialStorage)

    console.log("uploaded files in new draft are", uploadedFiles);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErr, setValidationErr] = useState<boolean>(false);
    const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false)
    console.log("send is disabled", isSendDisabled)

    const [toastText, setToastText] = useState<string>("Saved draft");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const api = useApi();
    const { searchBarFilters } = useSearch(); // using this filter to get back sources + scopes

    const [filteredBroadcasts, setFilteredBroadcasts] = useState([
        {
            label: "Slack",
            value: "Slack",
            color: "",
            key: "Slack"
        },
        {
            label: "CommunitySlack",
            value: "CommunitySlack",
            color: "",
            key: "CommunitySlack"
        },
        {
            label: "Discord",
            value: "Discord",
            color: "",
            key: "Discord"
        },
    ]);

    useEffect(() => {
        const updatedBroadcasts = [
            {
                label: "Slack",
                value: "Slack",
                color: "",
                key: "Slack"
            },
            {
                label: "CommunitySlack",
                value: "CommunitySlack",
                color: "",
                key: "CommunitySlack"
            },
            {
                label: "Discord",
                value: "Discord",
                color: "",
                key: "Discord"
            },
        ].filter((broadcast) => searchBarFilters.has(broadcast.value));

        setFilteredBroadcasts(updatedBroadcasts);
    }, [searchBarFilters]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            broadcastName: announcement?.name ? announcement?.name : "",
            sourceMetadata: sourceFields.map((info) => ({
                source: info.selectedBroadcast,
            })),
        },
    });
    const authInfo = useAuthInfo();

    const [orgId, setOrgId] = useState<string>("");
    const navigate = useNavigate();

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "sourceMetadata",
    });

    const timerRef = useRef(0);

    useEffect(() => {
        const orgIds = authInfo.orgHelper?.getOrgIds();
        if (orgIds === undefined || orgIds.length !== 1) {
            navigate("/*");
            return;
        }
        setOrgId(orgIds[0]);
    }, [authInfo.orgHelper, navigate]);

    const [fieldsState, setFieldsState] =
        useState<BroadcastInfo[]>(sourceFields);

    const [customerGroupsState, setCustomerGroupsState] =
        useState<ScopeResponse[]>(customerGroups);

    const [allCustomerGroupsState, setAllCustomerGroupsState] = useState<
        ScopeResponse[]
    >([]);

    const [customerGroupsExist, setCustomerGroupsExist] = useState<boolean>(
        customerGroups?.length !== 0,
    );

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getCustomerGroups}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data.data as CustomerGroup[];
                    const customer_names: ScopeResponse[] = [];
                    for (let i = 0; i < data.length; i++) {
                        customer_names.push({
                            key: data[i]?.id ?? data[i]?.group_name,
                            name: data[i]?.group_name,
                        });
                    }
                    setAllCustomerGroupsState(customer_names);
                }
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
            });
    }, [api]);

    const handleBroadcastChange = (index: number, newBroadcast: string) => {
        const updatedFields = fieldsState.map((field, idx) =>
            idx === index
                ? {
                    ...field,
                    selectedBroadcast: newBroadcast,
                    filters: new Map(field.filters).set(
                        "Broadcast",
                        new Set([newBroadcast]),
                    ),
                    icon:
                        integrationBackEndDataMappingToSvg.get(
                            newBroadcast,
                        ) || undefined,
                    options: [],
                }
                : field,
        );

        setFieldsState(updatedFields);

        api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${newBroadcast}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const dataItems: ScopeResponse[] =
                    res.data.data?.asm_ticket_channels;
                const updatedFieldsWithOptions = updatedFields.map(
                    (field, idx) =>
                        idx === index
                            ? {
                                ...field,
                                options: dataItems, // Set the retrieved options here
                                selectedBroadcast: field.selectedBroadcast,
                            }
                            : field,
                );

                setFieldsState(updatedFieldsWithOptions);
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
            });
    };

    const [aiResponse, setAIResponse] = useState<string>("");
    const [aiResponseCompleted, setAIResponseCompleted] = useState<number>(
        loadingTypes.none,
    );

    const getAIResponse = () => {
        setAIResponseCompleted(loadingTypes.loading);
        api.get(`${URLS.serverUrl}${API.getAIResponse}/${announcement.id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setAIResponse(res.data.data.response);
                    setAIResponseCompleted(loadingTypes.loaded);
                } else {
                    setAIResponseCompleted(loadingTypes.error);
                }
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
                setAIResponseCompleted(loadingTypes.error);
            });
    };

    const defaultSource = "Source...";

    const addNewSource = () => {
        append({ source: defaultSource });
        setFieldsState((prevState) => [
            ...prevState,
            {
                selectedBroadcast: defaultSource,
                options: [],
                selectedChannels: [],
                icon: undefined,
                filters: new Map([["Broadcast", new Set([defaultSource])]]),
            },
        ]);
    };

    const removeSource = (index: number) => {
        remove(index);
        setFieldsState((prevState) =>
            prevState.filter((_, idx) => idx !== index),
        );
    };

    function onSubmit(values: z.infer<typeof formSchema>) {
        console.log(values);
    }

    const [textContent, setTextContent] = useState<string>("");
    const [loading, setLoading] = useState<number>(loadingTypes.loading);

    const getFinalizedTextContent = async () => {
        const roomId = `${orgId}:announcements:${id}`;
        const textContent = await withLexicalDocument(
            {
                roomId: roomId,
                client: liveblocks,
                nodes: [
                    HeadingNode,
                    QuoteNode,
                    ListNode,
                    ListItemNode,
                    EmojiNode,
                ],
            },
            (doc) => doc.toMarkdown(),
        );
        console.log("text content is", textContent);

        setTextContent(textContent);
        setLoading(loadingTypes.loaded);
        return textContent;
    };

    const handleFormSubmit = async () => {
        const files = uploadedFiles.filter((file) => {
            return file.type === "file";
        });

        if (files.length > 0) {
            const statusDraft = AnnouncementsState.draft; // save info
            handleSubmit(statusDraft, true);
        } else {
            const statusDraft = AnnouncementsState.sent;
            handleSubmit(statusDraft, false);
        }
    };

    const saveDraft = async () => {
        const status = AnnouncementsState.draft;
        handleSubmit(status, false);
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isInsightDialogOpen, setIsInsightDialogOpen] = useState(false);

    const handleSubmit = async (
        status: string,
        withFilesAndSubmit: boolean,
    ) => {
        if (form.getValues().broadcastName === "") {
            setErrorMsg("Announcement Name should not be empty.");
            setValidationErr(true);
            return;
        }
        const finalFields = new Map<string, ScopeResponse[]>([]);
        for (const field of fieldsState) {
            if (field.selectedBroadcast !== "Source...") {
                finalFields.set(
                    field.selectedBroadcast,
                    field.selectedChannels,
                );
            }
        }

        const scopeObject = Object.fromEntries(finalFields);
        const metadataPayload = JSON.stringify(scopeObject);

        const content = await getFinalizedTextContent();
        console.log("content is ", content);

        const contentMetadataMap = new Map<string, string>();
        contentMetadataMap.set("Slack", slackifyMarkdown(content));
        contentMetadataMap.set("CommunitySlack", slackifyMarkdown(content));
        const contentMetadataObject = Object.fromEntries(contentMetadataMap);

        const images = uploadedFiles.filter((file) => {
            return file.type === "image";
        });

        const requestData = {
            status: status,
            announcement_type: announcementType,
            id: id,
            name: form.getValues().broadcastName,
            metadata: metadataPayload,
            content: content,
            content_metadata: contentMetadataObject,
            customer_groups: customerGroupsState,
            images: images,
        };

        let filesCalled = false;

        api.post(URLS.serverUrl + API.announcement, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (status === AnnouncementsState.sent) {
                        setToastText("Announcement sent!");
                        navigate("/announcements");
                    } else {
                        setToastText("Announcement saved!");
                        if (withFilesAndSubmit) {
                            filesCalled = true;
                            handleSubmitWithFiles(status);
                        } else {
                            setToastText("Announcement saved (no files)!");
                        }
                    }
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                if (!filesCalled) {
                    // let file handler handle (handleSubmitWithFiles) the toast
                    setOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        console.log("setting open to true");
                        setOpen(true);
                    }, 100);
                }
            });
    };

    const { tokens } = useAuthInfo();

    const handleSubmitWithFiles = async (status: string) => {
        const files = uploadedFiles.filter((file) => {
            return file.type === "file";
        });
        const formData = new FormData();

        // Append non-file fields
        for (const fileData of files) {
            formData.append(
                "files",
                (fileData as UploadedFileWithMetadata).file,
                (fileData as UploadedFileWithMetadata).file_name,
            );
        }

        formData.append("id", id ?? "");
        const accessToken = await tokens.getAccessToken();
        axios
            .post(URLS.serverUrl + API.sendAnnouncementWithFiles, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setToastText("Announcement sent!");
                    navigate("/announcements");
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setOpen(true);
                }, 100);
            });
    };

    const [ticketWithInsight, setTicketWithInsight] =
        useState<Ticket>(defaultTicket);

    const [ticketWithInsightLoaded, setTicketWithInsightLoaded] =
        useState<number>(loadingTypes.loading);

    const [announcementType, setAnnouncementType] = useState<string>(
        announcement.announcement_type ?? "Broadcast",
    );

    useEffect(() => {
        if (
            announcement.insight_id !== "" ||
            announcement.insight_id !== undefined
        ) {
            api.get(
                `${URLS.serverUrl}${API.getInsightInfo}/${announcement.insight_id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        const result: InsightInfoResponse = res.data.data;
                        const copyTicket = ticketWithInsight;
                        copyTicket.insights = [result.insight];
                        copyTicket.issueCounts = result.issue_counts;
                        copyTicket.trend = result.trend;
                        setTicketWithInsight(copyTicket);
                        setTicketWithInsightLoaded(loadingTypes.loaded);
                    } else {
                        return null;
                    }
                })
                .catch((res) => { });
        }
    }, [announcement.insight_id, api]);

    useEffect(() => {
        if (announcementType === AnnouncementsType.replyInThread) {
            setCustomerGroupsExist(false); // customer groups can't exist for customer group case
        }
    }, [announcementType]);

    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to={`/${from}`} />;
    }

    console.log("announcement files are ", announcement.files);

    return (
        <div className="overflow-hidden min-h-screen">
            <div className="h-screen overflow-hidden">
                <TooltipProvider delayDuration={0}>
                    <ResizablePanelGroup
                        direction="horizontal"
                        onLayout={(sizes: number[]) => {
                            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                                sizes,
                            )}`;
                        }}
                        className="h-fit items-stretch"
                    >
                        <ResizablePanel
                            defaultSize={window.innerWidth * 0.6}
                            key="ticketInfo"
                            className="mt-3"
                        >
                            <div className="flex flex-row items-center pl-4">
                                <Breadcrumb>
                                    <BreadcrumbList>
                                        <BreadcrumbItem>
                                            <BreadcrumbLink
                                                href={"/announcements"}
                                            >
                                                <div className="flex items-center pl-2">
                                                    <ChevronLeftIcon className="mx-2" />
                                                    <p>Announcements</p>
                                                </div>
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                    </BreadcrumbList>
                                </Breadcrumb>
                            </div>
                            <Separator size="4" className="mt-3 mb-8" />
                            <Flex
                                direction="column"
                                justify={"start"}
                                className="pr-8 pl-8"
                            >
                                <Heading size="5" align="left">
                                    Send Announcements
                                </Heading>
                                <Text mb="20px" size="2">
                                    Create a broadcast and send it across any
                                    resource.
                                </Text>

                                <RoomProvider
                                    id={`${orgId}:announcements:${id}`}
                                    initialPresence={{ cursor: null }}
                                >
                                    <ClientSideSuspense fallback={<Loading />}>
                                        <CollaborativeEditor
                                            getAIResponse={getAIResponse}
                                            aiResponse={aiResponse}
                                            aiResponseCompleted={
                                                aiResponseCompleted
                                            }
                                            aiResponseButton={
                                                announcement.announcement_type ===
                                                AnnouncementsType.broadcast
                                            }
                                            uploadedFiles={uploadedFiles}
                                            setUploadedFiles={setUploadedFiles}
                                            setIsSendDisabled={setIsSendDisabled}
                                        />
                                    </ClientSideSuspense>
                                </RoomProvider>
                            </Flex>
                        </ResizablePanel>
                        <ResizableHandle className="bg-sidebarBorder w-px" />
                        <ResizablePanel
                            defaultSize={window.innerWidth * 0.4}
                            key="ticketMetadata"
                            className="mt-3"
                        >
                            <div className="flex flex-row items-center justify-between">
                                <div className="justify-self-start ml-4 text-sm font-medium">
                                    Send
                                </div>
                                <RoomProvider
                                    id={`${orgId}:announcements:${id}`}
                                    initialPresence={{
                                        cursor: null,
                                    }}
                                >
                                    <ClientSideSuspense fallback={<div />}>
                                        <Avatars />
                                    </ClientSideSuspense>
                                </RoomProvider>
                            </div>
                            <Separator size="4" className="mt-2 mb-8" />
                            <div className="ml-4 flex flex-1 flex-col pb-5 gap-3 rounded-md pr-6 ">
                                <Form {...form}>
                                    <form
                                        onSubmit={form.handleSubmit((values) =>
                                            onSubmit(values),
                                        )}
                                        className="flex flex-col space-y-4"
                                        id="announcements-form"
                                    >
                                        <FormItem className="flex flex-row items-end justify-between">
                                            <FormLabel className="text-xs">
                                                Announcement Type
                                            </FormLabel>
                                            {announcement.insight_id === "" ||
                                                announcement.insight_id ===
                                                undefined ? (
                                                <FormControl>
                                                    <Badge>
                                                        {AnnouncementsTypeMap[
                                                            announcementType
                                                        ] ?? "Broadcast"}
                                                    </Badge>
                                                </FormControl>
                                            ) : (
                                                <FormControl>
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger
                                                            asChild
                                                            className="hover:bg-muted"
                                                        >
                                                            <Button
                                                                variant="ghost"
                                                                className="flex p-1.5 py-0.5"
                                                            >
                                                                <Badge
                                                                    color={
                                                                        "gray"
                                                                    }
                                                                    size="2"
                                                                    radius="full"
                                                                    variant="outline"
                                                                    className="py-1 hover:opacity-70  hover:text-opacity-70 transition-opacity duration-300 text-gray11"
                                                                >
                                                                    <div className="flex flex-row items-center gap-2">
                                                                        {announcementType ===
                                                                            AnnouncementsType.broadcast ? (
                                                                            <SpeakerLoudIcon />
                                                                        ) : (
                                                                            <ResetIcon />
                                                                        )}
                                                                        <>
                                                                            {AnnouncementsTypeMap[
                                                                                announcementType
                                                                            ] ??
                                                                                "Broadcast"}
                                                                            <TriangleDownIcon className="h-3 w-3" />
                                                                        </>
                                                                    </div>
                                                                </Badge>
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent
                                                            side="bottom"
                                                            align="end"
                                                            className="w-[160px] px-4 py-2 text-xs"
                                                        >
                                                            <DropdownMenuItem
                                                                onSelect={() => {
                                                                    setAnnouncementType(
                                                                        AnnouncementsType.replyInThread,
                                                                    );
                                                                }}
                                                                className="py-1 hover:bg-muted hover:outline-none cursor-pointer"
                                                            >
                                                                {
                                                                    AnnouncementsTypeMap[
                                                                    AnnouncementsType
                                                                        .replyInThread
                                                                    ]
                                                                }
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem
                                                                onSelect={() => {
                                                                    setAnnouncementType(
                                                                        AnnouncementsType.broadcast,
                                                                    );
                                                                }}
                                                                className="py-1 hover:bg-muted hover:outline-none cursor-pointer"
                                                            >
                                                                {
                                                                    AnnouncementsTypeMap[
                                                                    AnnouncementsType
                                                                        .broadcast
                                                                    ]
                                                                }
                                                            </DropdownMenuItem>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </FormControl>
                                            )}
                                        </FormItem>

                                        <FormField
                                            control={form.control}
                                            name="broadcastName"
                                            render={({ field }) => (
                                                <FormItem className="flex flex-col ">
                                                    <FormLabel
                                                        htmlFor="broadcastName"
                                                        className="text-xs"
                                                    >
                                                        Announcement Name
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            id="broadcastName"
                                                            className="text-xs focus:outline-none focus:ring-0 focus:outline-offset-2 focus:outline-1"
                                                            style={{
                                                                outlineColor:
                                                                    "#CBCDFF",
                                                            }}
                                                            {...field}
                                                            placeholder="Product Update"
                                                        />
                                                    </FormControl>
                                                    <FormMessage className="text-xs" />
                                                </FormItem>
                                            )}
                                        />
                                        {announcement.insight_id !== "" &&
                                            announcement.insight_id !==
                                            undefined &&
                                            ticketWithInsightLoaded ===
                                            loadingTypes.loaded && (
                                                <SavedInsightDialogPopup
                                                    ticketWithInsight={
                                                        ticketWithInsight
                                                    }
                                                />
                                            )}
                                        {announcementType !==
                                            AnnouncementsType.replyInThread && (
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger
                                                        asChild
                                                        className="hover:bg-muted"
                                                    >
                                                        <Button
                                                            className="self-end outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-iris5 shadow-md"
                                                            size="sm"
                                                            variant="outline"
                                                            type="button"
                                                        >
                                                            <PlusIcon />
                                                            Send to
                                                        </Button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent
                                                        align="end"
                                                        className="max-h-60 overflow-y-auto p-2"
                                                    >
                                                        {allCustomerGroupsState.length !==
                                                            0 &&
                                                            announcementType ===
                                                            AnnouncementsType.broadcast && (
                                                                <DropdownMenuItem
                                                                    onClick={() =>
                                                                        setCustomerGroupsExist(
                                                                            true,
                                                                        )
                                                                    }
                                                                    className="py-1 hover:bg-muted outline-none cursor-pointer px-3 text-xs"
                                                                >
                                                                    Customer Group
                                                                </DropdownMenuItem>
                                                            )}
                                                        <DropdownMenuItem
                                                            onClick={() =>
                                                                addNewSource()
                                                            }
                                                            className="py-1 hover:bg-muted outline-none cursor-pointer px-3 text-xs"
                                                        >
                                                            Account
                                                        </DropdownMenuItem>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            )}

                                        {announcementType ===
                                            AnnouncementsType.broadcast ? (
                                            <AnnouncementsBroadcastSources
                                                fields={fields}
                                                fieldsState={fieldsState}
                                                form={form}
                                                handleBroadcastChange={
                                                    handleBroadcastChange
                                                }
                                                filteredBroadcasts={
                                                    filteredBroadcasts
                                                }
                                                removeSource={removeSource}
                                                setFieldsState={setFieldsState}
                                            />
                                        ) : (
                                            <AnnouncementsBulkReplies
                                                fields={fields}
                                                fieldsState={fieldsState}
                                                form={form}
                                                handleBroadcastChange={
                                                    handleBroadcastChange
                                                }
                                                filteredBroadcasts={
                                                    filteredBroadcasts
                                                }
                                                removeSource={removeSource}
                                                setFieldsState={setFieldsState}
                                            />
                                        )}

                                        {customerGroupsExist && (
                                            <div>
                                                <div className="flex flex-row items-start justify-between">
                                                    <div>
                                                        <FormLabel className="pb-0 mb-0 text-xs">
                                                            Customer Groups
                                                        </FormLabel>
                                                        <FormField
                                                            key={
                                                                "customerGroupMetadata"
                                                            }
                                                            control={
                                                                form.control
                                                            }
                                                            name={
                                                                "customerGroupMetadata"
                                                            }
                                                            render={({
                                                                field,
                                                            }) => (
                                                                <>
                                                                    <FormItem>
                                                                        <div className="flex flex-col gap-y-5">
                                                                            <FormControl>
                                                                                <div className="flex flex-col items-start gap-3">
                                                                                    <FancyMultiSelect
                                                                                        setSelectedChannels={
                                                                                            setCustomerGroupsState
                                                                                        }
                                                                                        selectedChannels={
                                                                                            customerGroupsState
                                                                                        }
                                                                                        options={
                                                                                            allCustomerGroupsState
                                                                                        }
                                                                                        placeholder="Select Customer Groups..."
                                                                                    />
                                                                                </div>
                                                                            </FormControl>
                                                                            <FormMessage className="text-xs" />
                                                                        </div>
                                                                    </FormItem>
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <Button
                                                        type="button"
                                                        variant="outline"
                                                        size="sm"
                                                        className="mt-0 pt-0"
                                                        onClick={() => {
                                                            setCustomerGroupsExist(
                                                                false,
                                                            );
                                                            setCustomerGroupsState(
                                                                [],
                                                            );
                                                        }}
                                                    >
                                                        <TrashIcon />
                                                    </Button>
                                                </div>
                                                <Separator
                                                    size="4"
                                                    className="mt-4 mb-4"
                                                />
                                            </div>
                                        )}

                                        <div className="flex flex-row gap-5 self-end ">
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <Button
                                                            className="mr-3 outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-md"
                                                            size="sm"
                                                            variant="outline"
                                                            type="button"
                                                            value="draft"
                                                            onClick={saveDraft}
                                                        >
                                                            Save Draft
                                                        </Button>
                                                    </TooltipTrigger>
                                                    <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col">
                                                        <div className="text-sm font-semibold">
                                                            Files will not be
                                                            saved.
                                                        </div>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>

                                            <Dialog
                                                open={isDialogOpen}
                                                onOpenChange={setIsDialogOpen}
                                            >
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <div>
                                                                <Button
                                                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-md"
                                                                    size="sm"
                                                                    variant="outline"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        getFinalizedTextContent();
                                                                        const finalFields =
                                                                            new Map<
                                                                                string,
                                                                                ScopeResponse[]
                                                                            >([]);

                                                                        for (const field of fieldsState) {
                                                                            if (
                                                                                field.selectedBroadcast !==
                                                                                "Source..."
                                                                            ) {
                                                                                finalFields.set(
                                                                                    field.selectedBroadcast,
                                                                                    field.selectedChannels,
                                                                                );
                                                                            }
                                                                        }

                                                                        if (
                                                                            form.getValues()
                                                                                .broadcastName ===
                                                                            ""
                                                                        ) {
                                                                            setErrorMsg(
                                                                                "Announcement Name should not be empty.",
                                                                            );
                                                                            setValidationErr(
                                                                                true,
                                                                            );
                                                                            setIsDialogOpen(
                                                                                false,
                                                                            );
                                                                        } else if (
                                                                            finalFields.size ===
                                                                            0 &&
                                                                            customerGroupsState.length ===
                                                                            0
                                                                        ) {
                                                                            setErrorMsg(
                                                                                "Please add a destination before sending.",
                                                                            );
                                                                            setValidationErr(
                                                                                true,
                                                                            );
                                                                            setIsDialogOpen(
                                                                                false,
                                                                            );
                                                                        } else {
                                                                            setIsDialogOpen(
                                                                                true,
                                                                            );
                                                                            setErrorMsg("");
                                                                            setValidationErr(
                                                                                false,
                                                                            );
                                                                        }
                                                                    }}
                                                                    disabled={!isSendDisabled}
                                                                >
                                                                    Send
                                                                    <PaperPlaneIcon />
                                                                </Button>
                                                            </div>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col">
                                                            {isSendDisabled ?
                                                                "Send message!" : "Add text and check that all variables have been populated."}
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                                <DialogContent className="max-w-[1200px] w-full">
                                                    <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                                                        <DialogHeader className="self-start">
                                                            <DialogTitle className="text-md pb-3 text-left">
                                                                Preview Message
                                                            </DialogTitle>
                                                        </DialogHeader>

                                                        {/* todo - add previewable content here + which channels its being sent to */}
                                                        <Card className="p-4 mb-4">
                                                            {loading ===
                                                                loadingTypes.loading ? (
                                                                <Loading />
                                                            ) : loading ===
                                                                loadingTypes.loaded ? (
                                                                <>
                                                                    <Markdown>
                                                                        {
                                                                            textContent
                                                                        }
                                                                    </Markdown>
                                                                    <div className="flex items-center flex-wrap gap-1">
                                                                        {uploadedFiles.map(
                                                                            (
                                                                                file,
                                                                            ) =>
                                                                                file.type ===
                                                                                "image" && (
                                                                                    <div
                                                                                        key={
                                                                                            (
                                                                                                file as UploadedFile
                                                                                            )
                                                                                                .alt
                                                                                        }
                                                                                        className="relative"
                                                                                    >
                                                                                        <div className="w-[100px] h-[100px] overflow-hidden relative m-1">
                                                                                            <img
                                                                                                src={
                                                                                                    (
                                                                                                        file as UploadedFile
                                                                                                    )
                                                                                                        .src
                                                                                                }
                                                                                                alt={
                                                                                                    (
                                                                                                        file as UploadedFile
                                                                                                    )
                                                                                                        .alt
                                                                                                }
                                                                                                style={{
                                                                                                    maxWidth:
                                                                                                        "auto",
                                                                                                    height: "100%",
                                                                                                    objectFit:
                                                                                                        "cover",
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                ),
                                                                        )}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <Callout.Root
                                                                    size="1"
                                                                    variant="outline"
                                                                    color="red"
                                                                    style={{
                                                                        padding:
                                                                            "20px",
                                                                    }}
                                                                >
                                                                    <Callout.Text>
                                                                        Sorry,
                                                                        something's
                                                                        wrong!
                                                                        Please
                                                                        contact
                                                                        support@askassembly.app
                                                                        for
                                                                        help.
                                                                    </Callout.Text>
                                                                </Callout.Root>
                                                            )}
                                                        </Card>
                                                        <div className="flex justify-end space-x-2">
                                                            <DialogClose
                                                                asChild
                                                            >
                                                                <Button
                                                                    className="self-end outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-md"
                                                                    size="sm"
                                                                    variant="outline"
                                                                    onClick={() => { }}
                                                                    type="button"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </DialogClose>
                                                            <DialogClose>
                                                                <Button
                                                                    className="self-end outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-md"
                                                                    size="sm"
                                                                    variant="outline"
                                                                    type="submit"
                                                                    value="submit"
                                                                    form="announcements-form"
                                                                    onClick={
                                                                        handleFormSubmit
                                                                    }
                                                                >
                                                                    Send
                                                                    <PaperPlaneIcon />
                                                                </Button>
                                                            </DialogClose>
                                                        </div>
                                                    </div>
                                                </DialogContent>
                                            </Dialog>
                                        </div>

                                        {validationErr && (
                                            <Alert
                                                variant="destructive"
                                                className="mt-4"
                                            >
                                                <div className="flex flex-row gap-3 items-center">
                                                    <ExclamationTriangleIcon className="h-4 w-4" />
                                                    <div>
                                                        <AlertTitle>
                                                            Error
                                                        </AlertTitle>
                                                        <AlertDescription>
                                                            {errorMsg}
                                                        </AlertDescription>
                                                    </div>
                                                </div>
                                            </Alert>
                                        )}
                                    </form>
                                </Form>
                            </div>
                        </ResizablePanel>
                    </ResizablePanelGroup>
                </TooltipProvider>
            </div>
            <Toast.Provider swipeDirection="right">
                <Toast.Root
                    className="ToastRoot"
                    open={open}
                    onOpenChange={setOpen}
                >
                    <Toast.Title className="ToastTitle">
                        <Flex direction={"row"} align={"center"} gap="2">
                            <ToastSymbol color="green" />
                            {toastText}
                        </Flex>
                    </Toast.Title>
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
        </div>
    );
};
