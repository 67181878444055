import { Avatar } from "@/Ticket/Avatar";
import { MyUser } from "@/Ticket/User";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import type { Category, Insight, Query, RelatedIssue } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    cleanText,
    getHtmlStringFromReactContent,
    getStatusIcon,
} from "@/utilities/methods";
import {
    CaretDownIcon,
    CaretUpIcon,
    ComponentBooleanIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import parse from "html-react-parser";
import { Suspense, memo, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";
import { RelatedIssuesDropdown } from "./RelatedIssuesDropdown";
import { useApi } from "@/interfaces/api";
import { API, URLS } from "@/constant";
import { useQuery } from "@tanstack/react-query";

const RICard = (
    relatedIssue: RelatedIssue,
    insightSelectedIsSaved: boolean,
    issueSelected: string | undefined,
    setIssueSelected?: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
    const isSelected = issueSelected === relatedIssue.org_specific_id;
    const api = useApi();

    const categoriesDataQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    const Icon = getStatusIcon(relatedIssue.status);
    const timestampCutoff = "2024-09-30T23:50:00.000000Z";
    const parsedTimestampCutoff = new Date(timestampCutoff);
    const commentParsedTimestamp = new Date(relatedIssue.created_at);
    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () =>
            integrationBackEndDataMappingToSvg.get(
                relatedIssue.source ?? "Unknown",
            ),
        [relatedIssue.source],
    );
    const date: string = useMemo(() => {
        let updatedDate = new Date(relatedIssue.ticket_updated_at);
        if (
            Number.isNaN(updatedDate.getTime()) ||
            !relatedIssue.ticket_updated_at
        ) {
            updatedDate = new Date();
        }
        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [relatedIssue.ticket_updated_at]);

    const handleClick = (id: string) => {
        return () => {
            if (insightSelectedIsSaved && setIssueSelected) {
                setIssueSelected(id);
            } else {
                const url = `/issue/${id}`;
                window.open(url, "_blank");
            }
        };
    };

    return (
        <Card
            className={`p-2 py-0 m-1 rounded ${isSelected ? "bg-sidebarBorder" : "hover:bg-muted"}`}
        >
            <CardContent className="py-1 px-2">
                <button
                    className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                    onClick={handleClick(relatedIssue.org_specific_id)}
                    type="button"
                >
                    <div className="flex items-center justify-between w-full gap-1.5">
                        <div
                            className="flex items-center gap-3"
                            style={{ maxWidth: "50%" }}
                        >
                            {relatedIssue.user_info &&
                                relatedIssue.user_info?.id !== "" && (
                                    <div className="lb-root">
                                        <div className="lb-comment-details">
                                            <Suspense
                                                fallback={
                                                    <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                                                }
                                            >
                                                <Avatar
                                                    user={
                                                        relatedIssue.user_info
                                                    }
                                                />
                                            </Suspense>
                                        </div>
                                    </div>
                                )}
                            <div
                                className="flex flex-col gap-0.5"
                                style={{ maxWidth: "100%" }}
                            >
                                <div className="flex items-center gap-2">
                                    {relatedIssue.user_info &&
                                        relatedIssue.user_info?.id !== "" ? (
                                        <div className="lb-comment-details-labels">
                                            <MyUser
                                                user={relatedIssue.user_info}
                                                className="lb-comment-author text-xs font-semibold"
                                            />
                                        </div>
                                    ) : (
                                        <div className="text-xs font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                            {relatedIssue.title?.trim() ||
                                                relatedIssue.query}
                                        </div>
                                    )}
                                    <div className="flex-shrink-0 text-xs text-muted-foreground">
                                        {relatedIssue.org_specific_id}
                                    </div>
                                </div>
                                {relatedIssue.user_info?.id !== "" && (
                                    <div className="text-xs overflow-hidden whitespace-nowrap text-ellipsis flex items-center gap-1.5">
                                        {relatedIssue.title?.trim() ||
                                            relatedIssue.query}
                                        {(relatedIssue.source === "Slack" ||
                                            relatedIssue.source ===
                                            "CommunitySlack") &&
                                            commentParsedTimestamp <
                                            parsedTimestampCutoff ? (
                                            <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                >
                                                    {getHtmlStringFromReactContent(
                                                        parse(
                                                            toHTML(
                                                                cleanText(
                                                                    relatedIssue.query ??
                                                                    "",
                                                                ),
                                                            ),
                                                        ),
                                                    )}
                                                </ReactMarkdown>
                                            </div>
                                        ) : (
                                            <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                >
                                                    {cleanText(
                                                        relatedIssue.query ??
                                                        "",
                                                    )}
                                                </ReactMarkdown>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center justify-end">
                            <Badge
                                color={"gray"}
                                size="2"
                                radius="medium"
                                variant="outline"
                                className="my-0 mx-1.5 ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                            >
                                <Icon
                                    style={{
                                        height: "15px",
                                    }}
                                />
                                <span className="text-xs font-normal ">
                                    {relatedIssue.status}
                                </span>
                            </Badge>
                            <Badge
                                color="gray"
                                size="2"
                                radius="full"
                                variant="outline"
                                className="m-0.5"
                            >
                                <div className="flex flex-row items-center">
                                    <ComponentBooleanIcon
                                        color={
                                            categoriesDataQuery.data?.find(
                                                (category) =>
                                                    category.name ===
                                                    relatedIssue.tag,
                                            )?.color ?? "gray"
                                        }
                                    />

                                    <p className="pl-0.5">{relatedIssue.tag}</p>
                                </div>
                            </Badge>
                            <div className="mx-1.5 text-xs w-15">{date}</div>
                            {SourceSvgImage && (
                                <SourceSvgImage className="w-5 h-5 mr-1" />
                            )}
                        </div>
                    </div>
                </button>
            </CardContent>
        </Card>
    );
};

interface RelatedIssuesCardProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarLabels: string;
    insight: Insight;
    updateInsightState: ((newState: Partial<Insight>) => void) | undefined;
    insightSelectedIsSaved: boolean;
    userID: string;
    issues?: Query[];
    issueSelected?: string | undefined;
    setIssueSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function RelatedIssuesCard({
    isOpen,
    setIsOpen,
    sidebarLabels,
    insight,
    updateInsightState,
    insightSelectedIsSaved,
    userID,
    issues,
    issueSelected,
    setIssueSelected,
}: RelatedIssuesCardProps) {
    return (
        <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-3 py-2 border relative bg-white">
            <div className="flex items-center justify-between">
                <div className={`flex items-center ${sidebarLabels}`}>
                    {insightSelectedIsSaved && updateInsightState ? (
                        <RelatedIssuesDropdown
                            insight={insight}
                            updateInsightState={updateInsightState}
                            issues={issues ?? []}
                            userID={userID}
                        />
                    ) : (
                        <div className="flex flex-row items-center">
                            <div className="font-semibold text-md">
                                Related Interactions
                            </div>
                            <Badge
                                color="gray"
                                size="2"
                                radius="full"
                                variant="soft"
                                className="m-0.5"
                            >
                                <div className="flex flex-row items-center">
                                    <p className="pl-0.5">{insight.count}</p>
                                </div>
                            </Badge>
                        </div>
                    )}
                </div>
                {isOpen ? (
                    <Button
                        type="button"
                        variant="ghost"
                        className="text-xs p-0.5"
                        onClick={() => setIsOpen(false)}
                    >
                        <CaretUpIcon className="w-4 h-4" />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        variant="ghost"
                        className="text-xs p-0.5"
                        onClick={() => setIsOpen(true)}
                    >
                        <CaretDownIcon className="w-4 h-4" />
                    </Button>
                )}
            </div>
            <div
                className={`max-h-[240px] overflow-y-auto ${isOpen && insight.related_issues.length !== 0 ? "mt-2" : ""}`}
            >
                {isOpen &&
                    insight.related_issues?.map((relatedIssue) =>
                        RICard(
                            relatedIssue,
                            insightSelectedIsSaved,
                            issueSelected,
                            setIssueSelected,
                        ),
                    )}
            </div>
        </Card>
    );
}

export default memo(RelatedIssuesCard);
