import { Button } from "@/component/shadcn/ui/button";
import { TeamsAPI, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTeamResponse,
    GetUserResponse,
    TeamSources,
} from "@/interfaces/serverData";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Box, Skeleton, Text } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AutocloseCard } from "./Autoclose";
import type { MembersRowState } from "./DataTable/constants";
import NewTeamCard from "./NewTeamCard";
import TeamCard from "./TeamCard";

export type TeamCardUI = {
    id: string;
    card: TeamSources;
    isEditing: boolean;
};

const TeamSettings = () => {
    const { id } = useParams<{ id: string }>(); // team id

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [rowState, setRowState] = useState<Map<string, MembersRowState>>(
        new Map(),
    );

    const [teamData, setTeamData] = useState<GetTeamResponse | null>(null);

    const [teamSources, setTeamSources] = useState<TeamSources[]>([]);

    const [teamCards, setTeamCards] = useState<TeamCardUI[]>([]);

    const [loadingTeamSources, setLoadingTeamSources] = useState<number>(
        loadingTypes.loading,
    );

    useEffect(() => {
        const [url, method] = TeamsAPI.getTeamSources;
        api.get(`${URLS.serverUrl}${url}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    setTeamSources(res.data.data ?? []);
                    setLoadingTeamSources(loadingTypes.loaded);
                } else {
                    setLoadingTeamSources(loadingTypes.error);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingTeamSources(loadingTypes.error);
            });
    }, [id]);

    useEffect(() => {
        setTeamCards(
            teamSources.map((teamSource) => ({
                id: teamSource.id,
                card: teamSource,
                isEditing: false,
            })),
        );
    }, [teamSources]);

    useEffect(() => {
        const [url, method] = TeamsAPI.getTeam;
        api.get(`${URLS.serverUrl}${url}/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    const teamInfo: GetTeamResponse = res.data.data;
                    setTeamData(teamInfo);
                    setSelectedMembersData(teamInfo.members);

                    const newRowState = new Map<string, MembersRowState>();
                    for (let i = 0; i < teamInfo.members.length; i++) {
                        const user: GetUserResponse = teamInfo.members[i];
                        newRowState.set(String(i), {
                            id: user.id,
                            name: `${user.first_name} ${user.last_name}`,
                            email: user.email,
                            username: user.username,
                            picture_url: user.picture_url,
                            role: user.user_role,
                        });
                    }
                    setRowState(newRowState);
                    setLoadingState(loadingTypes.loaded);
                } else {
                    setLoadingState(loadingTypes.error);
                }
            })
            .catch((err) => {
                console.log(err);
                setLoadingState(loadingTypes.error);
            });
    }, [id]);

    const [selectedMembersData, setSelectedMembersData] = useState<
        GetUserResponse[]
    >([]);

    useEffect(() => {
        console.log("selectedMembersData", selectedMembersData);
    }, [selectedMembersData]);

    useEffect(() => {
        console.log("rowState", rowState);
    }, [rowState]);

    const [toastText, setToastText] = useState<string>(
        "Team created successfully!",
    );

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const removeTeamCardNew = (teamCardId: string) => {
        console.log(teamCardId);
        setTeamCards((prevCards) =>
            prevCards.filter((card) => card.id !== teamCardId),
        );
    };

    const timerRef = useRef<number>(0);

    const deleteTeamCard = (teamCardId: string, source: string) => {
        const [url, method] = TeamsAPI.deleteTeamSource;
        const requestData = {
            source: source,
        };
        api.post(`${URLS.serverUrl}${url}/${id}`, requestData) // delete by source and TEAM id
            .then((res) => {
                if (res.status === 200) {
                    console.log(teamCards);
                    console.log(teamCardId);
                    setTeamCards(
                        (prevCards) =>
                            prevCards.filter((card) => card.id !== teamCardId), // delete team card from UI
                    );
                }
                if (res.status !== 200) {
                    setToastText("Error deleting source");
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Source deleted successfully");
                    setToastSymbol(CheckCircledIcon);
                }
            })
            .catch((err) => {
                console.error("Error fetching scope data:", err);
                setToastText("Error deleting source");
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    };

    return (
        <div>
            {loadingState === loadingTypes.loading ? (
                <Skeleton>
                    <Text>
                        {[...Array(6)].map((_, index) => (
                            <p
                                key={
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    index
                                }
                            >
                                {loremIpsum}
                            </p>
                        ))}
                    </Text>
                </Skeleton>
            ) : loadingState === loadingTypes.loaded ? (
                <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                    <div className="flex flex-row justify-between">
                        <h2 className="text-2xl font-semibold">Settings</h2>
                    </div>
                    <p className="text-sm text-gray11 pb-3">
                        Manage tickets that this team will respond to
                    </p>
                    <div className="flex flex-col items-end gap-2">
                        <Button
                            className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-4"
                            size="sm"
                            variant="outline"
                            onClick={() => {
                                // Add a new TeamCard in editing state
                                // You might need to manage this in state, e.g.:
                                setTeamCards((prevCards) => [
                                    {
                                        id: String(prevCards.length + 1),
                                        isEditing: true,
                                        card: {
                                            id: "",
                                            source: "",
                                            scopes: [],
                                        },
                                    },
                                    ...prevCards,
                                ]);
                            }}
                        >
                            Add Accounts
                            <PlusIcon />
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2">
                        {teamCards.map((card) =>
                            card.isEditing ? (
                                <NewTeamCard
                                    key={card.id}
                                    isEditing={card.isEditing}
                                    teamId={id ?? ""}
                                    teamCardId={card.id}
                                    removeTeamCard={removeTeamCardNew}
                                    setToastText={setToastText}
                                    setToastSymbol={setToastSymbol}
                                    setToastOpen={setToastOpen}
                                    setTeamCards={setTeamCards}
                                />
                            ) : (
                                <TeamCard
                                    key={card.id}
                                    teamId={id ?? ""}
                                    teamSource={card.card}
                                    removeTeamCard={deleteTeamCard}
                                    removeTeamCardFromUI={removeTeamCardNew}
                                    setToastText={setToastText}
                                    setToastSymbol={setToastSymbol}
                                    setToastOpen={setToastOpen}
                                    setTeamCards={setTeamCards}
                                />
                            ),
                        )}
                    </div>
                    <div className="my-4">
                        {id && <AutocloseCard team_id={id} />}
                    </div>
                </Box>
            ) : (
                <AssemblyErrorMessage className="mt-5 mx-20" />
            )}
            <AssemblyToastProvider
                open={toastOpen}
                setOpen={setToastOpen}
                toastText={toastText}
                toastSymbol={ToastSymbol}
            />
        </div>
    );
};

export default TeamSettings;
