import { InsightListType } from "@/Insights/useInsightsData";
import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import { type FilterOption, FilterType } from "@/IssuesTable/constants";
import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import type {
    ResponseTime,
    ResponseTimeResponse,
    Teams,
} from "@/interfaces/serverData";
import TimezoneDropdown from "@/reusable_components/timestampDropdown";
import { getFilterOption } from "@/utilities/methods";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Card, Flex, Skeleton, Text } from "@radix-ui/themes";
import { LineChart as TremorChart } from "@tremor/react";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import type React from "react";
import { useCallback, useState } from "react";
import type { DateRange } from "react-day-picker";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { ChartData } from "../AdminAnalyticsPage";

export interface FirstResponseAnalyticsProps {
    teamID: string;
    setTeamID: React.Dispatch<React.SetStateAction<string>>;
    teamsQueryStatus: string;
    teamsQueryIsError: boolean;
    teamsQueryData: Teams[] | undefined;
    rawData: ResponseTime[];
    firstResponseTimePerDay: ChartData[];
    firstResponseChartData: ChartData[];
    firstResponseTimeQueryStatus: string;
    firstResponseTimeQueryIsError: boolean;
    firstResponseTimeQueryData: ResponseTimeResponse | undefined;
    listType: InsightListType;
    range: DateRange | undefined;
    setRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    playgroundFilters: Map<string, Set<string>>;
    setPlaygroundFilters: React.Dispatch<
        React.SetStateAction<Map<string, Set<string>>>
    >;
    handleFilterSelect: (type: string, option: { label: string; value: string; key: string; color: string }) => () => void;
}

const FirstResponseAnalyticsComponent = ({
    teamID,
    setTeamID,
    teamsQueryStatus,
    teamsQueryIsError,
    teamsQueryData,
    rawData,
    firstResponseTimePerDay,
    firstResponseChartData,
    firstResponseTimeQueryStatus,
    firstResponseTimeQueryIsError,
    firstResponseTimeQueryData,
    listType,
    range,
    setRange,
    playgroundFilters,
    setPlaygroundFilters,
    handleFilterSelect,
}: FirstResponseAnalyticsProps) => {
    // Function to convert FirstResponse data to CSV format
    const convertToCSV = (data: ChartData[], timeZone: string) => {
        const headers = ["Date", "Duration (minutes)"];
        const rows = data?.map((item) => [
            new Date(item.date).toLocaleDateString("en-US", {
                timeZone,
            }),
            (item.duration / 60).toFixed(2), // Convert duration from seconds to minutes
        ]);
        return [headers, ...rows];
    };

    const convertRawToCSV = (timeZone: string) => {
        const headers = [
            "Ticket Number",
            "URL",
            "Assignee",
            "Start",
            "End",
            "Duration (in minutes)",
            "Source",
            "Interaction Type",
            "Topics",
        ];
        const rows = rawData?.map((item) => {
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
            return [
                item.ticket_number,
                item.url,
                item.assignee,
                `${startDate.toLocaleDateString("en-US", { timeZone })} ${startDate.toLocaleTimeString("en-US", { timeZone })}`,
                `${endDate.toLocaleDateString("en-US", { timeZone })} ${endDate.toLocaleTimeString("en-US", { timeZone })}`,
                item.duration,
                item.source,
                item.bot_category,
                `"${item.topics}"`,
            ];
        });
        return [headers, ...rows];
    };

    // Function to handle CSV download
    const downloadCSV = (timezone: string) => {
        const csvData = convertToCSV(firstResponseTimePerDay, timezone);
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "aggregate_first_response_time.csv");
    };

    const downloadRawCSV = (timezone: string) => {
        const csvData = convertRawToCSV(timezone);
        const csvContent = csvData?.map((e) => e.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        saveAs(blob, "raw_first_response_time.csv");
    };

    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const filterOptions: FilterOption[] = [
        getFilterOption("Source", FilterType.MenuThenSelect, true),
    ];
    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2 mt-2">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="flex items-center h-9 gap-2 text-muted-foreground text-xs"
                        >
                            <CalendarIcon className="h-3 w-3" />
                            {range?.from ? (
                                format(range.from, "PP")
                            ) : (
                                <span>Oldest</span>
                            )}
                            <div>to</div>
                            {range?.to ? (
                                format(range.to, "PP")
                            ) : (
                                <span>Latest</span>
                            )}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                        />
                    </PopoverContent>
                </Popover>

                {Array.from(playgroundFilters.entries())?.map(
                    ([type, values]) => (
                        <div key={type}>
                            <Filter
                                type={type}
                                values={values}
                                filters={playgroundFilters}
                                setFilters={setPlaygroundFilters}
                                topics={[]}
                                users={[]}
                                customerGroups={[]}
                                categories={[]}
                                isSavedViewFilter={false}
                                teams={[]}
                                channels={new Map()}
                                filterOptions={filterOptions}
                            />
                        </div>
                    ),
                )}
                <FilterDropdown
                    filters={playgroundFilters}
                    filterOptions={filterOptions}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    handleItemSelect={handleFilterSelect}
                    handleMenuClick={handleMenuClick}
                    topics={[]}
                    users={[]}
                    customerGroups={[]}
                    teams={[]}
                    categories={[]}
                    channels={new Map()}
                />

                {listType === InsightListType.General && (
                    <Select
                        value={teamID}
                        onValueChange={(value) => setTeamID(value)}
                    >
                        <SelectTrigger className="text-xs py-1 mx-5">
                            <SelectValue placeholder="Select Team" />
                        </SelectTrigger>
                        <SelectContent>
                            {teamsQueryData?.map((team) => (
                                <SelectItem key={team.id} value={team.id}>
                                    {team.team_name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                )}
                <TimezoneDropdown
                    onTimezoneSelect={(timezone) => {
                        downloadCSV(timezone);
                        downloadRawCSV(timezone);
                    }}
                    button={
                        <Button
                            className="bg-shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-1.5 justify-start data-[state=open]:bg-muted shadow-sm"
                            size="sm"
                            variant="outline"
                        >
                            Download CSV
                        </Button>
                    }
                />
            </div>

            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        First Response Duration Per Day Average
                    </Text>
                    {firstResponseTimeQueryStatus === "loading" && (
                        <SkeletonText />
                    )}
                    {firstResponseTimeQueryIsError && (
                        <Text>Error loading data</Text>
                    )}
                    {firstResponseTimeQueryData &&
                        firstResponseTimePerDay.length > 0 && (
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart
                                    data={firstResponseTimePerDay?.map(
                                        (item) => {
                                            return {
                                                ...item,
                                                duration: item.duration / 60, // Convert duration from seconds to minutes
                                            };
                                        },
                                    )}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        type="category"
                                        tickFormatter={(value) =>
                                            new Date(value).toLocaleDateString()
                                        }
                                    />
                                    <YAxis />
                                    <Tooltip
                                        labelFormatter={(value) =>
                                            new Date(value).toLocaleDateString()
                                        }
                                        formatter={(value: number) => [
                                            `${value.toFixed(2)} minutes`,
                                            "Average First Response Duration",
                                        ]}
                                    />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="duration"
                                        stroke="#8884d8"
                                        name="Average First Response Time"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                </Flex>
            </Card>

            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Rolling Average First Response Time
                    </Text>
                    {firstResponseTimeQueryStatus === "loading" && (
                        <SkeletonText />
                    )}
                    {firstResponseTimeQueryIsError && (
                        <Text>Error loading data</Text>
                    )}
                    {firstResponseTimeQueryData && (
                        <>
                            <Text size="2">
                                {`${(firstResponseTimeQueryData.average / 60).toFixed(2)} minutes`}
                            </Text>
                            <TremorChart
                                data={firstResponseChartData}
                                categories={["duration"]}
                                index="date"
                                colors={["blue"]}
                                yAxisWidth={48}
                            />
                        </>
                    )}
                </Flex>
            </Card>
        </div>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default FirstResponseAnalyticsComponent;
