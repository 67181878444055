import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/component/shadcn/ui/table";
import {
    type ColumnDef,
    type ColumnFiltersState,
    type Row,
    type SortingState,
    type VisibilityState,
    flexRender,
    getCoreRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { AccountsDataTablePagination } from "./accounts-data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";
import { Checkbox } from "@/component/shadcn/ui/checkbox";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuPortal, ContextMenuTrigger } from "@/component/shadcn/ui/context-menu";
import { CheckCircledIcon, Cross2Icon, CrossCircledIcon, OpenInNewWindowIcon, Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/component/shadcn/ui/alert-dialog";
import type { Account, GetUserResponse, Teams } from "@/interfaces/serverData";
import { convertAccountLabelsToMap, getNavLink } from "@/utilities/methods";
import AccountPopup from "@/pages/Admin/CRM/AccountPopup";
import { useApi } from "@/interfaces/api";
import { ContactsAPI } from "@/constant";
import { MergeIcon } from "lucide-react";
import type { UseQueryResult } from "@tanstack/react-query";
import type { AccountsListType } from "@/pages/Admin/CRM/Accounts";

interface AccountsDataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    handleRowClick: (row: Row<TData>) => void,
    pageIndex: number
    setPageIndex: React.Dispatch<React.SetStateAction<number>>
    pageSize: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    updateData: () => Promise<void>
    setToastText: React.Dispatch<React.SetStateAction<string>>
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType<any, keyof React.JSX.IntrinsicElements>>>
    teamsQuery: UseQueryResult<Teams[], Error>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    userID: string;
    orgID: string;
    listType: AccountsListType;
}

// TODO: need to convert other rows to using row id. This is currently only used by company table.
export function AccountsDataTable<TData extends { id: string }, TValue>({
    columns,
    data,
    handleRowClick,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    updateData,
    setToastText,
    setToastOpen,
    setToastSymbol,
    teamsQuery,
    usersQuery,
    userID,
    orgID,
    listType
}: AccountsDataTableProps<TData, TValue>) {
    const teamID = window.location.pathname.split("/")[2] || "";
    const api = useApi()
    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState<number | null>(null);
    const [selectedAccounts, setSelectedAccounts] = useState<Set<Account>>(new Set());
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
        {},
    );
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]);

    const [contextMenuRow, setContextMenuRow] = useState<Row<Account> | undefined>();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const handleRowRightClick = (row: Row<TData>) => {
        setContextMenuRow(row as unknown as Row<Account>);
    };

    // Memoize so that a new reference is only created when data changes
    const memoizedData = useMemo(() => data ?? [], [data]);
    const memoizedColumns = useMemo(() => columns ?? [], [columns]);

    const table = useReactTable({
        data: memoizedData,
        columns: memoizedColumns,
        state: {
            sorting,
            columnVisibility,
            rowSelection,
            columnFilters,
            pagination: {
                pageIndex,
                pageSize,
            },
        },
        enableColumnResizing: true,
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        pageCount: Math.ceil(memoizedData.length / pageSize),
        initialState: {
            pagination: {
                pageSize: pageSize,
                pageIndex: pageIndex
            },
        },
    });

    const handleCheckboxClickWithShift = (event: React.MouseEvent, rowIndex: number) => {
        const rows = table.getRowModel().rows;
        const row = rows[rowIndex];
        const checked = row?.getIsSelected()

        // Handle shift + click
        if (event.shiftKey && lastSelectedRowIndex != null && row?.id) {
            // Ensure the range is always in the correct order (start from the smaller index)
            const start = Math.min(lastSelectedRowIndex, rowIndex);
            const end = Math.max(lastSelectedRowIndex, rowIndex);

            const newSelection = { ...rowSelection };
            const newSelectionSet = new Set(selectedAccounts);
            for (let i = start; i <= end; i++) {
                const currRow = rows[i];
                if (currRow?.id) {
                    // Toggle all the rows based on whether the selected row is originally checked or not
                    newSelection[currRow?.id] = !checked;
                    if (checked) {
                        newSelectionSet.delete(currRow.original as unknown as Account); // Unselect if the row was checked
                    } else {
                        newSelectionSet.add(currRow.original as unknown as Account); // Add to selection if unchecked
                    }
                }
            }
            setRowSelection(newSelection);
        } else if (row?.id) {
            // If shift is not held, toggle the selection for just this row
            setRowSelection((prev) => ({
                ...prev,
                [row?.id]: !prev[row?.id],
            }));
            setLastSelectedRowIndex(null);
            const newSelectionSet = new Set(selectedAccounts);
            if (checked) {
                newSelectionSet.delete(row.original as unknown as Account); // Unselect the row
            } else {
                newSelectionSet.add(row.original as unknown as Account); // Select the row
            }
        }
        setLastSelectedRowIndex(rowIndex);
    };

    const handleOpenInNewTabRow = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        row: Row<Account> | undefined,
    ) => {
        if (row) {
            handleOpenInNewTab(event, row.original)
        }
    };

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        account: Account,
    ) => {
        event.stopPropagation();
        if (account) {
            window.open(getNavLink(account, listType, teamID), "_blank");
        }
    };

    const timerRef = useRef<number>(0);
    const handleDeleteRow = (row: Row<Account> | undefined,) => {
        if (!row?.original.id) {
            return;
        }
        handleDelete(row.original)
    };

    const handleDelete = (account: Account | undefined,) => {
        if (account?.account_type === "Customer") {
            api.delete(`${ContactsAPI.deleteCustomer.url}/${account?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        updateData();
                        table.toggleAllRowsSelected(false)
                        setToastOpen(true);
                        setToastSymbol(CheckCircledIcon);
                        setToastText("Deleted Customer!");
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setToastSymbol(CrossCircledIcon);
                    setToastText("Failed to delete customer");
                })
                .finally(() => {
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        } else {
            api.delete(`${ContactsAPI.deleteCompany.url}/${account?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        updateData();
                        table.toggleAllRowsSelected(false)
                        setToastOpen(true);
                        setToastSymbol(CheckCircledIcon);
                        setToastText("Deleted Company!");
                    } else {
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setToastSymbol(CrossCircledIcon);
                    setToastText("Failed to delete company");
                })
                .finally(() => {
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        }
    };

    // TODO: add a backend bulk endpoint for delete
    const handleBulkDelete = () => {
        for (const account of Array.from(selectedAccounts)) {
            if (account?.account_type === "Customer") {
                api.delete(`${ContactsAPI.deleteCustomer.url}/${account?.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            updateData();
                            table.toggleAllRowsSelected(false)
                        } else {
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setToastSymbol(CrossCircledIcon);
                        setToastText("Failed to delete account");
                    })
                    .finally(() => {
                        setToastOpen(false);
                        window.clearTimeout(timerRef.current);
                        timerRef.current = window.setTimeout(() => {
                            setToastOpen(true);
                        }, 100);
                    });
            } else {
                api.delete(`${ContactsAPI.deleteCompany.url}/${account?.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            updateData();
                            table.toggleAllRowsSelected(false)
                        } else {
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setToastSymbol(CrossCircledIcon);
                        setToastText("Failed to delete account");
                    })
                    .finally(() => {
                        setToastOpen(false);
                        window.clearTimeout(timerRef.current);
                        timerRef.current = window.setTimeout(() => {
                            setToastOpen(true);
                        }, 100);
                    });
            }
        }
        setToastOpen(true);
        setToastSymbol(CheckCircledIcon);
        setToastText("Bulk Deleted Accounts!");
    };

    const handleMergeCustomers = () => {
        if (selectedAccounts.size <= 1) {
            return;
        }
        const requestData = { "customer_ids": Array.from(selectedAccounts).map(account => account.id) };
        api.post(ContactsAPI.mergeCustomers.url, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateData();
                    table.toggleAllRowsSelected(false)
                    setToastOpen(true);
                    setToastSymbol(CheckCircledIcon);
                    setToastText("Merged Customers!");
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                setToastSymbol(CrossCircledIcon);
                setToastText("Failed to merge customers");
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    };

    useEffect(() => {
        const newSelectedAccounts = new Set<Account>();

        // biome-ignore lint/complexity/noForEach: <explanation>
        table.getRowModel().rows.forEach((row) => {
            if (row.getIsSelected()) {
                newSelectedAccounts.add(row.original as unknown as Account);
            }
        });

        // Only update if the selection has changed
        if (selectedAccounts.size !== newSelectedAccounts.size || ![...selectedAccounts].every((account, index) => [...newSelectedAccounts][index] === account)) {
            setSelectedAccounts(newSelectedAccounts);
        }
    }, [rowSelection]);

    const account = selectedAccounts.size === 1
        ? Array.from(selectedAccounts)[0]
        : contextMenuRow?.original;

    // Add the filtering for each column
    return (
        <div className="flex flex-col space-y-4 w-full">
            <DataTableToolbar
                table={table}
                placeholder="Filter Accounts..."
                column="name"
            />
            <ContextMenu>
                <ContextMenuTrigger className={`[data-state='open']`}>
                    <div className="rounded-md border">
                        <Table>
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead
                                                    key={header.id}
                                                    colSpan={header.colSpan}
                                                    style={{
                                                        width: header.column.getSize(),
                                                    }}
                                                >
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef
                                                                .header,
                                                            header.getContext(),
                                                        )}
                                                </TableHead>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map((row, rowIndex) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                data-state={
                                                    row.getIsSelected() && "selected"
                                                }
                                                className={`cursor-pointer hover:bg-gray-100 ${row.getIsSelected() ? "bg-iris3 hover:bg-iris3" : ""}`}
                                                onContextMenu={() => handleRowRightClick(row)}
                                                onClick={(event) => {
                                                    handleRowClick(row)
                                                }}
                                            >
                                                {/* Checkbox Column */}
                                                <TableCell>
                                                    <Checkbox
                                                        className={`${row.getIsSelected()
                                                            ? "opacity-100"
                                                            : "opacity-0"
                                                            } ml-2 hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 lb-comment-details data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer`}
                                                        checked={row.getIsSelected()}
                                                        onCheckedChange={row.getToggleSelectedHandler()}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleCheckboxClickWithShift(event, rowIndex);
                                                        }}
                                                    />
                                                </TableCell>

                                                {/* Other columns */}
                                                {row.getVisibleCells().map((cell) => {
                                                    if (cell.column.id !== "select") {
                                                        return (
                                                            <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                            </TableCell>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns?.length}
                                            className="h-24 text-center"
                                        >
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </ContextMenuTrigger>
                <ContextMenuPortal forceMount>
                    <ContextMenuContent className="w-60">
                        {selectedAccounts.size <= 1 &&
                            <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    if (selectedAccounts.size === 0) {
                                        handleOpenInNewTabRow(event, contextMenuRow);
                                    } else if (selectedAccounts.size === 1) {
                                        handleOpenInNewTab(event, Array.from(selectedAccounts)[0]);
                                    }
                                }}
                            >
                                <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                                Open in New Tab
                            </ContextMenuItem>
                        }

                        {selectedAccounts.size <= 1 &&
                            <AccountPopup
                                triggerElement={
                                    <ContextMenuItem
                                        inset
                                        onSelect={(e) => e.preventDefault()}
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    >
                                        <Pencil1Icon className="w-3.5 h-3.5" />
                                        Edit
                                    </ContextMenuItem>
                                }
                                editing={true}
                                setToastOpen={setToastOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                type={
                                    account?.account_type ===
                                        "Company"
                                        ? "Company"
                                        : "Customer"
                                }
                                editingObject={account}
                                contacts={account?.associated_customers}
                                labels={convertAccountLabelsToMap(account?.labels ?? [], account?.account_type ?? "Customer")}
                                updateData={updateData}
                                teamsQuery={teamsQuery}
                                usersQuery={usersQuery}
                                userID={userID}
                                orgID={orgID}
                                listType={listType}
                            />
                        }

                        {/* Delete Customer */}
                        <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                            <AlertDialogTrigger asChild>
                                <ContextMenuItem
                                    inset
                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    onSelect={(e) => e.preventDefault()}
                                >
                                    <TrashIcon className="w-3.5 h-3.5" />
                                    {selectedAccounts.size > 1 ? "Bulk Delete" : "Delete"}
                                </ContextMenuItem>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                                <div>
                                    <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                        <AlertDialogTitle>
                                            Are you absolutely sure?
                                        </AlertDialogTitle>
                                        <AlertDialogDescription>
                                            {`This action cannot be undone. This will permanently delete account(s): ${selectedAccounts.size > 0 ? Array.from(selectedAccounts).map(a => ` ${a.name}`) : contextMenuRow?.original.name}`}
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                </div>
                                <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                    <AlertDialogCancel onClick={() => setDeleteDialogOpen(false)}>
                                        Cancel
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                        onClick={() => {
                                            if (selectedAccounts.size === 0) {
                                                handleDeleteRow(contextMenuRow);
                                            } else {
                                                handleBulkDelete()
                                            }
                                        }}
                                    >
                                        Delete
                                    </AlertDialogAction>
                                    <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 " onClick={() => setDeleteDialogOpen(false)}>
                                        <Cross2Icon />
                                    </AlertDialogCancel>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>

                        {/* Conditionally render the ContextMenuSub only if there are selected accounts */}
                        {selectedAccounts.size !== 0 && Array.from(selectedAccounts).every(account => account.account_type === 'Customer') && (
                            <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    handleMergeCustomers();
                                }}
                            >
                                <MergeIcon className="w-3.5 h-3.5" />
                                Merge Accounts
                            </ContextMenuItem>
                        )}
                    </ContextMenuContent>
                </ContextMenuPortal>
            </ContextMenu>
            <AccountsDataTablePagination
                table={table}
                setPageSize={setPageSize}
                setPageIndex={setPageIndex}
            />
        </div>
    );
}
