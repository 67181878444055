import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { Command, CommandGroup, CommandItem, CommandList } from "@/component/shadcn/ui/command";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import type { Teams } from "@/interfaces/serverData";
import { getBadgeForTeam } from "@/utilities/methods";
import { CheckIcon, Pencil2Icon } from "@radix-ui/react-icons";
import { HotelIcon, HouseIcon } from "lucide-react";
import { useEffect, useState } from "react";

interface TeamsDropdownProps<TData> {
    selectedTeams: Teams[]
    teams: Teams[]
    toggleTeam: (team: Teams, tagTeams: Teams[]) => void,
    defaultIsWorkspace: boolean, // If not, then "General" default should be used
    orgID?: string;
    notEditable?: boolean;
}

export function TeamsDropdown<TData>({
    selectedTeams,
    teams,
    toggleTeam,
    defaultIsWorkspace,
    orgID,
    notEditable
}: TeamsDropdownProps<TData>) {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredTeams, setFilteredTeams] = useState<Teams[]>(teams);

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredTeams(teams);
        } else {
            // Filter the queries based on the search value
            setFilteredTeams(
                teams.filter((team) =>
                    (team.team_name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    useEffect(() => {
        if (searchQuery.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredTeams(teams);
        } else {
            // Filter the queries based on the search value
            setFilteredTeams(
                teams.filter((team) =>
                    (team.team_name)
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    }, [teams])

    return (
        <div>
            {notEditable ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex flex-wrap items-start gap-2 justify-start p-0 w-full data-[state=open]:bg-muted !h-auto">
                                {(selectedTeams.length ?? 0) > 0 ? (
                                    selectedTeams.map((team: Teams) => {
                                        return getBadgeForTeam(team.team_name)
                                    })
                                ) : (
                                    defaultIsWorkspace ?
                                        (
                                            <Badge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal">
                                                <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                                    <HotelIcon
                                                        className="text-blue9"
                                                        strokeWidth={1.5}
                                                        size={12}
                                                    />
                                                </div>
                                                Workspace
                                            </Badge>
                                        ) : (
                                            <Badge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal">
                                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                                    <HouseIcon
                                                        className="text-iris9"
                                                        strokeWidth={1.5}
                                                        size={12}
                                                    />
                                                </div>
                                                General
                                            </Badge>
                                        )
                                )}
                            </div>
                        </TooltipTrigger>
                        <TooltipContent
                            sideOffset={5}
                            className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                            asChild
                        >
                            <Card className="bg-white text-gray11 p-3 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                Contacts are in the same teams as the company they are attached to.
                            </Card>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <DropdownMenu>
                    <DropdownMenuTrigger
                        asChild
                        className="hover:bg-muted col-span-3 w-full"
                        type="button"
                    >
                        <div className="w-full">
                            <Button
                                variant="ghost"
                                className="flex flex-wrap items-start gap-2 justify-start p-0 w-full data-[state=open]:bg-muted !h-auto"
                                type="button"
                            >
                                {(selectedTeams.length ?? 0) > 0 ? (
                                    selectedTeams.map((team: Teams) => {
                                        return getBadgeForTeam(team.team_name)
                                    })
                                ) : (
                                    defaultIsWorkspace ?
                                        (
                                            <Badge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal">
                                                <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                                    <HotelIcon
                                                        className="text-blue9"
                                                        strokeWidth={1.5}
                                                        size={12}
                                                    />
                                                </div>
                                                Workspace
                                            </Badge>
                                        ) : (
                                            <Badge variant="outline" className="gap-1 py-1 px-1.5 text-xs font-normal">
                                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                                    <HouseIcon
                                                        className="text-iris9"
                                                        strokeWidth={1.5}
                                                        size={12}
                                                    />
                                                </div>
                                                General
                                            </Badge>
                                        )
                                )}
                                <Pencil2Icon className="mt-2" />
                            </Button>
                        </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        side="bottom"
                        align="start"
                        className="fixed w-[200px] p-0 bg-muted rounded-md shadow-lg"
                    >
                        <div className="p-2">
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                                className="w-full p-1.5 border rounded-md text-xs"
                            />
                        </div>
                        <div className="h-full max-h-30 overflow-y-auto">
                            <Command className="bg-muted pb-2">
                                <CommandList>
                                    <CommandGroup>
                                        {filteredTeams.length >
                                            0 ? (
                                            filteredTeams.map(
                                                (team) => {
                                                    const isSelected =
                                                        selectedTeams.map((t: Teams) => t.id).includes(team.id) || (selectedTeams.length === 0 && (team.id === orgID || team.id === "workspace"));
                                                    return (
                                                        <CommandItem
                                                            key={
                                                                team.id
                                                            }
                                                            onSelect={() => {
                                                                toggleTeam(team, selectedTeams)
                                                            }
                                                            }
                                                            className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                                        >
                                                            <div
                                                                className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${isSelected
                                                                    ? "bg-primary text-primary-foreground"
                                                                    : "opacity-50"
                                                                    }`}
                                                            >
                                                                {isSelected && (
                                                                    <CheckIcon className="h-3 w-3" />
                                                                )}
                                                            </div>
                                                            {getBadgeForTeam(team.team_name)}
                                                        </CommandItem>
                                                    );
                                                },
                                            )
                                        ) : (
                                            <div className="p-2 text-xs text-gray-500">
                                                No results found
                                            </div>
                                        )}
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </div>
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </div>)
}