import { Box } from "@radix-ui/themes";
import AutocloseCard from "./Autoclose";
import Timer from "./Timer";

export default function Settings() {

    return (
        <Box mt={"5%"} ml={"28%"} mr={"28%"}>
            <div className="flex flex-row justify-between">
                <h2 className="text-2xl font-semibold">Settings</h2>
            </div>
            <p className="text-sm text-gray11 pb-3">Manage general settings</p>
            <div className="flex flex-col gap-8">
                <AutocloseCard />
                <Timer />
            </div>
        </Box>
    );
}
