import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { FancyMultiSelect } from "@/component/MultiSelect";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Label } from "@/component/shadcn/ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetTopicsPrefResponse,
    GetUserResponse,
    ScopeResponse,
    Topic,
} from "@/interfaces/serverData";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    Cross2Icon,
    DotFilledIcon,
    DoubleArrowRightIcon,
    ExclamationTriangleIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Badge, ChevronDownIcon, Flex, Separator } from "@radix-ui/themes";
import { type UseMutationResult, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import type React from "react";
import { useEffect, useState } from "react";
import type { Workflow } from "./Workflows";

import { supportedThenConstants } from "./constants";

const DropdownTrigger = (
    type: string,
    filters: Map<string, Set<string>>,
    topics: Topic[],
    selectedTopic: string | undefined,
    selectedSource: string | undefined,
) => {
    switch (type) {
        case "Source": {
            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="px-2 py-1 gap-2 text-xs rounded-xl flex items-center justify-between min-w-[100px]"
                >
                    {selectedSource === undefined ? (
                        <>Source...</>
                    ) : (
                        <p>
                            Issue Source: <b>{selectedSource}</b>
                        </p>
                    )}
                    <ChevronDownIcon className="ml-auto" />
                </Badge>
            );
        }
        case "Topic": {
            let topic: {
                color: string;
                label: string;
                value: string;
            } | undefined;
            if (selectedTopic === "All") {
                topic = {
                    label: "All",
                    value: "All",
                    color: "gray",
                }
            } else {
                topic = topics.find(
                    (topic) => topic.value === selectedTopic,
                )
            }
            const color = topic?.color;
            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="px-2 py-1 gap-2 text-xs rounded-xl flex flex-row items-center justify-between min-w-[100px]"
                >
                    {(selectedTopic === undefined || selectedTopic === "") ? (
                        <>Tag...</>
                    ) : (
                        <div className="flex flex-row items-center">
                            <p className="mr-1">Tag:</p>
                            <DotFilledIcon
                                color={(color && color !== "") ? color : "#9B9EF0"}
                                style={{ transform: "scale(1.8)" }}
                            />
                            <b>{topic?.label}</b>
                        </div>
                    )}
                    <ChevronDownIcon className="ml-auto" />
                </Badge>
            );
        }
    }
};

const DropdownTriggerForSupportedThen = (
    type: string,
    selectedThenFilters: Map<string, Set<string>>,
    topics: Topic[],
    tags: Category[],
    users: GetUserResponse[],
    selectedThenTopic: string,
    selectedSource: string | undefined,
    selectedTag: string,
    selectedAssignee: string,
) => {
    switch (type) {
        case "Assignee": {
            const assigneeSet = selectedThenFilters.get("Assignee");
            const currAssignee =
                assigneeSet && assigneeSet.size === 1
                    ? Array.from(assigneeSet)[0]
                    : "NoAssignee";
            const assigneeInfo = users.find((user) => user.id === currAssignee);
            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl w-100"
                >
                    <div className="flex items-center gap-1.5">
                        Assign Issue To:
                        <div className="flex items-center gap-1">
                            <div className="lb-avatar rounded-lg w-4 h-4">
                                {assigneeInfo?.picture_url ? (
                                    <img
                                        className="lb-avatar-image"
                                        src={assigneeInfo?.picture_url}
                                        alt={assigneeInfo?.username}
                                    />
                                ) : (
                                    <AvatarIcon className="w-4 h-4" />
                                )}
                            </div>
                            <span className="lb-comment-author text-xs font-normal font-destructive">
                                {assigneeInfo
                                    ? `${assigneeInfo?.first_name} ${assigneeInfo?.last_name}`
                                    : "No Assignee"}
                            </span>
                        </div>
                    </div>
                </Badge>
            );
        }
        case "Tag": {
            const tagSet = selectedThenFilters.get("Tag");
            const currTag =
                tagSet && tagSet.size === 1 ? Array.from(tagSet)[0] : "";
            const tagInfo = tags.find(
                (tag) => tag.id === currTag || tag.name === currTag,
            );

            return (
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-0.5 px-2 py-1 text-xs rounded-xl"
                >
                    <div className="flex flex-row items-center">
                        {selectedTag === "" ? (
                            <>Assign Interaction Type...</>
                        ) : (
                            <div className="flex items-center gap-1.5">
                                Assign Interaction Type:
                                <div className="flex items-center">
                                    <ComponentBooleanIcon
                                        color={tagInfo?.color ?? "gray"}
                                    />
                                    <p className="pl-0.5">{tagInfo?.name}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </Badge>
            );
        }
        case "Topic": {
            const topicSet = selectedThenFilters.get("Topic");
            const currTopic =
                topicSet && topicSet.size === 1 ? Array.from(topicSet)[0] : "";

            const topicInfo = topics.find(
                (topic) =>
                    topic.label === currTopic || topic.value === currTopic,
            );

            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="px-2 py-1 gap-2 text-xs rounded-xl flex flex-row items-center justify-between min-w-[100px]"
                >
                    {selectedThenTopic === "" ? (
                        <>Assign Tag...</>
                    ) : (
                        <div className="flex flex-row items-center">
                            <p className="mr-1">Assign Tag: </p>
                            <DotFilledIcon
                                color={
                                    topicInfo?.color !== ""
                                        ? topicInfo?.color
                                        : "#9B9EF0"
                                }
                                style={{ transform: "scale(1.8)" }}
                            />
                            <b>{topicInfo?.label}</b>
                        </div>
                    )}
                    <ChevronDownIcon className="ml-auto" />
                </Badge>
            );
        }
    }
};

interface WorkflowPopupProps {
    workflow: Workflow | undefined;
    topics: Topic[];
    selectedSource: string | undefined;
    setSelectedSource: React.Dispatch<React.SetStateAction<string | undefined>>;
    options: Map<string, ScopeResponse[]>;
    supportedWhen: string[];
    setSupportedWhen: React.Dispatch<React.SetStateAction<string[]>>;
    selectedChannels: ScopeResponse[];
    setSelectedChannels: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    supportedFields: string[];
    setSupportedFields: React.Dispatch<React.SetStateAction<string[]>>;
    supportedThen: string[];
    setSupportedThen: React.Dispatch<React.SetStateAction<string[]>>;
    users: GetUserResponse[];
    tags: Category[];
    selectedTopic: string | undefined;
    setSelectedTopic: React.Dispatch<React.SetStateAction<string | undefined>>;
    addWorkflow: UseMutationResult<
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        AxiosResponse<any, any>,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        any,
        Workflow,
        unknown
    >;
    updateWorkflow: UseMutationResult<
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        AxiosResponse<any, any>,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        any,
        Workflow,
        unknown
    >;
    icon?: React.ElementType;
    triggerElement: React.ReactNode;
    editing: boolean;
    clearWorkflowDetails: () => void;
    selectedTag: string;
    setSelectedTag: React.Dispatch<React.SetStateAction<string>>;
    selectedAssignee: string;
    setSelectedAssignee: React.Dispatch<React.SetStateAction<string>>;
    selectedThenTopic: string;
    setSelectedThenTopic: React.Dispatch<React.SetStateAction<string>>;
}

const WorkflowPopup: React.FC<WorkflowPopupProps> = ({
    workflow,
    selectedSource,
    setSelectedSource,
    options,
    selectedChannels,
    setSelectedChannels,
    supportedFields,
    setSupportedFields,
    selectedTopic,
    setSelectedTopic,
    addWorkflow,
    updateWorkflow,
    icon,
    triggerElement,
    editing,
    supportedWhen,
    setSupportedWhen,
    supportedThen,
    setSupportedThen,
    users,
    clearWorkflowDetails,
    topics,
    tags,
    selectedTag,
    setSelectedTag,
    selectedAssignee,
    setSelectedAssignee,
    selectedThenTopic,
    setSelectedThenTopic,
}) => {
    const api = useApi();
    useEffect(() => {
        if (workflow) {
            setSelectedSource(
                workflow.if.source === "*" ? "All" : workflow.if.source,
            );
            setSelectedTopic(
                workflow.if.topic === "*" ? "All" : workflow.if.topic,
            );
            setSelectedChannels(workflow.if.channels);

            if (workflow.then.length > 0) {
                const objMap = new Map(
                    Object.entries(workflow.then[0]?.metadata),
                );
                if (objMap.get("tag") !== undefined) {
                    const tagId = objMap.get("tag");
                    const tagName = tags.find(tag => tag.id === tagId)?.name;
                    setSelectedTag(tagName ?? "");
                }
                setSelectedAssignee(objMap.get("user") ?? "");

                const topicsList = (objMap.get("topic") ?? []) as string[];
                if (topicsList.length > 0) {
                    setSelectedThenTopic(topicsList[0]);
                }
            }
        }
    }, [setSelectedChannels, setSelectedSource, setSelectedTopic, workflow]);

    const { data: aiTaggingEnabled = true, refetch: refetch_prefs } = useQuery({
        queryKey: ["topicsPreferences"],
        queryFn: () => fetchTopicsPref(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const fetchTopicsPref = async (): Promise<boolean> => {
        const response = await api.get(URLS.serverUrl + API.getTopicsPref, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            const topicsPref: GetTopicsPrefResponse = response.data.data;
            return topicsPref.ai_tagging;
        }
        // Default is true
        return true;
    };

    const dropdownTypes =
        aiTaggingEnabled && selectedThenTopic === ""
            ? ["Source", "Topic"]
            : ["Source"];

    // const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active


    const filters = new Map<string, Set<string>>();
    filters.set("Source", new Set([selectedSource ?? ""]));
    filters.set("Topic", new Set([selectedTopic ?? ""]));

    const selectedThenFilters = new Map<string, Set<string>>();
    selectedThenFilters.set("Topic", new Set([selectedThenTopic]));
    selectedThenFilters.set("Tag", new Set([selectedTag]));
    selectedThenFilters.set("Assignee", new Set([selectedAssignee]));

    const [selectedWhen, setSelectedWhen] = useState<string | undefined>(
        workflow?.when || "",
    ); // Prepopulate with workflow "when"

    const [workflowTitle, setWorkflowTitle] = useState<string>(
        workflow?.name ? workflow.name : "New Workflow",
    );
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [validationErr, setValidationErr] = useState<boolean>(false);

    const validation = async () => {
        setValidationErr(false);
        setErrorMsg(undefined);
        if (selectedWhen === undefined) {
            setErrorMsg("Please select a trigger");
            setValidationErr(true);
        }

        if (selectedTopic === undefined && selectedSource === undefined) {
            setErrorMsg("Please select a topic or a source");
            setValidationErr(true);
        }

        if (
            selectedAssignee === undefined &&
            selectedThenTopic === "" &&
            selectedTag === ""
        ) {
            setErrorMsg("Please select an action under THEN");
            setValidationErr(true);
        }
        if (validationErr === false) {
            const source = selectedSource === "All" ? "*" : selectedSource;
            const topic = selectedTopic === "All" ? "*" : selectedTopic;

            const map = new Map<string, string | string[]>();

            if (selectedAssignee !== "") {
                map.set("user", selectedAssignee);
            }

            if (selectedTag !== "") {
                map.set("tag", selectedTag);
            }

            if (selectedThenTopic !== "") {
                map.set("topic", [selectedThenTopic]);
            }

            const newWorkflow: Workflow = {
                name: workflowTitle,
                // biome-ignore lint/style/noNonNullAssertion: already checked null above
                when: selectedWhen!,
                if: {
                    // biome-ignore lint/style/noNonNullAssertion: already checked null above
                    source: source!,
                    // biome-ignore lint/style/noNonNullAssertion: already checked null above
                    topic: topic!,
                    channels: selectedChannels,
                },
                // biome-ignore lint/suspicious/noThenProperty: inapplicable
                then: [
                    {
                        action: supportedThenConstants.AssignIssueTo,
                        metadata: Object.fromEntries(map),
                    },
                ],
            };
            if (editing) {
                newWorkflow.id = workflow?.id;
                updateWorkflow.mutate(newWorkflow);
                setWorkflowTitle("New Workflow")
                clearWorkflowDetails();
            } else {
                addWorkflow.mutate(newWorkflow);
                setWorkflowTitle("New Workflow")
                clearWorkflowDetails();
            }
        }
    };

    const selectedThenDropdownTypes =
        (!selectedTopic || selectedTopic === "")
            ? ["Assignee", "Tag", "Topic"]
            : ["Assignee", "Tag"];

    const handleItemSelect = (type: string, option: { label: string; value: string; key: string; color: string }) => () => {
        switch (type) {
            case "Topic": {
                setSelectedTopic(option.value);
                filters.set("Topic", new Set([option.value]));
                return;
            }
            case "Source": {
                setSelectedSource(option.value);
                filters.set("Source", new Set([option.value]));
                setSelectedChannels([])
                return;
            }

            default:
                console.log("handleItemSelect does not support type", type);
        }
    };

    const handleSelectedThenItemSelect =
        (type: string, option: { label: string; value: string; key: string; color: string }) => () => {
            switch (type) {
                case "Topic": {
                    setSelectedThenTopic(option.value);
                    selectedThenFilters.set("Topic", new Set([option.value]));
                    return;
                }
                case "Tag": {
                    setSelectedTag(option.value);
                    selectedThenFilters.set("Tag", new Set([option.value]));
                    return;
                }
                case "Assignee": {
                    setSelectedAssignee(option.value);
                    selectedThenFilters.set("Assignee", new Set([option.value]));
                    return;
                }
                default:
                    console.log("handleItemSelect does not support type", type);
            }
        };

    return (
        <Dialog>
            <DialogTrigger asChild className="outline-none">
                {triggerElement}
            </DialogTrigger>
            <DialogContent className="pt-10 max-w-[1000px] w-full">
                <div className="ml-10 mr-10">
                    <DialogHeader className="justify-left text-left items-left pb-5">
                        <DialogTitle>
                            {editing ? "Edit Workflow" : "New Workflow"}
                        </DialogTitle>
                        <DialogDescription>
                            Workflows allow you to define a set of rules
                            that will be applied to your data.
                        </DialogDescription>
                    </DialogHeader>
                    <div className="flex flex-col ">
                        <div className="flex flex-col gap-4">
                            <Label>Title</Label>
                            <Input
                                value={workflowTitle}
                                onChange={(e) => {
                                    setWorkflowTitle(e.target.value);
                                }}
                                className="w-full rounded-full mb-5"
                            />
                        </div>
                        <div className="flex flex-row gap-3">
                            <div className="flex flex-col items-center">
                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                    <DoubleArrowRightIcon className="text-iris9" />
                                </div>
                                <Separator
                                    orientation="vertical"
                                    className="bg-[#D8D8D8] w-[1px] h-16"
                                />
                            </div>
                            <div className="flex flex-col pt-1 gap-2">
                                <Label>WHEN...</Label>
                                <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 shadow-sm bg-white pl-2">
                                    <Select
                                        value={selectedWhen}
                                        onValueChange={setSelectedWhen}
                                    >
                                        <SelectTrigger className="w=full">
                                            <SelectValue placeholder="Select a trigger" />
                                        </SelectTrigger>
                                        <SelectContent className="w-full">
                                            {supportedWhen.map((when) => (
                                                <SelectItem
                                                    key={when}
                                                    value={when}
                                                    className="w-full place-items-start"
                                                >
                                                    <div className="flex flex-row gap-2 items-center w-full">
                                                        <div className="flex items-center justify-center rounded-lg p-1 bg-mint3 border border-mint4 shadow-sm">
                                                            <PlusIcon className="text-mint11" />
                                                        </div>
                                                        <span className="flex-1">
                                                            {when}
                                                        </span>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-3">
                            <div className="flex flex-col items-center">
                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                    <DoubleArrowRightIcon className="text-iris9" />
                                </div>
                                <Separator
                                    orientation="vertical"
                                    className={`bg-[#D8D8D8] w-[1px] ${["Discord", "Slack"].includes(selectedSource ?? "") ? "h-24" : "h-16"}`}
                                />
                            </div>

                            <div className="flex flex-col pt-1 gap-2">
                                <Label>IF...</Label>
                                <div className="flex items-center px-2 py-1 bg-white pl-2">
                                    <Flex className="flex flex-col gap-5">
                                        <div className="flex flex-row items-center gap-3">
                                            {dropdownTypes.map((type) => {
                                                if (
                                                    type === "Channel" &&
                                                    ![
                                                        "Slack",
                                                        "CommunitySlack",
                                                        "Discord",
                                                    ].includes(
                                                        selectedSource ??
                                                        "",
                                                    )
                                                ) {
                                                    return <></>;
                                                }
                                                return (
                                                    <>
                                                        <DropdownMenu
                                                            key={type}
                                                        >
                                                            <DropdownMenuTrigger
                                                                asChild
                                                                type="button"
                                                            >
                                                                {DropdownTrigger(
                                                                    type,
                                                                    filters,
                                                                    topics,
                                                                    selectedTopic,
                                                                    selectedSource,
                                                                )}
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent
                                                                align="start"
                                                                className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                                            >
                                                                <FilterDropdownElement
                                                                    type={
                                                                        type
                                                                    }
                                                                    filters={
                                                                        filters
                                                                    }
                                                                    handleItemSelect={
                                                                        handleItemSelect
                                                                    }
                                                                    topics={
                                                                        topics
                                                                    }
                                                                    users={[]}
                                                                    extraOptions={[
                                                                        {
                                                                            label: "All",
                                                                            value: "All",
                                                                            color: "gray",
                                                                            key: "All"
                                                                        },
                                                                    ]}
                                                                    channels={
                                                                        options
                                                                    }
                                                                    key={
                                                                        selectedSource
                                                                    }
                                                                    customerGroups={[]}
                                                                    isSavedViewFilter={
                                                                        false
                                                                    }
                                                                    categories={[]}
                                                                    teams={[]}
                                                                />
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                        <DialogClose>
                                                            <Button
                                                                key={type}
                                                                variant="ghost"
                                                                className="w-4 px-0 ml-0 mr-3"
                                                                hidden={
                                                                    (type ===
                                                                        "Source") ||
                                                                    (type ===
                                                                        "Topic" && (!selectedTopic || selectedTopic === ""))
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        type ===
                                                                        "Topic"
                                                                    ) {
                                                                        setSelectedTopic(undefined)
                                                                        setSelectedThenTopic(
                                                                            "",
                                                                        );
                                                                        selectedThenFilters.set(
                                                                            "Topic",
                                                                            new Set(
                                                                                [
                                                                                    "",
                                                                                ],
                                                                            ),
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                <Cross2Icon className="h-4 w-4 text-gray-500 hover:text-gray-700" />
                                                            </Button>
                                                        </DialogClose>
                                                    </>
                                                );
                                            })}
                                        </div>
                                        {selectedSource && ["Slack", "CommunitySlack", "Discord", "Gmail", "API"].includes(selectedSource) && (
                                            <FancyMultiSelect
                                                setSelectedChannels={
                                                    setSelectedChannels
                                                }
                                                selectedChannels={
                                                    selectedChannels
                                                }
                                                options={
                                                    options.get(selectedSource) ??
                                                    []
                                                }
                                                placeholder="Issue Source subchannels..."
                                            />
                                        )}
                                    </Flex>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-3">
                            <div className="flex flex-col items-center">
                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                    <DoubleArrowRightIcon className="text-iris9" />
                                </div>
                                <Separator
                                    orientation="vertical"
                                    className="bg-[#D8D8D8] w-[1px]  h-16"
                                />
                            </div>

                            <div className="flex flex-col pt-1 gap-2">
                                <Label>THEN...</Label>
                                <div className="flex items-center px-2 py-1 bg-white pl-2">
                                    <div className="flex flex-row items-center gap-3 flex-wrap">
                                        {selectedThenDropdownTypes.map(
                                            (type) => {
                                                return (
                                                    <div
                                                        key={type}
                                                        className="flex flex-row items-center gap-1"
                                                    >
                                                        <DropdownMenu
                                                            key={type}
                                                        >
                                                            <DropdownMenuTrigger
                                                                asChild
                                                                type="button"
                                                            >
                                                                {DropdownTriggerForSupportedThen(
                                                                    type,
                                                                    selectedThenFilters,
                                                                    topics,
                                                                    tags,
                                                                    users,
                                                                    selectedThenTopic,
                                                                    selectedSource,
                                                                    selectedTag,
                                                                    selectedAssignee,
                                                                )}
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent
                                                                align="start"
                                                                className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                                            >
                                                                <FilterDropdownElement
                                                                    type={
                                                                        type
                                                                    }
                                                                    filters={
                                                                        selectedThenFilters
                                                                    }
                                                                    handleItemSelect={
                                                                        handleSelectedThenItemSelect
                                                                    }
                                                                    topics={
                                                                        topics
                                                                    }
                                                                    users={
                                                                        users
                                                                    }
                                                                    channels={
                                                                        options
                                                                    }
                                                                    key={
                                                                        selectedSource
                                                                    }
                                                                    customerGroups={[]}
                                                                    isSavedViewFilter={
                                                                        false
                                                                    }
                                                                    categories={
                                                                        tags
                                                                    }
                                                                    teams={[]}
                                                                />
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                        <Button
                                                            key={type}
                                                            variant="ghost"
                                                            className="w-4 px-0 ml-0 mr-3"
                                                            hidden={
                                                                (type ===
                                                                    "Assignee" &&
                                                                    selectedAssignee ===
                                                                    "") ||
                                                                (type ===
                                                                    "Topic" &&
                                                                    selectedThenTopic ===
                                                                    "") ||
                                                                (type ===
                                                                    "Tag" &&
                                                                    selectedTag ===
                                                                    "")
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    type ===
                                                                    "Topic"
                                                                ) {
                                                                    setSelectedThenTopic(
                                                                        "",
                                                                    );
                                                                    selectedThenFilters.set(
                                                                        "Topic",
                                                                        new Set(
                                                                            [
                                                                                "",
                                                                            ],
                                                                        ),
                                                                    );
                                                                } else if (
                                                                    type ===
                                                                    "Tag"
                                                                ) {
                                                                    setSelectedTag(
                                                                        "",
                                                                    );
                                                                    selectedThenFilters.set(
                                                                        "Tag",
                                                                        new Set(
                                                                            [
                                                                                "",
                                                                            ],
                                                                        ),
                                                                    );
                                                                } else if (
                                                                    type ===
                                                                    "Assignee"
                                                                ) {
                                                                    setSelectedAssignee(
                                                                        "",
                                                                    );
                                                                    selectedThenFilters.set(
                                                                        "Assignee",
                                                                        new Set(
                                                                            [
                                                                                "",
                                                                            ],
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Cross2Icon className="h-4 w-4 text-gray-500 hover:text-gray-700" />
                                                        </Button>
                                                    </div>
                                                );
                                            },
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter className="flex flex-col">
                        <DialogClose>
                            <div className="pt-4 justify-end text-end items-end">
                                <Button
                                    className="bg-iris9"
                                    type="submit"
                                    onClick={validation}
                                >
                                    Save
                                </Button>
                            </div>
                        </DialogClose>
                        {validationErr && (
                            <Alert variant="destructive" className="mt-4">
                                <div className="flex flex-row gap-3 items-center">
                                    <ExclamationTriangleIcon className="h-4 w-4" />
                                    <div>
                                        <AlertTitle>Error</AlertTitle>
                                        <AlertDescription>
                                            {errorMsg ?? ""}
                                        </AlertDescription>
                                    </div>
                                </div>
                            </Alert>
                        )}
                    </DialogFooter>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default WorkflowPopup;
