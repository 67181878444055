import {
    Background,
    type Connection,
    Controls,
    type EdgeProps,
    type OnEdgesChange,
    type OnNodesChange,
    ReactFlow,
    ReactFlowProvider,
    addEdge,
    useReactFlow,
} from "@xyflow/react";
import type React from "react";
import "@xyflow/react/dist/style.css";
import { Card } from "@/component/shadcn/ui/card";
import type { Workflow } from "@/interfaces/serverData";
import { Box } from "@radix-ui/themes";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { ActionsIcon, ConditionIcon, TriggerIcon, WaitIcon } from "./Icons";
import ActionNode from "./Nodes/ActionNode";
import ConditionNode from "./Nodes/ConditionNode";
import TriggerNode from "./Nodes/TriggerNode";
import WaitNode from "./Nodes/WaitNode";
import { capitalizeFirstLetter, stepToNodeMetadata } from "@/utilities/methods";

const constNodeNames = {
    trigger: "trigger",
    action: "action",
    condition: "condition",
    wait: "wait",
};

const NODE_TYPE_KEY = "node-type";

interface WorkflowEditorProps {
    nodes: never[];
    edges: never[];
    setNodes: React.Dispatch<React.SetStateAction<never[]>>;
    setEdges: React.Dispatch<React.SetStateAction<never[]>>;
    onNodesChange: OnNodesChange<never>;
    onEdgesChange: OnEdgesChange<never>;
    workflow?: Workflow;
}

const WorkflowEditor: React.FC<WorkflowEditorProps> = ({
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    workflow,
}) => {
    const { screenToFlowPosition, fitView } = useReactFlow();
    const containerRef = useRef<HTMLDivElement>(null);

    // Add an initial trigger node
    useEffect(() => {
        if (workflow) {
            const initialNodes: {
                id: string;
                type: string;
                position: {
                    x: number;
                    y: number;
                };
                data: {
                    label: string;
                    id: string;
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    metadata: Record<string, any>;
                };
            }[] = [];
            const initialEdges: {
                id: string;
                source: string;
                target: string;
                type: string;
                sourceHandle: string;
                targetHandle: string;
            }[] = [];
            let currY = 15;
            for (const step of workflow.steps) {
                const node = {
                    id: step.id,
                    type: step.type,
                    position: { x: 150, y: currY }, // Adjusting the position based on the centerX
                    data: {
                        label: capitalizeFirstLetter(step.type),
                        id: step.id,
                        metadata: stepToNodeMetadata(step.type, step.name, step.subtype, new Map(Object.entries(step.metadata || {}))),
                    },
                };
                initialNodes.push(node);

                // Add bigger vertical spacing if was a condition node
                currY += step.type === "condition" ? 350 : 200;

                if (step.parent_id) {
                    // Add an edge
                    const edge = {
                        id: `edge-${step.parent_id}-${step.id}`,
                        source: step.parent_id,
                        target: step.id,
                        type: "default", // Type of edge, e.g., 'default', 'smoothstep'
                        sourceHandle: "b",
                        targetHandle: "a"
                    };
                    initialEdges.push(edge);
                }
            }
            setNodes(initialNodes);
            setEdges(initialEdges);
            fitView({ padding: 0.1 });
        } else {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const centerX = containerWidth / 2;

                const newNodeID = crypto.randomUUID();
                const initialNode = {
                    id: newNodeID,
                    type: "trigger",
                    position: { x: centerX - 175, y: 15 }, // Subtracting from center to account for node with
                    data: {
                        label: "Trigger",
                        metadata: { type: "issue_created" },
                    },
                };
                setNodes([initialNode]);
            }
        }
    }, [setNodes, setEdges, workflow]);

    // Handle updating the metadata of a specific node
    const onUpdate = useCallback(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (id: string, updatedMetadata: any) => {
            setNodes((nds) => {
                // If the id is undefined and there's only one node, update the first node.
                if (id === undefined && nds.length === 1) {
                    return [
                        {
                            ...nds[0],
                            data: { ...nds[0].data, metadata: updatedMetadata },
                        },
                    ];
                }

                // Otherwise, update the node with the matching id.
                return nds.map((node) => {
                    return node.id === id
                        ? { ...node, data: { ...node.data, metadata: updatedMetadata } }
                        : node;
                });
            });
        },
        [setNodes],
    );


    const nodeTypes = useMemo(
        () => ({
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            trigger: (props: any) => (
                <TriggerNode {...props} onUpdate={onUpdate} workflow={workflow} />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            action: (props: any) => (
                <ActionNode {...props} onUpdate={onUpdate} workflow={workflow} />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            condition: (props: any) => (
                <ConditionNode {...props} onUpdate={onUpdate} workflow={workflow} />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            wait: (props: any) => <WaitNode {...props} onUpdate={onUpdate} workflow={workflow} />,
        }),
        [onUpdate],
    );

    const ArrowEdge = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
    }: EdgeProps) => {
        const path = `M${sourceX},${sourceY}L${targetX},${targetY}`;
        return (
            <path
                id={id}
                d={path}
                fill="transparent"
                stroke="#000"
                strokeWidth={2}
                markerEnd="url(#arrowhead)" // Applying the arrowhead marker to the edge
            />
        );
    };

    const edgeTypes = {
        custom: ArrowEdge,
    };

    const onConnect = useCallback(
        (params: Connection) => {
            setEdges((eds) => addEdge(params, eds));
        },
        [setEdges],
    );

    const listNodeTypes = [
        { name: constNodeNames.trigger, label: "Trigger", icon: TriggerIcon },
        {
            name: constNodeNames.condition,
            label: "Condition",
            icon: ConditionIcon,
        },
        { name: constNodeNames.wait, label: "Wait", icon: WaitIcon },
        { name: constNodeNames.action, label: "Action", icon: ActionsIcon },
    ];

    const onDragStart = useCallback(
        (event: React.DragEvent, nodeType: string) => {
            event.dataTransfer?.setData(NODE_TYPE_KEY, nodeType);
            if (event.dataTransfer) {
                event.dataTransfer.effectAllowed = "move";
            }
        },
        [],
    );

    const onDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = "move";
        }
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();

            const type = event.dataTransfer.getData(NODE_TYPE_KEY);

            if (!type) return;

            const position = screenToFlowPosition({
                x: event.clientX,
                y: event.clientY,
            });

            const startingMetadata = (() => {
                console.log("type is", type);
                switch (type) {
                    case "trigger":
                        return { type: "issue_created" };
                    case "condition":
                        return { subtype: "all" };
                    case "wait":
                        return { unit: "days", time: 30 };
                    case "action":
                        return { subtype: "send_template" };
                    default:
                        return {}; // default case, returns an empty object if type doesn't match
                }
            })();

            const newNodeID = crypto.randomUUID();
            const newNode = {
                id: newNodeID,
                type,
                position,
                data: {
                    label: `${type} node`,
                    id: newNodeID,
                    metadata: startingMetadata,
                },
            };

            setNodes((nds) => nds.concat(newNode));
        },
        [screenToFlowPosition, setNodes],
    );

    console.log("nodes are ", nodes);
    console.log("edges are ", edges);

    return (
        <Box className="h-screen">
            <ReactFlowProvider>
                <Box
                    style={{ width: "calc(97vw)", height: "calc(80vh)" }}
                    className="relative"
                    ref={containerRef}
                >
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        fitView={false}
                        nodeTypes={nodeTypes}
                        edgeTypes={edgeTypes}
                        zoom={0.5}
                        connectionMode="loose"
                    >
                        <defs>
                            <marker
                                id="arrowhead"
                                viewBox="0 0 10 10"
                                refX="5"
                                refY="5"
                                markerWidth="20"
                                markerHeight="6"
                                orient="auto"
                            >
                                <path d="M 0 0 L 10 5 L 0 10 z" fill="#000" />
                            </marker>
                        </defs>
                        <Background />
                        <Controls />
                    </ReactFlow>

                    <div className="absolute top-0 left-0 p-2 z-10 bg-white bg-opacity-80 rounded-lg shadow-md flex flex-col gap-1">
                        {listNodeTypes.map((node) => (
                            <Card
                                key={node.name}
                                className="flex flex-row items-center gap-2 bg-white shadow-none border rounded-lg relative p-2 w-[120px]"
                                onDragStart={(event) =>
                                    onDragStart(event, node.name)
                                }
                                draggable
                            >
                                {node.icon && <node.icon />}
                                <p className="text-xs">{node.label}</p>
                            </Card>
                        ))}
                    </div>
                </Box>
            </ReactFlowProvider>
        </Box>
    );
};

export default WorkflowEditor;
