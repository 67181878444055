import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/component/shadcn/ui/alert-dialog";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    Teams,
    UpdateWorkflowRequest,
    Workflow,
} from "@/interfaces/serverData";
import { lastTriggered, workflowEnabled, workflowFrequencyBadge, workflowTypeBadge } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import { ContextMenuItem } from "@radix-ui/react-context-menu";
import { CaretUpIcon, CheckCircledIcon, Cross1Icon, Cross2Icon, CrossCircledIcon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { Badge, Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowDisplay from "./WorkflowDisplay";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";

interface WorkflowsPageProps {
    teamID?: string;
}

const WorkflowsPage: React.FC<WorkflowsPageProps> = ({ teamID }) => {
    const api = useApi();
    const navigate = useNavigate();
    const timerRef = useRef(0);
    const location = useLocation();

    const [workflowSelected, setWorkflowSelected] = useState<Workflow>();
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";
    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );
    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const navigateToWorklowEditor = async (workflowID?: string) => {
        navigate("/preferences/workflows/create");
    };

    useEffect(() => {
        if (location.state && location.state.showToast) {
            setToastText(location.state.toastText)
            if (location.state.toastText.includes("Oops")) {
                setToastSymbol(CrossCircledIcon)
            } else {
                setToastSymbol(CheckCircledIcon)
            }
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
            window.history.replaceState({}, document.title, window.location.href);
        }
    }, [location]);

    // Grab the list of topics for the org
    useEffect(() => {
        let endpoint = URLS.serverUrl + API.getTopics;
        if (teamID) {
            endpoint = `${URLS.serverUrl}${API.getTopics}/team/${teamID}`;
        }
        api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const fetchWorkflows = async (): Promise<Workflow[]> => {
        const response: AxiosResponse<{ data: Workflow[] }> = await api.get(
            `${URLS.serverUrl}${API.getWorkflowsNew}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch workflows");
    };

    const fetchTeamWorkflows = async (): Promise<Workflow[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getWorkflowsNew}/team/${teamID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const {
        data: workflows = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<Workflow[]>({
        queryKey: teamID ? [`teamWorkflowsNew_${teamID}`] : ["workflowsNew"],
        queryFn: teamID ? () => fetchTeamWorkflows() : () => fetchWorkflows(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const deleteWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            return api.delete(`${URLS.serverUrl}${API.deleteWorkflowNew}/${id}`);
        },
        onSuccess: () => {
            refetch()
            setDeleteDialogOpen(false);
            setWorkflowSelected(undefined)
            setToastSymbol(CheckCircledIcon);
            setToastText("Deleted workflow!");
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        },
        onError: (error) => {
            console.error("Delete workflow failed:", error.message);
            setToastText(
                "Oops! Something's wrong. Please try again at a later time.",
            );
            setToastSymbol(CrossCircledIcon);
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        },
    });

    const disableWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            const requestData: UpdateWorkflowRequest = {
                enabled: false
            }
            return api.patch(
                `${URLS.serverUrl}${API.updateWorkflowNew}/${id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
        },
        onSuccess: () => {
            refetch()
            setToastSymbol(CheckCircledIcon);
            setToastText("Disabled workflow!");
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        },
        onError: (error) => {
            console.error("Disable workflow failed:", error.message);
            setToastText(
                "Oops! Something's wrong. Please try again at a later time.",
            );
            setToastSymbol(CrossCircledIcon);
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        },
    });

    const enableWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            const requestData: UpdateWorkflowRequest = {
                enabled: true
            }
            return api.patch(
                `${URLS.serverUrl}${API.updateWorkflowNew}/${id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
        },
        onSuccess: () => {
            refetch()
            setToastSymbol(CheckCircledIcon);
            setToastText("Enabled workflow!");
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        },
        onError: (error) => {
            console.error("Enable workflow failed:", error.message);
            setToastText(
                "Oops! Something's wrong. Please try again at a later time.",
            );
            setToastSymbol(CrossCircledIcon);
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        },
    });

    const handleEnableWorkflow = (e: Event, workflow: Workflow) => {
        // e.preventDefault()
        if (workflow.enabled) { // Disable the workflow
            disableWorkflowMutation.mutate(workflow.id ?? "")
        } else { // Enable the workflow
            enableWorkflowMutation.mutate(workflow.id ?? "")
        }
    }

    useEffect(() => {
        const newWorkflow = workflows.find(w => w.id === workflowSelected?.id)
        setWorkflowSelected(newWorkflow)
    }, [workflows])

    return (
        <div>
            <Box mt={"3%"} ml={"3%"} mr={"3%"} className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-2xl font-semibold">Workflows</h2>
                        <p className="text-sm text-gray11">
                            View and monitor your existing workflows.
                        </p>
                    </div>
                    <Button
                        className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                        size="sm"
                        variant="outline"
                        onClick={() => navigateToWorklowEditor()}
                    >
                        Add Workflow
                        <PlusIcon />
                    </Button>
                </div>

                {isLoading && (
                    <div>
                        <Skeleton>
                            <Text>
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    </div>
                )}
                {!isLoading && !isError && (
                    <div className="max-h-full">
                        <TooltipProvider delayDuration={0}>
                            <ResizablePanelGroup
                                direction="horizontal"
                                onLayout={(sizes: number[]) => {
                                    document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                                        sizes,
                                    )}`;
                                }}
                                className="h-fit items-stretch relative z-10"
                            >
                                <ResizablePanel
                                    defaultSize={
                                        workflowSelected !== undefined
                                            ? window.innerWidth * 0.35
                                            : window.innerWidth
                                    }
                                    key="workflowsList"
                                    className="flex flex-col gap-1.5 overflow-y-scroll py-2 pr-2"
                                >   {workflows?.length === 0 && !isLoading && !isError && (
                                    <p className="text-sm">
                                        No workflows found. Create a new workflow to get
                                        started!
                                    </p>
                                )}
                                    {workflows?.map((workflow) => (
                                        <Card
                                            key={workflow.id}
                                            className="rounded-lg shadow-none flex flex-col gap-2.5 overflow-hidden"
                                        >
                                            <ContextMenu>
                                                <ContextMenuTrigger className={`hover:bg-muted items-start w-full px-4 py-6 ${workflowSelected?.id === workflow.id ? "bg-sidebarBorder" : ""}`} onClick={() => setWorkflowSelected(workflow)}>
                                                    <div className="flex items-center justify-between">
                                                        <div className="flex flex-col gap-0.5 items-start">
                                                            <div className="font-semibold text-sm flex flex-row items-center gap-2 w-full flex-nowrap">
                                                                {workflowEnabled(workflow)}
                                                                <span className="text-ellipsis overflow-hidden whitespace-nowrap">{workflow.name}</span>
                                                            </div>
                                                            <p className="text-xs text-gray-500">
                                                                {workflow.description}
                                                            </p>
                                                            {workflowSelected && (
                                                                <div className="flex flex-wrap items-center gap-2 pt-1">
                                                                    {workflowTypeBadge(workflow)}
                                                                    {lastTriggered(workflow)}
                                                                    {workflowFrequencyBadge(workflow)}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {!workflowSelected && (
                                                            <div className="flex items-center gap-2">
                                                                {workflowTypeBadge(workflow)}
                                                                {lastTriggered(workflow)}
                                                                {workflowFrequencyBadge(workflow)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </ContextMenuTrigger>

                                                <ContextMenuContent className="w-25">
                                                    <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
                                                        <AlertDialogTrigger asChild>
                                                            <ContextMenuItem
                                                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5 p-2"
                                                                onSelect={(e) => e.preventDefault()}
                                                            >
                                                                <TrashIcon className="w-3.5 h-3.5" />
                                                                Delete
                                                            </ContextMenuItem>
                                                        </AlertDialogTrigger>
                                                        <AlertDialogContent>
                                                            <div>
                                                                <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                                    <AlertDialogTitle>
                                                                        Are you absolutely sure?
                                                                    </AlertDialogTitle>
                                                                    <AlertDialogDescription>
                                                                        {`This action cannot be undone. This will permanently delete this workflow: ${workflow.name}.`}
                                                                    </AlertDialogDescription>
                                                                </AlertDialogHeader>
                                                            </div>
                                                            <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                                <AlertDialogCancel onClick={() => setDeleteDialogOpen(false)}>
                                                                    Cancel
                                                                </AlertDialogCancel>
                                                                <AlertDialogAction
                                                                    onClick={() => {
                                                                        deleteWorkflowMutation.mutate(
                                                                            workflow.id ??
                                                                            "",
                                                                        );
                                                                    }}
                                                                >
                                                                    Delete
                                                                </AlertDialogAction>
                                                                <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 " onClick={() => setDeleteDialogOpen(false)}>
                                                                    <Cross2Icon />
                                                                </AlertDialogCancel>
                                                            </AlertDialogFooter>
                                                        </AlertDialogContent>
                                                        <ContextMenuItem
                                                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5 p-2"
                                                            onSelect={(e) => handleEnableWorkflow(e, workflow)}
                                                        >
                                                            {workflow.enabled ? (
                                                                <>
                                                                    <CrossCircledIcon className="w-3.5 h-3.5" />
                                                                    Disable
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <CheckCircledIcon className="w-3.5 h-3.5" />
                                                                    Enable
                                                                </>
                                                            )}
                                                        </ContextMenuItem>
                                                    </AlertDialog>
                                                </ContextMenuContent>
                                            </ContextMenu>
                                        </Card>
                                    ))}
                                </ResizablePanel>
                                {workflowSelected !== undefined && (
                                    <>
                                        <ResizableHandle />
                                        <ResizablePanel
                                            defaultSize={
                                                window.innerWidth * 0.65
                                            }
                                            key="ticketInfo"
                                            className="flex flex-col h-full mx-3"
                                        >
                                            <WorkflowDisplay
                                                workflow={workflowSelected}
                                            />
                                        </ResizablePanel>
                                    </>
                                )}
                            </ResizablePanelGroup>
                        </TooltipProvider>
                    </div>
                )}
                {isError && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                <AssemblyToastProvider
                    open={toastOpen}
                    toastSymbol={ToastSymbol}
                    toastText={toastText}
                    setOpen={setToastOpen}
                />
            </Box>
        </div>
    );
};
export default WorkflowsPage;
