import { Button } from "@/component/shadcn/ui/button";
import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    GetUserResponse,
    ListAccountsResponse,
    Teams,
} from "@/interfaces/serverData";
import { AccountsDataTable } from "@/pages/WorkspacePreferences/DataTable/accounts-data-table";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import {
    CheckCircledIcon,
    InfoCircledIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Callout,
    DropdownMenu,
    Flex,
    Heading,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import {
    useInfiniteQuery,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import type { Row } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountPopup from "./AccountPopup";
import { generateCustomerColumms } from "./CustomersTable/columns";
import { getBadgeForTeam, getNavLink } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";

export enum AccountsListType {
    Accounts = "accounts",
    Inbox = "inbox",
    Team = "team",
}

export interface AccountsProps {
    orgID: string;
    userID: string;
    listType: AccountsListType;
}

export const AccountsPage = ({ orgID, userID, listType }: AccountsProps) => {
    const teamID = window.location.pathname.split("/")[2] || "";
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const [loadingState, setLoadingState] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    const fetchAccounts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListAccountsResponse> => {
        try {
            const { url, method } = ContactsAPI.listAccounts;
            const response = await api.get(`${URLS.serverUrl}${url}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 100,
                    offset: pageParam,
                },
            });
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const fetchTeamAccounts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListAccountsResponse> => {
        if (!teamID) {
            console.error("Team ID not found in URL");
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
        try {
            const { url, method } = ContactsAPI.listAccounts;
            const response = await api.get(
                `${URLS.serverUrl}${url}/team/${teamID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const fetchMyAccounts = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<ListAccountsResponse> => {
        if (!userID) {
            console.error("User ID not found");
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
        try {
            const { url, method } = ContactsAPI.listAccounts;
            const response = await api.get(
                `${URLS.serverUrl}${url}/assignee/${userID}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const accountQueryConfig = {
        [AccountsListType.Accounts]: {
            queryKey: ["accounts"],
            queryFn: fetchAccounts,
        },
        [AccountsListType.Team]: {
            queryKey: [`teamAccounts_${teamID}`],
            queryFn: fetchTeamAccounts,
        },
        [AccountsListType.Inbox]: {
            queryKey: ["myAccounts"],
            queryFn: fetchMyAccounts,
        },
    };

    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
        useInfiniteQuery({
            queryKey: accountQueryConfig[listType]?.queryKey,
            queryFn: accountQueryConfig[listType]?.queryFn,
            getNextPageParam: (lastPage) => {
                if (lastPage?.has_next_page) {
                    return lastPage.next_cursor;
                }
                return undefined; // No more pages
            },
            initialPageParam: pageIndex,
            refetchInterval: 30000,
            refetchOnWindowFocus: true,
        });

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
            setPageIndex(pageIndex + 1);
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const api = useApi();

    const updateData = async () => {
        if (teamID) {
            queryClient.refetchQueries({
                queryKey: [`teamAccounts_${teamID}`],
                exact: true,
            });
        } else {
            queryClient.refetchQueries({
                queryKey: ["accounts"],
                exact: true,
            });
        }
    };

    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const [rowState, setRowState] = useState<Map<string, Account>>(new Map());

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const handleRowClick = (row: Row<Account>) => {
        navigate(getNavLink(row.original, listType, teamID));
    };

    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">
                <div className="flex items-start justify-between">
                    <Flex
                        align="start"
                        direction="column"
                        justify={"start"}
                        style={{ paddingLeft: "20px", paddingBottom: "20px" }}
                    >
                        <Heading size="5" align="left" className="flex items-center gap-2">
                            Accounts
                            {
                                // For Team view
                                listType === AccountsListType.Team && teamID && (
                                    <TeamBadges
                                        teams={(teamsQuery.data ?? []).filter(team => team.id === teamID)}
                                        defaultIsWorkspace={false}
                                    />
                                )
                                // For regular view
                                || (listType === AccountsListType.Accounts && getBadgeForTeam("General"))
                            }
                        </Heading>
                        <div className="flex flex-row gap-2 items-start">
                            <Text mb="12px" size="2">
                                Manage external accounts you stay in touch with
                            </Text>
                            <Badge size="1" color="green">
                                BETA
                            </Badge>
                        </div>
                        <Callout.Root size="1">
                            <Callout.Text className="flex flex-row gap-2 items-center">
                                <InfoCircledIcon />
                                <Text className="text-xs">
                                    This feature currently represents new
                                    accounts added to Assembly after September
                                    25th, 2024.
                                </Text>
                            </Callout.Text>
                        </Callout.Root>
                    </Flex>
                    <div className="flex items-center mr-5">
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                                <Button
                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                    size="sm"
                                    variant="outline"
                                >
                                    Add
                                    <PlusIcon />
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Company
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    setToastOpen={setToastOpen}
                                    setToastSymbol={setToastSymbol}
                                    setToastText={setToastText}
                                    type="Company"
                                    updateData={updateData}
                                    teamsQuery={teamsQuery}
                                    usersQuery={usersQuery}
                                    userID={userID}
                                    orgID={orgID}
                                    listType={listType}
                                />
                                <AccountPopup
                                    triggerElement={
                                        <DropdownMenu.Item
                                            onSelect={(e) => e.preventDefault()}
                                        >
                                            Customer
                                        </DropdownMenu.Item>
                                    }
                                    editing={false}
                                    setToastOpen={setToastOpen}
                                    setToastSymbol={setToastSymbol}
                                    setToastText={setToastText}
                                    type="Customer"
                                    updateData={updateData}
                                    teamsQuery={teamsQuery}
                                    usersQuery={usersQuery}
                                    userID={userID}
                                    orgID={orgID}
                                    listType={listType}
                                />
                            </DropdownMenu.Content>
                        </DropdownMenu.Root>
                    </div>
                </div>

                {loadingState === 0 && (
                    <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                        <Text>
                            <Skeleton maxWidth="85%">
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Skeleton>
                        </Text>
                    </Flex>
                )}
                {loadingState === 2 && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}

                <AssemblyToastProvider
                    open={toastOpen}
                    toastSymbol={ToastSymbol}
                    toastText={toastText}
                    setOpen={setToastOpen}
                />
                {combinedData && (
                    <div className="flex flex-col gap-2 mx-5">
                        <AccountsDataTable<Account, string>
                            columns={generateCustomerColumms(
                                rowState,
                                usersQuery.data ?? [],
                            )}
                            data={combinedData}
                            handleRowClick={handleRowClick}
                            pageIndex={pageIndex}
                            setPageIndex={setPageIndex}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            updateData={updateData}
                            setToastText={setToastText}
                            setToastOpen={setToastOpen}
                            setToastSymbol={setToastSymbol}
                            teamsQuery={teamsQuery}
                            usersQuery={usersQuery}
                            userID={userID}
                            orgID={orgID}
                            listType={listType}
                        />
                    </div>
                )}
            </Box>
        </Flex>
    );
};
