import { Card, CardContent, CardHeader, CardTitle } from "@/component/shadcn/ui/card";
import { Badge } from "@radix-ui/themes";
import { Handle, Position } from '@xyflow/react';
import { TargetIcon } from 'lucide-react';
import { TriggerIcon, handleStyle } from '../Icons';

import { Input } from "@/component/shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import { useState } from "react";
import { Workflow } from "@/interfaces/serverData";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const TriggerNode: React.FC<{ data: any; isConnectable: boolean; onUpdate: (id: string, metadata: any) => void, workflow?: Workflow }> = ({ data, isConnectable, onUpdate, workflow }) => {
  const [triggerType, setTriggerType] = useState<string>(data.metadata?.type ?? (workflow ? undefined : "issue_created"));
  const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");

  // Update node's metadata
  const handleSelectChange = (value: string) => {
    console.log("in handle select chabge with value", value, "and data id", data.id)
    const updatedMetadata = { ...data.metadata, type: value };
    onUpdate(data.id, updatedMetadata);  // Update the metadata with selected value
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedMetadata = { ...data.metadata, name: e.target.value };
    onUpdate(data.id, updatedMetadata);  // Update the metadata of the node
    setNameVal(e.target.value);
  };

  return (
    <div className="flex flex-col items-start">
      <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
        <div className="flex flex-row items-center justify-center gap-1">
          <TargetIcon className="text-[#5e6ad2]" strokeWidth={1.5} size={12} />
          <p className="text-xs">Trigger</p>
        </div>
      </Badge>
      <Card className="w-[450px] shadow-none border rounded-tr-lg rounded-bl-lg rounded-br-lg z-10">
        <CardHeader className="flex flex-row items-center justify-between space-y-0">
          <div className="flex items-center space-x-2">
            <TriggerIcon />
            <CardTitle >
              <div className="flex items-center gap-2">
                <p className="text-xs font-medium">When:</p>
                <Select defaultValue={triggerType} onValueChange={handleSelectChange}>
                  <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="issue_created">Issue Created</SelectItem>
                    <SelectItem value="tag_updated">Tag Updated</SelectItem>
                    <SelectItem value="status_updated">Status Updated</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </CardTitle>
          </div>
        </CardHeader>

        <CardContent className="flex flex-col gap-2">
          <Separator />
          <Input
            className="text-xs border-none px-0"
            placeholder="Name..."
            value={nameVal}
            onChange={handleNameChange}
          />
        </CardContent>

        <Handle
          type="source"
          position={Position.Top}
          id="a"
          isConnectable={isConnectable}
          className={`${handleStyle} top-[18px]`}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
          className={handleStyle}
        />
      </Card>
    </div>
  );
}

export default TriggerNode;