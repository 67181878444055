import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import { Input } from '@/component/shadcn/ui/input';
import { Separator } from '@/component/shadcn/ui/separator';
import { Badge } from "@radix-ui/themes";
import { Handle, Position } from '@xyflow/react';
import { WaitIcon } from '../Icons';
import { handleStyle } from '../Icons';

import { TimerIcon } from 'lucide-react';

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/component/shadcn/ui/select";
import { useState } from 'react';
import { Workflow } from "@/interfaces/serverData";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const WaitNode: React.FC<{ data: any; isConnectable: boolean; onUpdate: (id: string, metadata: any) => void, workflow?: Workflow }> = ({ data, isConnectable, onUpdate, workflow }) => {
  const [timeUnit, setTimeUnit] = useState<string>(data.metadata?.unit ?? (workflow ? undefined : "days"));
  const [timeInput, setTimeInput] = useState<number>(data.metadata?.time ?? (workflow ? undefined : 30));

  // Update node's metadata
  const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeInput(Number(e.target.value))
    const updatedMetadata = { ...data.metadata, time: e.target.value };
    onUpdate(data.id, updatedMetadata);
  };
  const handleTimeUnit = (value: string) => {
    const updatedMetadata = { ...data.metadata, unit: value };
    onUpdate(data.id, updatedMetadata);
  };

  return (
    <div className="flex flex-col items-start">
      <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
        <div className="flex flex-row items-center justify-center gap-1">
          <TimerIcon className=" text-[#5e6ad2]" strokeWidth={1.5} size={12} />
          <p className="text-xs">Wait</p>
        </div>
      </Badge>
      <Card className="w-[450px] shadow-none border rounded-tr-lg rounded-bl-lg rounded-br-lg z-10">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex items-center flex-row space-x-2 w-full">
            <WaitIcon />
            <p className="text-xs">Wait for:</p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div className="flex items-center border border-[#D8D8D8] rounded-md bg-white h-9 w-fit">
              <Input
                type="number"
                value={timeInput}
                onChange={handleTimeInput}
                className={"px-3 w-[5rem] max-w-full text-center bg-transparent border-none outline-none focus:outline-none focus:ring-0"}
                min="1"
              />

              <Separator
                orientation="vertical"
                className="bg-[#D8D8D8] w-[1px] h-8"
              />

              <Select
                defaultValue={timeUnit} onValueChange={handleTimeUnit}
              >
                <SelectTrigger className="w-full focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="days">Days</SelectItem>
                  <SelectItem value="minutes">Minutes</SelectItem>
                  <SelectItem value="seconds">Seconds</SelectItem>
                </SelectContent>
              </Select>
            </div>


          </div>
        </CardHeader>

        <CardContent>

        </CardContent>

        <Handle
          type="source"
          position={Position.Top}
          id="a"
          isConnectable={isConnectable}
          className={`${handleStyle} top-[18px]`}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
          className={handleStyle}
        />
      </Card>
    </div>
  );
}

export default WaitNode;