import { GitHubLogoIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import {
    Box,
    Callout,
    Card,
    Flex,
    Grid,
    Heading,
    Separator,
    Skeleton,
    Text,
    Tooltip,
} from "@radix-ui/themes";
import {
    Card as ShadcnCard,
    CardDescription,
    CardTitle,
} from "@/component/shadcn/ui/card";
import { Button } from "@/component/shadcn/ui/button";
import type React from "react";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, ASSEMBLY_COOKIES, URLS, loadingTypes } from "../../constant";
import { ReactComponent as ConfluenceSvg } from "../../images/integrations/confluence.svg";
import { ReactComponent as DiscordSvg } from "../../images/integrations/discord.svg";
import { ReactComponent as GmailSvg } from "../../images/integrations/gmail.svg";
import { ReactComponent as HubSpotSvg } from "../../images/integrations/hubspot.svg";
import { ReactComponent as IntercomSvg } from "../../images/integrations/intercom.svg";
import { ReactComponent as JiraSvg } from "../../images/integrations/jira.svg";
import { ReactComponent as LinearSvg } from "../../images/integrations/linear1.svg";
import { ReactComponent as NotionSvg } from "../../images/integrations/notion.svg";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { ReactComponent as WebSvg } from "../../images/integrations/web.svg";
import { useApi } from "../../interfaces/api";
import { setCookie } from "../../utilities/CookieManagement";
import { JiraCardComponent } from "./Integrations/JiraCardComponent";
import { managementIntegrationList, INTEGRATION_PAGE_IGNORE } from "./constant";
import { readableIntegrationMapping } from "./constant";
import { KeyIcon } from "lucide-react";

interface IntegrationRowProps {
    name: string;
    SvgIcon: React.ElementType;

    activated: boolean;
    addChannels?: () => void;
    codeActivated?: boolean;
    description?: string;
}

const IntegrationRow = (props: IntegrationRowProps) => {
    const [activated, setActivatedState] = useState<boolean>(false);
    const [connectedState, setConnectedState] = useState<string>(
        props.description ?? "",
    );

    const [toolTipEnabled, setTooltipEnabled] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (props.name === "PullRequest") {
            if (!props.codeActivated) {
                setConnectedState(props.description ?? "");
                setActivatedState(true);
                setTooltipEnabled(true);
            } else {
                if (props.activated) {
                    setConnectedState("Enabled");
                } else {
                    setConnectedState(props.description ?? "");
                }
                setActivatedState(props.activated);
            }
        } else {
            if (props.activated === true) {
                setConnectedState("Enabled");
            } else {
                setConnectedState(props.description ?? "");
            }
            setActivatedState(props.activated);
        }
    }, [props.activated, props.codeActivated, props.description, props.name]);

    function getLink(name: string): string {
        return `/admin/manage_integrations/${name.toLowerCase()}`;
    }

    function getName(name: string): string {
        if (name === "GitHubTicket") {
            // Github Issues and Discussions are coupled into the same management page
            return "Github Issues/Discussions";
        }
        return readableIntegrationMapping.get(name) ?? name;
    }

    return (
        <div>
            <Box width={{ sm: "160px", md: "190px", lg: "210px", xl: "250px" }}>
                {toolTipEnabled ? (
                    <Tooltip content="Please connect to Code first to enable this integration!">
                        <Card size="1" asChild style={{ minHeight: "65px" }}>
                            <a
                                href={getLink(props.name)}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Flex gap="3" align="start" direction={"row"}>
                                    <props.SvgIcon
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            marginTop: "2px",
                                        }}
                                    />
                                    <Flex
                                        direction={"column"}
                                        width={{
                                            sm: "120px",
                                            md: "150px",
                                            lg: "170px",
                                            xl: "210px",
                                        }}
                                    >
                                        <Text as="div" size="2" weight="bold">
                                            {getName(props.name)}
                                        </Text>
                                        <Text
                                            wrap="pretty"
                                            as="div"
                                            size="2"
                                            color="gray"
                                        >
                                            {connectedState}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </a>
                        </Card>
                    </Tooltip>
                ) : (
                    <Card size="1" asChild style={{ minHeight: "65px" }}>
                        <a href={getLink(props.name)}>
                            <Flex gap="3" align="start" direction={"row"}>
                                <props.SvgIcon
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        marginTop: "2px",
                                    }}
                                />
                                <Flex
                                    direction={"column"}
                                    width={{
                                        sm: "120px",
                                        md: "150px",
                                        lg: "170px",
                                        xl: "210px",
                                    }}
                                >
                                    <Text as="div" size="2" weight="bold">
                                        {getName(props.name)}
                                    </Text>
                                    <Text
                                        wrap="pretty"
                                        as="div"
                                        size="2"
                                        color="gray"
                                    >
                                        {connectedState}
                                    </Text>
                                </Flex>
                            </Flex>
                        </a>
                    </Card>
                )}
            </Box>
        </div>
    );
};

export interface metadata {
    scopes: string[];
    indexedOn: string;
    slack_team_id?: string;
}

const IntegrationsManagementPage = () => {
    const api = useApi();

    const [disabledIntegrations, setDisabledIntegrations] = useState<string[]>(
        [],
    );
    const [enabledIntegrations, setEnabledIntegrations] = useState<string[]>(
        [],
    );

    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useLayoutEffect(() => {
        let integrations: string[] = [];
        api.get(URLS.serverUrl + API.getIntegrations, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    integrations = res.data.data ?? [];
                    // some integrations are tightly coupled with others (e.g. Github Discussions and Github Issues)
                    // and do not warrant another UI subpage, so ignore those here
                    integrations = integrations.filter(
                        (integration: string) =>
                            !INTEGRATION_PAGE_IGNORE.includes(integration),
                    );
                    console.log(integrations);
                    setEnabledIntegrations(integrations);
                    const disabled = managementIntegrationList.filter(
                        (integration) => !integrations.includes(integration),
                    );

                    setDisabledIntegrations(disabled);
                    setLoadingState(loadingTypes.loaded);
                }
            })
            .catch((res) => {
                console.log("System is down.");
                setLoadingState(loadingTypes.error);
            });
    }, []);

    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const isIntegrationEnabled = (integrationName: string) => {
        return enabledIntegrations?.includes(integrationName);
    };

    const addMoreRepositories = () => {
        navigate("/admin/integrations/add/code");
    };

    const navigate = useNavigate();

    const handleManageClick = () => {
        navigate("/preferences/apikeys");
    };

    const slackAddChannels = () => {
        navigate("/admin/integrations/add/slack");
    };

    const jiraPopupIndex = (integrationType: string, jiraURL: string) => {
        setCookie(ASSEMBLY_COOKIES.jira_url, jiraURL);
        const url = process.env.REACT_APP_JIRA_URL;
        window.open(url, "_self");
    };

    interface IntegrationConfig {
        SvgIcon: React.ComponentType;
        addChannels?: () => void;
        description: string;
    }

    const integrationConfig: Record<string, IntegrationConfig> = {
        Code: {
            SvgIcon: GitHubLogoIcon,
            addChannels: addMoreRepositories,
            description:
                "Search through any repositories of your choice directly within Assembly",
        },
        PullRequest: {
            SvgIcon: GitHubLogoIcon,
            description:
                "Ingest and search through existing GitHub PRs directly within Assembly",
        },
        HubSpot: {
            SvgIcon: HubSpotSvg,
            description:
                "Connect your HubSpot account to see customer data in Assembly.",
        },
        Jira: {
            SvgIcon: JiraSvg,
            description:
                "Auto-generate or search through all Jira tickets from Slack or Discord",
        },
        Confluence: {
            SvgIcon: ConfluenceSvg,
            description:
                "Automatically search through and answer questions about your Confluence knowledge base",
        },
        Linear: {
            SvgIcon: LinearSvg,
            description:
                "Generate or search through all Linear tickets from Slack or Discord",
        },
        Slack: {
            SvgIcon: SlackSvg,
            addChannels: slackAddChannels,
            description:
                "Stay in sync with all customer bugs/issues, or feature request threads",
        },
        Google: {
            SvgIcon: GmailSvg,
            description:
                "Let Assembly ingest emails sent to your customer support email address",
        },
        Notion: {
            SvgIcon: NotionSvg,
            description:
                "Automatically search through and answer questions about your Notion knowledge base",
        },
        Web: {
            SvgIcon: WebSvg,
            description:
                "Automatically search through and answer questions about your web docs",
        },
        Discord: {
            SvgIcon: DiscordSvg,
            description:
                "Stay up to date and interact with feature requests, bugs, and issues",
        },
        GitHubTicket: {
            SvgIcon: GitHubLogoIcon,
            description:
                "Automatically create issues, tickets, or projects from your communication channels",
        },
        ChatWidget: {
            SvgIcon: PaperPlaneIcon,
            description:
                "Turn your content into an AI search and support copilot with in-app embedded chat",
        },
        CommunitySlack: {
            SvgIcon: SlackSvg,
            description:
                "Stay in sync with your community's bugs/issues, or feature request threads",
        },
        Intercom: {
            SvgIcon: IntercomSvg,
            description: "Stay in sync with all intercom conversations",
        },
        API: {
            SvgIcon: KeyIcon,
            description: "Manage your API keys",
        },
    };

    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box
                    mt="7"
                    height="100%" // Ensure it takes full height of its container
                >
                    <Flex align="start" direction="column" justify={"start"}>
                        <Heading size="5" align="left">
                            Manage Integrations
                        </Heading>

                        <Text mb="15px" size="2">
                            See all the integrations you have enabled, and those
                            available.
                        </Text>
                    </Flex>

                    <JiraCardComponent
                        index={jiraPopupIndex}
                        popupOpen={popupOpen}
                        setPopupOpen={setPopupOpen}
                    />

                    {loadingState === 0 && (
                        <Flex
                            maxWidth={{
                                sm: "400px",
                                md: "620px",
                                lg: "720px",
                            }}
                        >
                            <Text>
                                <Skeleton
                                    maxWidth={{
                                        sm: "400px",
                                        md: "600px",
                                        lg: "700px",
                                    }}
                                >
                                    {[...Array(6)].map((_, index) => (
                                        <Text key={null}>{loremIpsum}</Text>
                                    ))}
                                </Skeleton>
                            </Text>
                        </Flex>
                    )}
                    {loadingState === 1 && (
                        <Flex direction={"column"} align={"center"} gap="2">
                            {enabledIntegrations.length !== 0 && (
                                <Flex
                                    direction={"column"}
                                    align={"start"}
                                    style={{ margin: "0px", width: "100%" }}
                                    gap="4"
                                >
                                    <Text color="gray">Enabled</Text>
                                    <Grid
                                        columns={{ xs: "1", sm: "2", md: "3" }}
                                        gap={{ xs: "2", sm: "3", md: "5" }}
                                        style={{
                                            margin: "0px",
                                            width: "100%",
                                            gridAutoRows: "80px",
                                        }}
                                    >
                                        {enabledIntegrations
                                            .filter(
                                                (integration) =>
                                                    integrationConfig[
                                                        integration
                                                    ],
                                            )
                                            .map((integration) => (
                                                <IntegrationRow
                                                    key={integration}
                                                    name={integration}
                                                    SvgIcon={
                                                        integrationConfig[
                                                            integration
                                                        ]?.SvgIcon
                                                    }
                                                    activated={isIntegrationEnabled(
                                                        integration,
                                                    )}
                                                    addChannels={
                                                        integrationConfig[
                                                            integration
                                                        ]?.addChannels
                                                    }
                                                    codeActivated={isIntegrationEnabled(
                                                        "Code",
                                                    )}
                                                />
                                            ))}
                                    </Grid>
                                </Flex>
                            )}
                            {enabledIntegrations.length !== 0 && (
                                <Separator size="4" />
                            )}
                            <Flex
                                direction={"column"}
                                align={"start"}
                                style={{ margin: "0px", width: "100%" }}
                                gap="4"
                            >
                                <Text color="gray">Available</Text>
                                <Grid
                                    columns={{ xs: "1", sm: "2", md: "3" }}
                                    gap={{ xs: "2", sm: "3", md: "5" }}
                                    style={{
                                        margin: "0px",
                                        width: "100%",
                                        gridAutoRows: "140px",
                                    }}
                                >
                                    {disabledIntegrations.map((integration) => (
                                        <IntegrationRow
                                            key={integration}
                                            name={integration}
                                            SvgIcon={
                                                integrationConfig[integration]
                                                    ?.SvgIcon
                                            }
                                            activated={isIntegrationEnabled(
                                                integration,
                                            )}
                                            addChannels={
                                                integrationConfig[integration]
                                                    .addChannels
                                            }
                                            description={
                                                integrationConfig[integration]
                                                    .description
                                            }
                                            codeActivated={isIntegrationEnabled(
                                                "Code",
                                            )}
                                        />
                                    ))}
                                </Grid>
                            </Flex>

                        </Flex>
                    )}

                    {loadingState === 2 && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                </Box>
            </Flex>
        </div>
    );
};

export default IntegrationsManagementPage;
