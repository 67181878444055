import { Card, CardContent, CardHeader, CardTitle } from "@/component/shadcn/ui/card";
import { Badge } from "@radix-ui/themes";
import { Handle, Position } from '@xyflow/react';
import { HouseIcon, PlayIcon, TrashIcon, UsersIcon } from 'lucide-react';
import { ActionsIcon } from '../Icons';

import { Input } from "@/component/shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from '@/component/shadcn/ui/separator';
import { handleStyle } from '../Icons';
import { useRef, useState } from "react";
import { Category, CustomerGroup, GetTopicsResponse, GetUserResponse, Teams, Workflow } from "@/interfaces/serverData";
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { AvatarIcon, CaretSortIcon, ComponentBooleanIcon, DotFilledIcon, GroupIcon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import { API, TeamsAPI, URLS } from "@/constant";
import { useAuthInfo } from "@propelauth/react";
import { useApi } from "@/interfaces/api";
import { getTopicColors } from "@/utilities/methods";
import { Button } from "@/component/shadcn/ui/button";

// const DropdownTrigger = (type: string, detail: { label: string; value: string; key: string; color: string }, users: GetUserResponse[]) => {
//   switch (type) {
//     case "Assignee": {
//       const pictureURL = users.find(
//         (user) => user.id === detail.key,
//       )?.picture_url;
//       return (
//         <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
//           <div className="flex items-center gap-1.5">
//             {detail && (pictureURL ? (
//               <img
//                 className="lb-avatar-image"
//                 src={pictureURL}
//                 alt={detail.label}
//               />
//             ) : (
//               <AvatarIcon className="w-6 h-6" />
//             ))}
//             {detail.label}
//           </div>
//           <CaretSortIcon />
//         </div>
//       );
//     }
//     case "Topic": {
//       return (
//         <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
//           {detail && <Badge
//             color="gray"
//             size="2"
//             radius="full"
//             variant="outline"
//             className="m-0 rounded-xl py-1 px-2"
//           >
//             <div className="flex flex-row items-center">
//               <DotFilledIcon
//                 color={detail?.color !== "" ? detail.color : "#9B9EF0"}
//                 style={{
//                   transform: "scale(1.8)",
//                 }}
//               />
//               <p className="pl-0.3">{detail.label}</p>
//             </div>
//           </Badge>
//           }
//           <CaretSortIcon />
//         </div>
//       )
//     }
//     case "Tag": {
//       return (
//         <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
//           {detail.value !== "Select interaction type" ? <Badge
//             color="gray"
//             size="2"
//             radius="full"
//             variant="outline"
//             className="m-0 rounded-xl py-1 px-2"
//           >
//             <div className="flex flex-row items-center">
//               <ComponentBooleanIcon
//                 color={
//                   detail?.color !== "" ? detail.color : "gray"
//                 }
//               />
//               <p className="pl-0.3">{detail.label}</p>
//             </div>
//           </Badge> :
//             <p>{detail.label}</p>}
//           <CaretSortIcon />
//         </div>
//       )
//     }
//     case "Team": {
//       return (
//         <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
//           <div className="flex items-center gap-1.5">
//             {detail.value !== "Select team" && (detail.value === "General" ? (
//               <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
//                 <HouseIcon
//                   className="text-iris9"
//                   strokeWidth={1.5}
//                   size={10}
//                 />
//               </div>
//             ) : (
//               <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
//                 <UsersIcon
//                   className="text-red9"
//                   strokeWidth={1.5}
//                   size={10}
//                 />
//               </div>
//             ))}
//             {detail.label}
//           </div>
//           <CaretSortIcon />
//         </div>
//       );
//     }
//   }
// };

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const ActionNode: React.FC<{ data: any; isConnectable: boolean; onUpdate: (id: string, metadata: any) => void, workflow?: Workflow }> = ({ data, isConnectable, onUpdate, workflow }) => {
  const api = useApi()
  const [actionType, setActionType] = useState<string>(data.metadata?.subtype ?? (workflow ? undefined : "send_template"));
  const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
  const [actionDetail, setActionDetail] = useState<{ label: string; value: string; key: string; color: string }>(data.metadata?.detail ?? new Map());

  // Update node's metadata
  const handleSelectChange = (value: string) => {
    const updatedMetadata = { ...data.metadata, subtype: value };
    onUpdate(data.id, updatedMetadata);  // Update the metadata with selected value
  };
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedMetadata = { ...data.metadata, name: e.target.value };
    onUpdate(data.id, updatedMetadata);  // Update the metadata of the node
    setNameVal(e.target.value);
  };

  // TODO: figure out how to change the selected one
  const handleItemSelect = (type: string, option: { label: string; value: string; key: string; color: string }) => () => {


  };

  const authInfo = useAuthInfo();
  const authInfoRef = useRef(authInfo);
  const usersQuery = useQuery<GetUserResponse[]>({
    queryKey: ["users"],
    queryFn: async () => {
      const res = await fetch(URLS.serverUrl + API.getAllUsers, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authInfoRef.current.accessToken}`,
        },
      });

      const data = await res.json();
      return data.data;
    },
  });
  const customerGroupsQuery = useQuery<CustomerGroup[]>({
    queryKey: ["customers"],
    queryFn: async () => {
      const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authInfoRef.current.accessToken}`,
        },
      });

      const data = await res.json();
      const customerGroups: CustomerGroup[] = data.data;
      return customerGroups;
    },
  });
  const teamsQuery = useQuery<Teams[]>({
    queryKey: ["teams"],
    queryFn: async () => {
      const [url, method] = TeamsAPI.listMemberTeams;
      const response = await fetch(
        `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
        {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
          },
        },
      );
      const d = await response.json();
      return d.data;
    },
  });

  const categoryOptionsQuery = useQuery<Category[]>({
    queryKey: ["categories"],
    queryFn: async () => {
      const response = await api.get(
        `${URLS.serverUrl}${API.getCategories}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      if (response.status === 200) {
        return response.data.data;
      }
      return [];
    },
  });

  const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
    const response = await api.get(URLS.serverUrl + API.getTopics, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };

  const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
    const response = await api.get(
      `${URLS.serverUrl}${API.getTopics}/team/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };

  // TODO: pull in team id
  const id = undefined
  const topicsQuery = useQuery<GetTopicsResponse[]>({
    queryKey: id ? [`teamTopics_${id}`] : ["topics"],
    queryFn: id ? () => fetchTeamTopics() : () => fetchTopics(),
  });

  return (
    <div className="flex flex-col items-start">
      <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
        <div className="flex flex-row items-center justify-center gap-1">
          <PlayIcon className="text-[#5e6ad2] h-3 w-3" />
          <p className="text-xs">Action</p>
        </div>
      </Badge>
      <Card className="w-[450px] shadow-none border rounded-lg z-10">
        <CardHeader className="flex flex-row items-center justify-between space-y-0">
          <div className="flex items-center space-x-2">
            <ActionsIcon />
            <CardTitle >
              <div className="flex items-center gap-2">
                <p className="text-xs font-medium flex-shrink-0">
                  Then:
                </p>
                <Select defaultValue={actionType} onValueChange={handleSelectChange}>
                  <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="send_template">Send a Template</SelectItem>
                    <SelectItem value="send_message">Send a Message</SelectItem>
                    <SelectItem value="add_assignee">Add an Assignee</SelectItem>
                    <SelectItem value="add_label">Add a Label</SelectItem>
                  </SelectContent>
                </Select>

                {/* {actionType !== "" && (
                  <>
                    <p className="text-xs font-medium">: </p>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild type="button">
                        {DropdownTrigger(actionType, actionDetail, usersQuery.data ?? [])}
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        align="start"
                        className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                      >
                        <FilterDropdownElement
                          type={actionType}
                          categories={categoryOptionsQuery.data ?? []}
                          filters={new Map()}
                          handleItemSelect={handleItemSelect}
                          topics={getTopicColors(
                            topicsQuery.data ?? [],
                          )}
                          users={[]}
                          customerGroups={customerGroupsQuery.data ?? []}
                          teams={teamsQuery.data ?? []}
                          isSavedViewFilter={false}
                          channels={new Map()}
                        />
                      </DropdownMenuContent>
                    </DropdownMenu>

                    <Button
                      variant="outline"
                      className="px-1.5"
                    // onClick={() => handleDeleteFilter(filterType.type, index)}
                    >
                      <TrashIcon className="w-3.5 h-3.5" />
                    </Button>
                  </>
                )} */}
              </div>
            </CardTitle>
          </div>
        </CardHeader>

        <CardContent className="flex flex-col gap-2">
          <Separator />
          <Input
            className="text-xs border-none px-0"
            placeholder="Name..."
            value={nameVal}
            onChange={handleNameChange}
          />
        </CardContent>

        <Handle
          type="source"
          position={Position.Top}
          id="a"
          isConnectable={isConnectable}
          className={`${handleStyle}`}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
          className={handleStyle}
        />
      </Card>
    </div>
  );
}

export default ActionNode;