import { Component } from "react";
import { Badge } from "@radix-ui/themes";
import { ExclamationTriangleIcon, LapTimerIcon } from "@radix-ui/react-icons";
import { API, URLS } from "@/constant";
import type {
    GetTimeLeftWithinBusinessHoursPayload,
    GetTimeLeftWithinBusinessHoursResponse,
} from "@/interfaces/serverData";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { AxiosInstance } from "axios";
import { PauseIcon } from "lucide-react";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "./shadcn/ui/tooltip";

export type BadgeType = (typeof badgePropDefs.variant.values)[number];

interface ClockProps {
    targetTime: string;
    variant: BadgeType;
    api: AxiosInstance;
    businessHoursID?: string;
}

interface ClockState {
    fixDate: Date;
    diff: number;
    paused: boolean;
    whenRestart: Date;
}

class Clock extends Component<ClockProps, ClockState> {
    intervalId: NodeJS.Timeout | null = null;

    constructor(props: ClockProps) {
        super(props);

        const { targetTime } = this.props;
        const fixDate = new Date(targetTime);
        const currDate = new Date();
        const diff = fixDate.getTime() - currDate.getTime();

        this.state = {
            fixDate,
            diff: diff,
            paused: false,
            whenRestart: currDate,
        };

        if (diff > 0) {
            this.timeLeftWithinBusinessHours();
        }
    }

    componentDidMount() {
        this.intervalId = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    tick() {
        const { diff } = this.state;
        if (diff > 0) {
            this.timeLeftWithinBusinessHours();
        } else {
            this.setState((prevState) => ({
                diff: prevState.fixDate.getTime() - new Date().getTime(),
            }));
        }
    }

    async timeLeftWithinBusinessHours(): Promise<void> {
        const requestData: GetTimeLeftWithinBusinessHoursPayload = {
            target_time: this.props.targetTime,
            business_hours_id: this.props.businessHoursID ?? "",
        };

        try {
            const response = await this.props.api.post(
                URLS.serverUrl + API.getTimeLeftWithinBusinessHours,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (response.status === 200 && response.data.data) {
                const res: GetTimeLeftWithinBusinessHoursResponse =
                    response.data.data;
                const restartDate = res.when_restart
                    ? new Date(res.when_restart)
                    : new Date();
                this.setState({
                    diff: res.time_left_seconds * 1000,
                    paused: res.paused,
                    whenRestart: restartDate,
                });
            }
        } catch (error) {
            console.error("Error fetching business hours:", error);
        }
    }

    getDisplayTime() {
        const { diff } = this.state;
        if (diff === 0) {
            return "now";
        } else if (diff < -100) {
            return "breached!";
        }

        const hours = Math.floor(diff / (60 * 60 * 1000));
        const mins = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
        const secs = Math.floor((diff % (60 * 1000)) / 1000);

        if (hours > 0) {
            return `${hours} hrs ${mins} mins`;
        } else if (mins > 0) {
            return `${mins} mins`;
        } else if (secs > 30) {
            return "< 1 min";
        } else {
            return "< 30 secs";
        }
    }

    render() {
        const { diff } = this.state;
        const displayTime = this.getDisplayTime();

        const timeOptions: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        const dateOptions: Intl.DateTimeFormatOptions = {
            weekday: "long",
            year: "numeric",
            month: "short",
            day: "numeric",
        };

        if (diff < 100) {
            return (
                <Badge
                    color="red"
                    size="2"
                    radius="full"
                    variant={this.props.variant}
                    className="py-1.5 mx-1"
                >
                    <ExclamationTriangleIcon />{" "}
                    {/* Different symbol for breached status */}
                    <span>{displayTime}</span>
                </Badge>
            );
        } else {
            // If not breached, show the default badge
            return (
                <div className="flex items-center gap-2">
                    {this.state.paused && (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Badge
                                        color="iris"
                                        size="2"
                                        radius="full"
                                        variant="soft"
                                        className="py-1"
                                    >
                                        <PauseIcon className="w-4 h-4" />
                                    </Badge>
                                </TooltipTrigger>
                                <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col">
                                    <div className="text-sm font-semibold">
                                        Business Hours are Paused
                                    </div>
                                    <p>{`Hours will open at ${this.state.whenRestart.toLocaleTimeString(undefined, timeOptions)} on ${this.state.whenRestart.toLocaleDateString(undefined, dateOptions)}`}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )}
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        className="bg-gray py-1.5"
                    >
                        <LapTimerIcon />
                        <span>{displayTime}</span>
                    </Badge>
                </div>
            );
        }
    }
}

export default Clock;
