import { Card } from "@/component/shadcn/ui/card";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    GetTopicsResponse,
    GetUserResponse,
    Insight,
    InsightInfoResponse,
    IssueCount,
    Query,
    Teams,
} from "@/interfaces/serverData";
import {
    BookmarkIcon,
    CaretDownIcon,
    CaretUpIcon,
} from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { EditableDescriptionInput } from "./EditableDescriptionInput";
import { EditableTitleInput } from "./EditableTitleInput";

import { Button } from "@/component/shadcn/ui/button";
import { getTrendFullIcon } from "@/utilities/methods";
import { type QueryObserverResult, type RefetchOptions, useQuery, type UseQueryResult } from "@tanstack/react-query";
import DisplayCard from "./DisplayCard";
import RelatedIssuesCard from "./RelatedIssuesCard";
import { useNavigate } from "react-router-dom";
import { useAuthInfo } from "@propelauth/react";

interface InsightDisplayProps {
    insight: Insight;
    userID: string;
    insightSelectedIsSaved: boolean;
    topicsMap: Map<string, GetTopicsResponse>;
    issueCounts: IssueCount[] | undefined;
    issueSelected?: string | undefined;
    setIssueSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
    updateInsightState?: (newState: Partial<Insight>) => void;
    handleSaveAIGeneratedInsight?: (
        insight: Insight,
        teamID?: string,
    ) => Promise<void>;
    issues?: Query[];
    trend: string | undefined;
    teamID?: string;
    setDialogClose?: () => void;
    teamsQuery?: UseQueryResult<Teams[], Error>
    refetch?: (options?: RefetchOptions) => Promise<QueryObserverResult<InsightInfoResponse | null, Error>>
}

export function InsightDisplay({
    insight,
    updateInsightState,
    insightSelectedIsSaved,
    handleSaveAIGeneratedInsight,
    issues,
    userID,
    issueSelected,
    setIssueSelected,
    topicsMap,
    issueCounts,
    trend,
    teamID,
    setDialogClose = () => { },
    teamsQuery,
    refetch
}: InsightDisplayProps) {
    const api = useApi();
    const authInfo = useAuthInfo();
    const [isSavingInsight, setIsSavingInsight] = useState(false);
    const [userInfo, setUserInfo] = useState<GetUserResponse>();

    const [isOpen, setIsOpen] = useState<boolean>(true); // Main card with the insight title, description, and graph
    const [attributesIsOpen, setAttributesIsOpen] = useState<boolean>(true);
    const [announcementsIsOpen, setAnnouncementsIsOpen] =
        useState<boolean>(false);
    const [extIssuesIsOpen, setExtIssuesIsOpen] = useState<boolean>(false);
    const [relIssuesIsOpen, setRelIssuesIsOpen] = useState<boolean>(true);
    const categoriesDataQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });
    const [orgId, setOrgId] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        const orgIds = authInfo.orgHelper?.getOrgIds();
        if (orgIds === undefined || orgIds.length !== 1) {
            navigate("/*");
            return;
        }
        setOrgId(orgIds[0]);
    }, [authInfo.orgHelper, navigate]);

    const sidebarLabels = "font-semibold p-1 text-sm";
    const Icon = getTrendFullIcon(trend ?? "");

    const handleSave = async () => {
        if (insight && handleSaveAIGeneratedInsight) {
            setIsSavingInsight(true);
            try {
                (await teamID)
                    ? handleSaveAIGeneratedInsight(insight, teamID)
                    : handleSaveAIGeneratedInsight(insight);
                console.log("Insight saved and data refetched");
            } finally {
                setIsSavingInsight(false);
            }
        }
    };

    useEffect(() => {
        if (insight?.user_id) {
            api.post(
                URLS.serverUrl + API.getUser,
                {
                    user_id: insight?.user_id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        setUserInfo(res.data.data);
                    }
                })
                .catch((res) => {
                    console.log("Could not grab username");
                });
        }
    }, [api, insight]);

    return (
        <div className="flex flex-1 flex-col gap-3 rounded-md h-full overflow-y-auto mb-20 m-1">
            {!insightSelectedIsSaved && (
                <div className="flex items-center gap-1 justify-start m-0">
                    <Button
                        type="button"
                        className="rounded-md flex items-center gap-1 m-0"
                        onClick={() => {
                            setDialogClose();
                            handleSave();
                        }}
                        disabled={isSavingInsight}
                        variant="secondary"
                    >
                        Save this AI Generated Insight
                        <BookmarkIcon className="h-4 w-4 text-muted-foreground" />
                    </Button>
                </div>
            )}
            <Card className="bg-white shadow-sm border rounded-lg relative p-3">
                <div
                    className={`flex items-start justify-between ${isOpen && "mb-3"}`}
                >
                    <div className="flex flex-col gap-3 py-2 w-full">
                        <div className="flex flex-col items-start px-2">
                            <div className="text-lg font-semibold w-full flex items-center gap-1.5">
                                {trend && trend !== "Unknown" && Icon && (
                                    <Icon />
                                )}
                                {insightSelectedIsSaved &&
                                    updateInsightState ? (
                                    <EditableTitleInput
                                        insight={insight}
                                        updateInsightState={updateInsightState}
                                        userID={userID}
                                    />
                                ) : (
                                    <div className="flex items-center gap-1">
                                        {insight.title}
                                        <img
                                            src={SparklesIcon}
                                            alt=""
                                            className="h-7 w-7 rounded p-1"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="text-sm text-muted-foreground w-full">
                                {insightSelectedIsSaved &&
                                    updateInsightState ? (
                                    <EditableDescriptionInput
                                        insight={insight}
                                        updateInsightState={updateInsightState}
                                        userID={userID}
                                    />
                                ) : (
                                    insight.description
                                )}
                            </div>
                        </div>
                        {isOpen && issueCounts && issueCounts.length !== 0 && (
                            <ResponsiveContainer
                                width="100%"
                                height={320}
                                className="-ml-7"
                            >
                                <LineChart data={issueCounts}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        type="category"
                                        tickFormatter={(value) =>
                                            new Date(value).toLocaleDateString()
                                        }
                                        tick={{ fontSize: 12 }}
                                    />
                                    <YAxis tick={{ fontSize: 12 }} />
                                    <Tooltip
                                        labelFormatter={(value) =>
                                            new Date(value).toLocaleDateString()
                                        }
                                        labelStyle={{ fontSize: 14 }}
                                        formatter={(value: number) => [
                                            `${value} Related Interactions`,
                                        ]}
                                        itemStyle={{ fontSize: 14 }}
                                    />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="numberOfIssues"
                                        stroke="#8884d8"
                                        name="Number of Related Interactions"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                    {isOpen ? (
                        <Button
                            type="button"
                            variant="ghost"
                            className="text-xs p-0.5 mt-3"
                            onClick={() => setIsOpen(false)}
                        >
                            <CaretUpIcon className="w-4 h-4" />
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            variant="ghost"
                            className="text-xs p-0.5 mt-3"
                            onClick={() => setIsOpen(true)}
                        >
                            <CaretDownIcon className="w-4 h-4" />
                        </Button>
                    )}
                </div>
            </Card>
            <DisplayCard
                isOpen={attributesIsOpen}
                setIsOpen={setAttributesIsOpen}
                categories={categoriesDataQuery.data ?? []}
                sidebarLabels={sidebarLabels}
                type={"Attributes"}
                insight={insight}
                updateInsightState={updateInsightState}
                insightUserInfo={userInfo}
                topicsMap={topicsMap}
                userID={userID}
                insightSelectedIsSaved={insightSelectedIsSaved}
                teamsQuery={teamsQuery}
                orgID={orgId}
                refetch={refetch}
            />
            <RelatedIssuesCard
                isOpen={relIssuesIsOpen}
                setIsOpen={setRelIssuesIsOpen}
                sidebarLabels={sidebarLabels}
                insight={insight}
                updateInsightState={updateInsightState}
                insightSelectedIsSaved={insightSelectedIsSaved}
                userID={userID}
                issues={issues}
                issueSelected={issueSelected}
                setIssueSelected={setIssueSelected}
            />
            {insightSelectedIsSaved && (
                <DisplayCard
                    isOpen={announcementsIsOpen}
                    setIsOpen={setAnnouncementsIsOpen}
                    categories={categoriesDataQuery.data ?? []}
                    sidebarLabels={sidebarLabels}
                    type={"Announcements"}
                    insight={insight}
                    updateInsightState={updateInsightState}
                    insightUserInfo={userInfo}
                    topicsMap={topicsMap}
                    userID={userID}
                    alertNum={insight.announcements?.length ?? 0}
                />
            )}
            {insightSelectedIsSaved && (
                <DisplayCard
                    isOpen={extIssuesIsOpen}
                    setIsOpen={setExtIssuesIsOpen}
                    categories={categoriesDataQuery.data ?? []}
                    sidebarLabels={sidebarLabels}
                    type={"External Issues"}
                    insight={insight}
                    updateInsightState={updateInsightState}
                    insightUserInfo={userInfo}
                    topicsMap={topicsMap}
                    userID={userID}
                    alertNum={insight.external_issues?.length ?? 0}
                />
            )}
        </div>
    );
}
