import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Category, CustomerGroup, GetTopicsResponse, Teams, Workflow } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { getTopicColors } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import {
  CaretSortIcon,
  ComponentBooleanIcon,
  DotFilledIcon,
  GroupIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { Handle, Position } from "@xyflow/react";
import { HouseIcon, UsersIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { ConditionIcon } from "../Icons";
import { handleStyle } from "../Icons";

const DropdownTrigger = (type: string, filters: Map<string, Set<{ label: string; value: string; key: string; color: string }>>, index: number) => {
  switch (type) {
    case "Source": {
      const sourceSet = filters.get(`Source_${index}`);
      const currSource =
        sourceSet && sourceSet.size === 1
          ? Array.from(sourceSet)[0]
          : {
            label: "Select source",
            value: "Select source",
            color: "",
            key: "Select source"
          };
      const SourceSvgImage =
        integrationBackEndDataMappingToSvg.get(currSource.value);
      return (
        <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-0.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
          <div className="flex items-center gap-1.5 p-1">
            {SourceSvgImage && (
              <SourceSvgImage
                className="w-3 h-3"
                justify="start"
              />
            )}
            {currSource.label}
          </div>
          <CaretSortIcon />
        </div>
      );
    }
    case "Team": {
      const teamSet = filters.get(`Team_${index}`);
      const currTeam =
        teamSet && teamSet.size === 1
          ? Array.from(teamSet)[0]
          : {
            label: "Select team",
            value: "Select team",
            color: "",
            key: "Select team"
          };
      return (
        <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
          <div className="flex items-center gap-1.5">
            {currTeam.value !== "Select team" && (currTeam.value === "General" ? (
              <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                <HouseIcon
                  className="text-iris9"
                  strokeWidth={1.5}
                  size={10}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                <UsersIcon
                  className="text-red9"
                  strokeWidth={1.5}
                  size={10}
                />
              </div>
            ))}
            {currTeam.label}
          </div>
          <CaretSortIcon />
        </div>
      );
    }
    case "Customer Group": {
      const cgSet = filters.get(`Customer Group_${index}`);
      const currCg =
        cgSet && cgSet.size === 1
          ? Array.from(cgSet)[0]
          : {
            label: "Select customer group",
            value: "Select customer group",
            color: "",
            key: "Select customer group"
          };
      return (
        <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
          <div className="flex items-center gap-1.5">
            {currCg.value !== "Select customer group" && <GroupIcon />}
            {currCg.label}
          </div>
          <CaretSortIcon />
        </div>
      );
    }
    case "Topic": {
      const topicSet = filters.get(`Topic_${index}`);
      const currTopic =
        topicSet && topicSet.size === 1
          ? Array.from(topicSet)[0]
          : {
            label: "Select tag",
            value: "Select tag",
            color: "",
            key: "Select tag"
          };
      return (
        <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
          {currTopic.value !== "Select tag" ? <Badge
            color="gray"
            size="2"
            radius="full"
            variant="outline"
            className="m-0 rounded-xl py-1 px-2"
          >
            <div className="flex flex-row items-center">
              <DotFilledIcon
                color={currTopic?.color !== "" ? currTopic.color : "#9B9EF0"}
                style={{
                  transform: "scale(1.8)",
                }}
              />
              <p className="pl-0.3">{currTopic.label}</p>
            </div>
          </Badge> :
            <p>{currTopic.label}</p>}
          <CaretSortIcon />
        </div>
      )
    }
    case "Tag": {
      const tagSet = filters.get(`Tag_${index}`);
      const currTag =
        tagSet && tagSet.size === 1
          ? Array.from(tagSet)[0]
          : {
            label: "Select interaction type",
            value: "Select interaction type",
            color: "",
            key: "Select interaction type"
          };
      return (
        <div className="flex items-center justify-between text-xs hover:bg-muted px-2 py-1.5 rounded outline outline-1 outline-[#eeeff1] w-[180px]">
          {currTag.value !== "Select interaction type" ? <Badge
            color="gray"
            size="2"
            radius="full"
            variant="outline"
            className="m-0 rounded-xl py-1 px-2"
          >
            <div className="flex flex-row items-center">
              <ComponentBooleanIcon
                color={
                  currTag?.color !== "" ? currTag.color : "gray"
                }
              />
              <p className="pl-0.3">{currTag.label}</p>
            </div>
          </Badge> :
            <p>{currTag.label}</p>}
          <CaretSortIcon />
        </div>
      )
    }
  }
};

type FilterType = {
  type: string;
  enabled: boolean;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const ConditionNode: React.FC<{ data: any; isConnectable: boolean; onUpdate: (id: string, metadata: any) => void, workflow?: Workflow }> = ({ data, isConnectable, onUpdate, workflow }) => {
  const api = useApi()
  // Each element in selected values is [type, value]
  const [selectedValues, setSelectedValues] = useState<FilterType[]>([{ type: "", enabled: true }]);
  const [filters, setFilters] = useState<Map<string, Set<{ label: string; value: string; key: string; color: string }>>>(data.metadata?.filters ?? new Map());

  const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
  const [conditionType, setConditionType] = useState<string>(data.metadata?.subtype ?? (workflow ? undefined : "all"));

  const authInfo = useAuthInfo();
  const authInfoRef = useRef(authInfo);
  const teamsQuery = useQuery<Teams[]>({
    queryKey: ["teams"],
    queryFn: async () => {
      const [url, method] = TeamsAPI.listMemberTeams;
      const response = await fetch(
        `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
        {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
          },
        },
      );
      const d = await response.json();
      return d.data;
    },
  });

  const customerGroupsQuery = useQuery<CustomerGroup[]>({
    queryKey: ["customers"],
    queryFn: async () => {
      const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authInfoRef.current.accessToken}`,
        },
      });

      const data = await res.json();
      const customerGroups: CustomerGroup[] = data.data;
      return customerGroups;
    },
  });

  const categoryOptionsQuery = useQuery<Category[]>({
    queryKey: ["categories"],
    queryFn: async () => {
      const response = await api.get(
        `${URLS.serverUrl}${API.getCategories}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        },
      );
      if (response.status === 200) {
        return response.data.data;
      }
      return [];
    },
  });

  // TODO: pull in team id
  const id = undefined
  const topicsQuery = useQuery<GetTopicsResponse[]>({
    queryKey: id ? [`teamTopics_${id}`] : ["topics"],
    queryFn: id ? () => fetchTeamTopics() : () => fetchTopics(),
  });

  const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
    const response = await api.get(URLS.serverUrl + API.getTopics, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };

  const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
    const response = await api.get(
      `${URLS.serverUrl}${API.getTopics}/team/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
    if (response.status === 200) {
      return response.data.data;
    }
    return [];
  };

  // Update node's metadata
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedMetadata = { ...data.metadata, name: e.target.value };
    onUpdate(data.id, updatedMetadata);  // Update the metadata of the node
    setNameVal(e.target.value);
  };
  const handleSubtypeChange = (value: string) => {
    const updatedMetadata = { ...data.metadata, subtype: value };
    onUpdate(data.id, updatedMetadata);  // Update the metadata of the node
  };
  useEffect(() => {
    const updatedMetadata = { ...data.metadata, filters: filters };
    onUpdate(data.id, updatedMetadata);

    const newSelectedValues: FilterType[] = []
    for (const [key, value] of filters.entries()) {
      console.log('key is', key)
      const type = key.split("_")[0]
      newSelectedValues.push({ type: type, enabled: true })
    }
    newSelectedValues.push({ type: "", enabled: true })
    setSelectedValues(newSelectedValues)
  }, [filters])


  const handleItemSelect = (type: string, option: { label: string; value: string; key: string; color: string }, index?: number) => () => {
    const newFilters = new Map(filters);
    switch (type) {
      case "Source": {
        newFilters.set(`Source_${index}`, new Set([option]));
        setFilters(newFilters);
        return;
      }
      case "Team": {
        newFilters.set(`Team_${index}`, new Set([option]));
        setFilters(newFilters);
        return;
      }
      case "Customer Group": {
        newFilters.set(`Customer Group_${index}`, new Set([option]));
        setFilters(newFilters);
        return;
      }
      case "Topic": {
        newFilters.set(`Topic_${index}`, new Set([option]));
        setFilters(newFilters);
        return;
      }
      case "Tag": {
        newFilters.set(`Tag_${index}`, new Set([option]));
        setFilters(newFilters);
        return;
      }
      default:
        console.log("handleItemSelect does not support type ", type);
    }
  };

  const handleSelectChange = (value: string, index: number) => {
    const newSelectedValues = [...selectedValues];
    // If setting the last filter to a value, automatically add another filter
    if (index === newSelectedValues.length - 1 && value !== "") {
      newSelectedValues.push({ type: "", enabled: true });
    }
    newSelectedValues[index] = { type: value, enabled: true };
    setSelectedValues(newSelectedValues);
  };

  const handleDeleteFilter = (value: string, index: number) => {
    const newSelectedValues = [...selectedValues];
    const filterType = newSelectedValues[index]
    // Update filters
    const newFilters = new Map(filters);
    newFilters.delete(`${filterType.type}_${index}`)
    setFilters(newFilters);

    newSelectedValues[index] = { type: value, enabled: false };
    setSelectedValues(newSelectedValues);
  };

  console.log("filters are", filters)
  console.log('selected values are', selectedValues)
  return (
    <div className="flex flex-col items-start">
      <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
        <div className="flex flex-row items-center justify-center gap-1">
          <ComponentBooleanIcon className="text-[#5e6ad2] h-3 w-3" />
          <p className="text-xs">Condition</p>
        </div>
      </Badge>
      <Card className="w-[450px] shadow-none border rounded-tr-lg rounded-bl-lg rounded-br-lg z-10">
        <CardHeader className="px-4">
          <div className="flex flex-col items-start gap-3 w-full">
            <div className="flex items-center gap-2 w-full">
              <ConditionIcon />
              <div className="flex items-center gap-3 w-full">
                <p className="text-xs font-medium flex-shrink-0">
                  If
                </p>
                <Select defaultValue={conditionType} onValueChange={handleSubtypeChange}>
                  <SelectTrigger className="w-[40px] focus:outline-none focus:ring-0 text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">all</SelectItem>
                    <SelectItem value="any">any</SelectItem>
                  </SelectContent>
                </Select>
                <p className="text-xs font-medium flex-grow">
                  of these filters match:
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3 w-full">
              {selectedValues.map((filterType, index) => {
                if (!filterType.enabled) {
                  return null; // If disabled, do not render this element
                }
                return (
                  <div className="flex items-center gap-3 w-full ml-8" key={`${filterType.type}_${index}`}>
                    <Select
                      value={filterType.type}
                      onValueChange={(value) => handleSelectChange(value, index)}
                    >
                      <SelectTrigger className="w-[120px] text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                        <SelectValue placeholder="Add a filter..." />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Source">Source</SelectItem>
                        <SelectItem value="Team">Team</SelectItem>
                        {/* Not supported in FilterDropdown yet so removed for now */}
                        {/* <SelectItem value="Company">Company</SelectItem> */}
                        <SelectItem value="Customer Group">Customer Group</SelectItem>
                        <SelectItem value="Tag">Interaction Type</SelectItem>
                        <SelectItem value="Topic">Tag</SelectItem>
                      </SelectContent>
                    </Select>

                    {filterType.type !== "" && (
                      <>
                        <p className="text-xs font-medium">is</p>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild type="button">
                            {DropdownTrigger(filterType.type, filters, index)}
                          </DropdownMenuTrigger>
                          <DropdownMenuContent
                            align="start"
                            className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                          >
                            <FilterDropdownElement
                              type={filterType.type}
                              categories={categoryOptionsQuery.data ?? []}
                              filters={filters}
                              handleItemSelect={handleItemSelect}
                              topics={getTopicColors(
                                topicsQuery.data ?? [],
                              )}
                              users={[]}
                              customerGroups={customerGroupsQuery.data ?? []}
                              teams={teamsQuery.data ?? []}
                              isSavedViewFilter={false}
                              channels={new Map()}
                              index={index}
                            />
                          </DropdownMenuContent>
                        </DropdownMenu>

                        <Button
                          variant="outline"
                          className="px-1.5"
                          onClick={() => handleDeleteFilter(filterType.type, index)}
                        >
                          <TrashIcon className="w-3.5 h-3.5" />
                        </Button>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </CardHeader>

        <CardContent className="flex flex-col gap-2">
          <Separator />
          <Input
            className="text-xs border-none px-0"
            placeholder="Name..."
            value={nameVal}
            onChange={handleNameChange}
          />
        </CardContent>

        <Handle
          type="source"
          position={Position.Top}
          id="a"
          isConnectable={isConnectable}
          className={`${handleStyle} `}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
          className={handleStyle}
        />
      </Card>
    </div>
  );
};

export default ConditionNode;
