import { Filter } from "@/IssuesTable/Filter";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import { FilterType } from "@/IssuesTable/constants";
import type { GetUserResponse, Teams } from "@/interfaces/serverData";
import { getFilterOption } from "@/utilities/methods";
import { useCallback, useState } from "react";

interface FilterItemsProps {
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    users: GetUserResponse[];
    teams: Teams[];
    isGeneralTeam: boolean;
}

export const FilterItems: React.FC<FilterItemsProps> = ({
    filters,
    setFilters,
    users,
    teams,
    isGeneralTeam,
}) => {
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const filterOptions = [getFilterOption("Insight Status", FilterType.MenuThenSelect, true),];
    if (isGeneralTeam) {
        filterOptions.push(getFilterOption("Team", FilterType.MenuThenSelect, true));
    }
    const handleFilterSelect = (type: string, option: { label: string; value: string; key: string; color: string }) => {
        return () => {
            const newFilters = new Map(filters); // Create a shallow copy of the filters Map
            if (newFilters.has(type)) {
                const currValues = new Set(newFilters.get(type)); // Shallow copy of the Set
                if (currValues.has(option.value)) {
                    currValues.delete(option.value); // Remove the selected value
                    if (currValues.size === 0) {
                        newFilters.delete(type); // Remove filter if no values remain
                    } else {
                        newFilters.set(type, currValues); // Update the type with new values
                    }
                } else {
                    currValues.add(option.value); // Add new value to the filter
                    newFilters.set(type, currValues); // Update the type with the new value set
                }
            } else {
                newFilters.set(type, new Set([option.value])); // Add new filter type if it doesn't exist
            }
            setFilters(newFilters); // Ensure you're updating the state properly
        };
    };

    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    return (
        <>
            {Array.from(filters.entries()).map(([type, values]) => {
                return (
                    <div key={type}>
                        <Filter
                            type={type}
                            values={values} // Convert Set to array
                            filters={filters}
                            setFilters={setFilters}
                            categories={[]}
                            topics={[]}
                            users={users}
                            customerGroups={[]}
                            teams={teams}
                            isSavedViewFilter={false}
                            channels={new Map()}
                            filterOptions={filterOptions}
                        />
                    </div>
                );
            })}
            <FilterDropdown
                filters={filters}
                filterOptions={filterOptions}
                activeMenu={activeMenu}
                setActiveMenu={setActiveMenu}
                handleItemSelect={handleFilterSelect}
                handleMenuClick={handleMenuClick}
                categories={[]}
                topics={[]}
                users={users}
                customerGroups={[]}
                teams={teams}
                channels={new Map()}
            />
        </>
    );
};
