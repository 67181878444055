import { Card } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { useApi } from "@/interfaces/api";
import type {
    GetUserResponse,
    Query,
} from "@/interfaces/serverData";
import { AvatarIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useEffect, useState } from "react";

interface AssigneeDropdownProps<TData> {
    selectedUser: string;
    users: GetUserResponse[];
    toggleAssignee: (userInfo: GetUserResponse, accountAssignee: string) => void,
    userID: string;
    notEditable?: boolean;
}

export function AssigneeDropdown<TData extends Query>({
    selectedUser,
    users,
    toggleAssignee,
    userID,
    notEditable
}: AssigneeDropdownProps<TData>) {
    const api = useApi();
    const assignText = "Unassigned...";

    const [user, setUser] = useState<string>(assignText);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<GetUserResponse>();
    const [pictureURL, setPictureURL] = useState<string>("");

    useEffect(() => {
        if (selectedUser) {
            const userData = users.find(
                (user) => user.id === selectedUser,
            );
            if (
                userData &&
                userData.first_name !== "" &&
                userData.last_name !== ""
            ) {
                setUser(`${userData.first_name} ${userData.last_name}`);
                setUserInfo(userData);
                setEnabled(true);
            } else {
                setEnabled(false);
            }
        }
    }, [selectedUser, api, users]);

    const [myUser, setMyUser] = useState<GetUserResponse>({
        id: "",
        email: "",
        username: "",
        first_name: "",
        last_name: "",
        picture_url: "",
        user_role: "",
    });

    useEffect(() => {
        if (userID) {
            const userData = users.find((user) => user.id === userID);

            if (
                userData &&
                userData.first_name !== "" &&
                userData.last_name !== ""
            ) {
                setMyUser(userData);
            }
        }
    }, [userID, users]);

    const [open, setOpen] = useState<boolean>(false);

    return (
        <div className="text-xs flex items-center">
            {notEditable ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Badge
                                color={"gray"}
                                size="2"
                                variant="outline"
                                className="ring-[0.8px] text-gray-700 ring-[#E0E1E6] rounded-md"
                            >
                                <div className="lb-root flex flex-row gap-1 rounded-lg lb-comment-header mb-0 lb-comment-details p-2">
                                    <div
                                        className={
                                            "lb-avatar rounded-lg w-5 h-4"
                                        }
                                    >
                                        {pictureURL && (
                                            <img
                                                className="lb-avatar-image"
                                                src={pictureURL}
                                                alt={user[0]}
                                            />
                                        )}
                                        <span>{user[0] ?? ""}</span>
                                    </div>
                                    <div className="lb-comment-author text-xs font-medium">
                                        {user}
                                    </div>
                                </div>
                            </Badge>
                        </TooltipTrigger>
                        <TooltipContent
                            sideOffset={5}
                            className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                            asChild
                        >
                            <Card className="bg-white text-gray11 p-3 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                Contacts have the same assignee as the company they are attached to:
                                <p className="font-bold">
                                    {userInfo?.email ?? ""}
                                </p>
                            </Card>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <Popover>
                    <PopoverTrigger asChild>
                        <Badge
                            color={"gray"}
                            size="2"
                            variant="outline"
                            className="ring-[0.8px] text-gray-700 ring-[#E0E1E6] rounded-md"
                        >
                            <div className="lb-root flex flex-row gap-1 rounded-lg lb-comment-header mb-0 lb-comment-details p-2">
                                {enabled ? (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div
                                                    className={
                                                        "lb-avatar rounded-lg w-5 h-4"
                                                    }
                                                >
                                                    {pictureURL && (
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={pictureURL}
                                                            alt={user[0]}
                                                        />
                                                    )}
                                                    <span>{user[0] ?? ""}</span>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent
                                                sideOffset={5}
                                                className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                                                asChild
                                            >
                                                <Card className="bg-white text-gray11 p-3 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                                    <p className="font-bold ">
                                                        {userInfo?.email ?? ""}
                                                    </p>
                                                </Card>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                ) : (
                                    <div className={"lb-avatar rounded-lg w-6 h-5"}>
                                        {pictureURL && (
                                            <img
                                                className="lb-avatar-image"
                                                src={pictureURL}
                                                alt={user[0]}
                                            />
                                        )}
                                        <span>{user[0] ?? ""}</span>
                                    </div>
                                )}
                                <div className="lb-comment-author text-xs font-medium">
                                    {user}
                                </div>
                                <TriangleDownIcon className="h-3 w-3" />
                            </div>
                        </Badge>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0">
                        <Command>
                            <CommandInput
                                placeholder="Search assignee..."
                                className="h-9 text-xs"
                            />
                            <CommandList>
                                <CommandEmpty>No assignee found.</CommandEmpty>
                                <CommandGroup>
                                    <CommandItem
                                        className="text-xs"
                                        onSelect={(currentValue) => {
                                            toggleAssignee(myUser, selectedUser)
                                            setOpen(false);
                                        }}
                                    >
                                        <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                            <AvatarIcon className="w-6 h-6 mx-0.3" />

                                            <span className="lb-comment-author text-xs font-normal">
                                                Assign to Me
                                            </span>
                                        </div>
                                    </CommandItem>
                                    {users.map((user) => (
                                        <CommandItem
                                            key={user.id}
                                            value={`${user.first_name} ${user.last_name}`}
                                            onSelect={(currentValue) => {
                                                toggleAssignee(user, selectedUser)
                                                setOpen(false);
                                            }}
                                        >
                                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                                <div
                                                    className={"lb-avatar w-5 h-5"}
                                                >
                                                    {user.picture_url && (
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={user.picture_url}
                                                            alt={`${user.first_name} ${user.last_name}`}
                                                        />
                                                    )}
                                                    <span>{`${user.first_name} ${user.last_name}`}</span>
                                                </div>
                                                <span className="lb-comment-author text-xs font-normal">
                                                    {`${user.first_name} ${user.last_name}`}
                                                </span>
                                            </div>
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            )}
        </div>
    );
}
