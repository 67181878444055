import {
    BoldIcon,
    CheckIcon,
    CodeIcon,
    ImageIcon,
    ItalicIcon,
    Link2Icon,
    ListIcon,
    ListOrderedIcon,
    SmileIcon,
    StrikethroughIcon,
    VariableIcon,
    XIcon,
} from "lucide-react";

import {
    $createTextNode,
    $getRoot,
    $insertNodes,
    $isParagraphNode,
    type NodeKey,
} from "lexical";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";

import "@liveblocks/react-ui/styles.css";
import "@liveblocks/react-lexical/styles.css";
import "../../pages/Announcements/globals.css";
import { $isLinkNode } from "@lexical/link";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    type ListType,
    REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isTableNode } from "@lexical/table";
import {
    $findMatchingParent,
    $getNearestNodeOfType,
    $isEditorIsNestedEditor,
    mergeRegister,
} from "@lexical/utils";
import EmojiPicker, { type EmojiClickData } from "emoji-picker-react";
import {
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    COMMAND_PRIORITY_CRITICAL,
    FORMAT_TEXT_COMMAND,
    SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState, useSyncExternalStore } from "react";
import { Input } from "../shadcn/ui/input";

import { $isAtNodeEnd } from "@lexical/selection";

import {
    SUPPORTED_VARIABLES,
    type UploadedFile,
    type UploadedFileWithMetadata,
} from "@/interfaces/serverData";
import { PopoverClose } from "@radix-ui/react-popover";
import type { ElementNode, RangeSelection, TextNode } from "lexical";
import { Button } from "../shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "../shadcn/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/ui/popover";
import { InsertFileDialog } from "./FilePlugin";
import { InsertImageDialog } from "./ImagesPlugin";
import { VariableNode } from "./nodes/TextVariableNode";
// REFERENCE:
// https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx#L101

export function getSelectedNode(
    selection: RangeSelection,
): TextNode | ElementNode {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
    }
}

export function FillURL(): string {
    const srcfile = prompt("Enter the URL of the image:", "");

    return srcfile ?? "";
}

const blockTypeToBlockName = {
    bullet: "Bulleted List",
    code: "Code Block",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
};

const rootTypeToRootName = {
    root: "Root",
    table: "Table",
};

interface ToolbarProps {
    handleImageUpload: (src: string, altText: string) => void;
    handleFileUpload: (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => void;
    source: string;
    setIsSendDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
    uploadedFiles?: (UploadedFile | UploadedFileWithMetadata)[];
}

export default function Toolbar({
    handleImageUpload,
    handleFileUpload,
    source,
    setIsSendDisabled,
    uploadedFiles,
}: ToolbarProps) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const activeBlock = useActiveBlock();

    const [emojisIsOpen, setEmojisIsOpen] = useState(false);
    const [blockType, setBlockType] =
        useState<keyof typeof blockTypeToBlockName>("paragraph");
    const [rootType, setRootType] =
        useState<keyof typeof rootTypeToRootName>("root");
    const [selectedElementKey, setSelectedElementKey] =
        useState<NodeKey | null>(null);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [isImageCaption, setIsImageCaption] = useState(false);

    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const [imageOpen, setImageOpen] = useState<boolean>(false);
    const [imageMode, setImageMode] = useState<string>("image");

    const [fileOpen, setFileOpen] = useState<boolean>(false);

    const mapListTypeToBlockType = (
        listType: ListType,
    ): "number" | "bullet" | "code" | "paragraph" | "quote" => {
        switch (listType) {
            case "number":
            case "bullet":
                return listType; // if it's "number" or "bullet", return as-is
            default:
                return "paragraph"; // for unsupported types like "check", return a fallback
        }
    };

    useEffect(() => {
        if (setIsSendDisabled) {
            if ((uploadedFiles ?? []).length > 0) {
                setIsSendDisabled(true);
            } else {
                return editor.registerUpdateListener(() => {
                    editor.getEditorState().read(() => {
                        const root = $getRoot();
                        const children = root.getChildren();
                        const hasVariableNodes = children.some((node) => {
                            if ($isParagraphNode(node)) {
                                // Check if any child of the paragraph node is a VariableNode
                                return node
                                    .getChildren()
                                    .some(
                                        (child) =>
                                            child instanceof VariableNode,
                                    );
                            }
                            return false;
                        });
                        if (hasVariableNodes) {
                            setIsSendDisabled(false);
                        } else if (children.length > 1) {
                            setIsSendDisabled(true);
                        } else {
                            if ($isParagraphNode(children[0])) {
                                const paragraphChildren =
                                    children[0].getChildren();
                                const isParagraphEmpty =
                                    paragraphChildren.every((child) => {
                                        return !child.getTextContent().trim();
                                    });
                                setIsSendDisabled(!isParagraphEmpty);
                            } else {
                                setIsSendDisabled(false);
                            }
                        }
                    });
                });
            }
        }
    }, [editor, setIsSendDisabled, uploadedFiles]);

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            if (
                activeEditor !== editor &&
                $isEditorIsNestedEditor(activeEditor)
            ) {
                const rootElement = activeEditor.getRootElement();
                setIsImageCaption(
                    !!rootElement?.parentElement?.classList.contains(
                        "image-caption-container",
                    ),
                );
            } else {
                setIsImageCaption(false);
            }

            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsCode(selection.hasFormat("code"));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType("table");
            } else {
                setRootType("root");
            }

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode,
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(mapListTypeToBlockType(type));
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                }
            }
        }
    }, [activeEditor, editor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                setActiveEditor(newEditor);
                $updateToolbar();
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
        );
    }, [$updateToolbar, activeEditor, editor]);

    useEffect(() => {
        activeEditor.getEditorState().read(() => {
            $updateToolbar();
        });
    }, [activeEditor, $updateToolbar]);

    const variablesUpdateEditor = (variable: string) => {
        editor.update(() => {
            const selection = $getSelection();

            if ($isRangeSelection(selection)) {
                const variableNode = new VariableNode(variable);
                selection.insertNodes([variableNode]);

                // Insert a text node after the variable node
                const textNode = $createTextNode(" ");
                const parentNode = selection.getNodes()[0]?.getParent();
                if (parentNode) {
                    parentNode.append(textNode);
                }
                const root = $getRoot();
                root.selectEnd();
            }
        });
    };

    return (
        <div>
            <div className="flex items-center gap-2 px-3 py-2 border-b">
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
        ${isBold ? "bg-gray-100" : ""}`} // Fixed this line
                    type="button"
                >
                    <BoldIcon strokeWidth={1.5} size={16} />
                </button>
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isItalic ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <ItalicIcon strokeWidth={1.5} size={16} />
                </button>
                {/* underline seems to not exist on slack?? */}
                <button
                    onClick={() => {
                        editor.dispatchCommand(
                            FORMAT_TEXT_COMMAND,
                            "strikethrough",
                        );
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isStrikethrough ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <StrikethroughIcon strokeWidth={1.5} size={16} />
                </button>
                {/* <button
                    onClick={() => editor.update(() => toggleBlock("h3"))}
                    data-active={activeBlock === "h3" ? "" : undefined}
                    className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 data-[active]:bg-gray-200"
                    type="button"
                >
                    <LinkIcon strokeWidth={1.5} size={16} />
                </button> */}
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isCode ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <CodeIcon strokeWidth={1.5} size={16} />
                </button>
                <button
                    onClick={() => {
                        if (blockType === "bullet") {
                            editor.dispatchCommand(
                                REMOVE_LIST_COMMAND,
                                undefined,
                            );
                        } else {
                            editor.dispatchCommand(
                                INSERT_UNORDERED_LIST_COMMAND,
                                undefined,
                            );
                        }
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${blockType === "bullet" ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <ListIcon strokeWidth={1.5} size={16} />
                </button>
                <button
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isLink ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <Popover modal={true}>
                        <PopoverTrigger>
                            <Link2Icon strokeWidth={1.5} size={16} />
                        </PopoverTrigger>
                        <PopoverContent className="w-[200px] p-2">
                            <div className="flex flex-col gap-2 items-center">
                                <Input
                                    type="text"
                                    placeholder="Enter URL..."
                                    className="text-xs border p-1"
                                    id="link-input"
                                />
                                <div className="flex flex-row gap-2 items-center">
                                    <PopoverClose asChild>
                                        <Button
                                            variant="outline"
                                            className="h-6 items-center flex flex-row gap-2"
                                        >
                                            <span className="text-xs">
                                                Cancel
                                            </span>
                                            <XIcon
                                                size={14}
                                                strokeWidth={1.5}
                                            />
                                        </Button>
                                    </PopoverClose>
                                    <PopoverClose asChild>
                                        <Button
                                            className="h-6 items-center flex flex-row gap-2"
                                            variant="outline"
                                            onClick={() => {
                                                const url = (
                                                    document.getElementById(
                                                        "link-input",
                                                    ) as HTMLInputElement
                                                ).value;
                                                if (url.trim()) {
                                                    editor.dispatchCommand(
                                                        TOGGLE_LINK_COMMAND,
                                                        { url },
                                                    );
                                                }
                                            }}
                                        >
                                            <span className="text-xs">
                                                Insert
                                            </span>
                                            <CheckIcon
                                                size={14}
                                                strokeWidth={1.5}
                                            />
                                        </Button>
                                    </PopoverClose>
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>
                </button>
                <button
                    onClick={() => {
                        if (blockType === "number") {
                            editor.dispatchCommand(
                                REMOVE_LIST_COMMAND,
                                undefined,
                            );
                        } else {
                            editor.dispatchCommand(
                                INSERT_ORDERED_LIST_COMMAND,
                                undefined,
                            );
                        }
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${blockType === "number" ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <ListOrderedIcon strokeWidth={1.5} size={16} />
                </button>
                {(source === "None" ||
                    source === "Slack" ||
                    source === "CommunitySlack" ||
                    source === "Discord") && (
                        <button
                            className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100"
                            type="button"
                        >
                            <DropdownMenu>
                                <DropdownMenuTrigger>
                                    <ImageIcon strokeWidth={1.5} size={16} />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                    <DropdownMenuItem
                                        onClick={() => {
                                            setImageMode("image");
                                            setImageOpen(true);
                                        }}
                                        className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                                    >
                                        Image
                                    </DropdownMenuItem>
                                    {source === "None" && ( // only support on announcements right now!
                                        <DropdownMenuItem
                                            onClick={() => {
                                                setFileOpen(true);
                                            }}
                                            className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                                        >
                                            File
                                        </DropdownMenuItem>
                                    )}
                                </DropdownMenuContent>
                            </DropdownMenu>
                            <InsertImageDialog
                                open={imageOpen}
                                setOpen={setImageOpen}
                                activeEditor={activeEditor}
                                mode={imageMode}
                                handleFileUpload={handleImageUpload}
                            />
                            <InsertFileDialog
                                open={fileOpen}
                                setOpen={setFileOpen}
                                activeEditor={activeEditor}
                                handleFileUpload={handleFileUpload}
                            />
                        </button>
                    )}
                <DropdownMenu
                    open={emojisIsOpen}
                    onOpenChange={setEmojisIsOpen}
                >
                    <DropdownMenuTrigger asChild>
                        <button
                            onClick={() => { }}
                            className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100"
                            type="button"
                        >
                            <SmileIcon strokeWidth={1.5} size={16} />
                        </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-64">
                        <EmojiPicker
                            onEmojiClick={(
                                emojiData: EmojiClickData,
                                event: MouseEvent,
                            ) => {
                                editor.update(() => {
                                    $insertNodes([
                                        $createTextNode(emojiData.emoji),
                                    ]);
                                });
                                setEmojisIsOpen(false);
                            }}
                        />
                    </DropdownMenuContent>
                </DropdownMenu>
                {source === "Template" && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <button
                                onClick={() => { }}
                                className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100"
                                type="button"
                            >
                                <VariableIcon strokeWidth={1.5} size={16} />
                            </button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-64 p-0">
                            <Command className="rounded-md shadow-md text-xs pb-1">
                                <CommandInput
                                    placeholder="Filter variables..."
                                    className="px-1 text-[13px]"
                                />
                                <CommandList className="flex-1 max-h-[140px] overflow-y-auto">
                                    <CommandEmpty className="text-xs px-4 py-2">
                                        No results found.
                                    </CommandEmpty>
                                    {SUPPORTED_VARIABLES.map((variable) => (
                                        <CommandItem
                                            key={variable}
                                            onSelect={() =>
                                                variablesUpdateEditor(variable)
                                            }
                                            className="text-[13px] flex items-center gap-2 px-4"
                                        >
                                            {variable}
                                        </CommandItem>
                                    ))}
                                </CommandList>
                            </Command>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
            </div>
        </div>
    );
}

function useActiveBlock() {
    const [editor] = useLexicalComposerContext();

    const subscribe = useCallback(
        (onStoreChange: () => void) => () => { },
        [editor],
    );

    const getSnapshot = useCallback(() => {
        return editor.getEditorState().read(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) return null;

            const anchor = selection.anchor.getNode();
            let element =
                anchor.getKey() === "root"
                    ? anchor
                    : $findMatchingParent(anchor, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });

            if (element === null) {
                element = anchor.getTopLevelElementOrThrow();
            }

            if ($isHeadingNode(element)) {
                return element.getTag();
            }

            return element.getType();
        });
    }, [editor]);

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
}
