import { Input } from "@/component/shadcn/ui/input";
import type {
    Category,
    CustomerGroup,
    GetUserResponse,
    Query,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { memo } from "react";
import { FilterItems } from "./FilterItems";

interface FilterBarProps {
    issues: Query[];
    topics: Topic[];
    channels: Map<string, ScopeResponse[]>;
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    categories: Category[];
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    savedViewFilters: Map<string, Set<string>>;
    isGeneralTeam: boolean;
}

const FilterBar: React.FC<FilterBarProps> = ({
    issues,
    categories,
    topics,
    channels,
    users,
    customerGroups,
    teams,
    searchQuery,
    setSearchQuery,
    filters,
    setFilters,
    savedViewFilters,
    isGeneralTeam,
}) => {
    return (
        <div className="flex flex-col gap-1 my-1 py-1 bg-muted">
            <div className="flex items-center gap-0 mx-5 mt-0.5">
                <MagnifyingGlassIcon className="w-4 h-4 text-gray-700" />
                <Input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full p-1.5 rounded-md text-xs border-none shadow-none focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none"
                />
            </div>
            <div className="flex mx-4 mt-0.5">
                <FilterItems
                    filters={filters}
                    setFilters={setFilters}
                    categories={categories}
                    topics={topics}
                    users={users}
                    customerGroups={customerGroups}
                    teams={teams}
                    savedViewFilters={savedViewFilters}
                    isGeneralTeam={isGeneralTeam}
                    channels={channels}
                />
            </div>
        </div>
    );
};

export default memo(FilterBar);
