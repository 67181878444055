import { ASSEMBLY_COOKIES, loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    BotMetadata,
    OrgInfoResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import { setCookie } from "@/utilities/CookieManagement";
import { CheckCircledIcon, ReloadIcon } from "@radix-ui/react-icons";
import { Box, Button, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as IntercomSvg } from "../../../images/integrations/intercom.svg";
import type { metadata } from "../IntegrationsManagementPage";
import { readableIntegrationMapping } from "../constant";
import IntegrationHeader from "./IntegrationHeader";
import { IntercomCardComponent } from "./IntercomCardComponent.tsx";

const IntercomIntegration = () => {
    const [popupOpen, setPopupOpen] = useState<boolean>(false);

    const intercomPopupIndex = (
        integrationType: string,
        intercomURL: string,
    ) => {
        setCookie(ASSEMBLY_COOKIES.jira_url, intercomURL); // Use the same logic to store URL in the jira_url cookie
        const client_id = process.env.REACT_APP_INTERCOM_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/intercom`;
        const intercom_url = `https://app.intercom.com/oauth?client_id=${client_id}&redirect_uri=${redirect_url}&state=ui-assembly-admin-intercom1234`;
        window.open(intercom_url, "_self");
    };

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const [scopes, setScopes] = useState<ScopeResponse[]>([]);
    const [asmTicketChannels, setAsmTicketChannels] = useState<string[]>([]);
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Intercom"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.Intercom) {
                            setIntegrationEnabled(true);
                        }

                        if (orgInfo.Intercom?.[0]?.scopes) {
                            for (const repo of orgInfo.Intercom[0].scopes) {
                                temp.scopes.push(repo.name);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    useEffect(() => {
        if (integrationEnabled) {
            const requestData = {
                type: "Intercom",
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setScopes(dataItems);
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Intercom`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const settingsData: BotMetadata = res.data.data;
                                const dataTemp: string[] = [];
                                if (settingsData.asm_ticket_channels) {
                                    for (const repo of settingsData.asm_ticket_channels) {
                                        dataTemp.push(repo.name);
                                    }
                                }
                                setAsmTicketChannels(dataTemp);
                            } else {
                                setLoadingState(2);
                            }
                        })
                        .catch((res) => {
                            setLoadingState(2);
                        });
                })
                .catch((res) => {
                    console.log("Could not find GitHub Ticket Scopes");
                });
        }
    }, [api, integrationEnabled]);

    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);
    const timerRef = useRef(0);

    const intercomContinue = () => {
        setPopupOpen(true);
    };

    return (
        <div>
            <IntercomCardComponent
                index={intercomPopupIndex}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
            />
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType={
                            readableIntegrationMapping.get("intercom") ??
                            "Intercom"
                        }
                        description="Sync Intercom support requests into Assembly"
                        SvgIcon={IntercomSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button mb={"20px"} onClick={intercomContinue}>
                                Enable
                            </Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                                <Button
                                    size="1"
                                    onClick={intercomContinue}
                                    mb={"20px"}
                                >
                                    <ReloadIcon /> Refresh Scopes
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <AssemblyErrorMessage />
                )}
            </Box>
            <AssemblyToastProvider
                open={open}
                setOpen={setOpen}
                toastText={toastText}
                toastSymbol={ToastSymbol}
            />
        </div>
    );
};

export default IntercomIntegration;
