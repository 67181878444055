import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { CLEAR_EDITOR_COMMAND } from "lexical";
import {
    ChevronDownIcon,
    EyeOffIcon,
    LoaderCircleIcon,
    MailIcon,
    MailPlusIcon,
    SendHorizonalIcon,
} from "lucide-react";
import { Button } from "../shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/ui/tooltip";
import { EditorActionType, ScopeResponse, Ticket } from "@/interfaces/serverData";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "../shadcn/ui/dialog";
import { Input } from "../shadcn/ui/input";
import { useEffect, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../shadcn/ui/select";

export default function ActionsPlugin({
    isSendDisabled,
    loading,
    handleSubmit,
    gmailScopes,
    originalTicket
}: {
    isSendDisabled: boolean;
    loading: boolean;
    handleSubmit: (type: EditorActionType, recipient?: string, integration_id?: string, subject?: string, header?: string) => void;
    gmailScopes?: ScopeResponse[];
    originalTicket?: Ticket;
}): JSX.Element {
    const [editor] = useLexicalComposerContext();
    const handleMessageSubmit = (type: EditorActionType, recipient?: string, integration_id?: string, subject?: string, header?: string) => {
        editor.getEditorState().read(() => {
            handleSubmit(type, recipient, integration_id, subject, header);
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        });
    };

    const [recipient, setRecipient] = useState('');
    const [subject, setSubject] = useState(originalTicket ? `Re: ${originalTicket.title}` : '');
    const [integrationID, setIntegrationID] = useState<string | undefined>();
    const [openDialog, setOpenDialog] = useState(false);
    // Handle submission of the email form
    const handleEmailSubmit = async () => {
        if (!recipient) {
            alert('Please enter a valid email address.');
            return;
        }
        if (!integrationID) {
            alert('Please enter a valid email to send from');
            return;
        }
        // Send new email
        handleMessageSubmit(EditorActionType.NewEmail, recipient, integrationID, subject)
        // Send internal message about the new email sent
        const imHeader = `Sent new email to ${recipient}:`
        handleMessageSubmit(EditorActionType.InternalReply, undefined, undefined, undefined, imHeader)
        setOpenDialog(false);
    };

    const handleSelectChange = (value: string) => {
        setIntegrationID(value);
    };

    useEffect(() => {
        if (!integrationID && gmailScopes && gmailScopes.length > 0) {
            setIntegrationID(gmailScopes[0].key)
        }
    }, [gmailScopes])
    return (
        <div className="overflow-hidden flex justify-end pr-1">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div>
                            <DropdownMenu>
                                <div className="relative flex">
                                    {/* Main Send Button */}
                                    <Button
                                        disabled={!isSendDisabled}
                                        className="px-4 py-2 disabled:opacity-50 bg-[#5B5BD6] rounded-r-none flex items-center"
                                        onClick={() => handleMessageSubmit(EditorActionType.Reply)} // Main send action
                                        type="submit"
                                    >
                                        {loading ? (
                                            <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                                        ) : (
                                            <>
                                                <SendHorizonalIcon className="h-4 w-4 mr-2" />
                                                Send
                                            </>
                                        )}
                                    </Button>

                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            disabled={!isSendDisabled}
                                            className="px-2 py-2 disabled:opacity-50 bg-[#5B5BD6] rounded-l-none flex items-center"
                                            type="button"
                                        >
                                            <ChevronDownIcon className="h-4 w-4" />
                                        </Button>
                                    </DropdownMenuTrigger>

                                    <DropdownMenuContent align="end">
                                        <DropdownMenuItem
                                            onSelect={() => handleMessageSubmit(EditorActionType.InternalReply)}
                                        >
                                            <EyeOffIcon className="mr-2 h-4 w-4" />
                                            Send as Internal
                                        </DropdownMenuItem>
                                        {gmailScopes?.length && gmailScopes?.length > 0 && (
                                            <Dialog open={openDialog} onOpenChange={setOpenDialog}>
                                                <DialogTrigger asChild>
                                                    <DropdownMenuItem
                                                        onSelect={(e) => e.preventDefault()}
                                                    >
                                                        <MailPlusIcon className="mr-2 h-4 w-4" />
                                                        Send New Email
                                                    </DropdownMenuItem>
                                                </DialogTrigger>

                                                <DialogContent>
                                                    <DialogHeader>
                                                        <h3 className="text-lg font-semibold">Send a New Email</h3>
                                                    </DialogHeader>

                                                    <div className="mt-2 flex items-center">
                                                        <div className="text-md font-semibold w-[60px] mr-2">To: </div>
                                                        <Input
                                                            type="email"
                                                            value={recipient}
                                                            onChange={(e) => setRecipient(e.target.value)}
                                                            placeholder="Recipient's Email"
                                                            className="w-full p-2 border border-gray-300 rounded text-xs ml-0"
                                                        />
                                                    </div>
                                                    <div className="mt-2 flex items-center">
                                                        <div className="text-md font-semibold w-[60px] mr-2">From: </div>
                                                        <Select defaultValue={integrationID}
                                                            onValueChange={handleSelectChange}>
                                                            <SelectTrigger
                                                                className="ml-1 focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1]">
                                                                <SelectValue />
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                {gmailScopes?.map(integration => (
                                                                    <SelectItem value={integration.key}>{integration.name}</SelectItem>
                                                                ))}
                                                            </SelectContent>
                                                        </Select>
                                                    </div>
                                                    <div className="mt-2 flex items-center">
                                                        <div className="text-md font-semibold w-[60px] mr-2">Subject: </div>
                                                        <Input
                                                            type="subject"
                                                            value={subject}
                                                            onChange={(e) => setSubject(e.target.value)}
                                                            placeholder="Email Subject"
                                                            className="w-full p-2 border border-gray-300 rounded text-xs ml-0"
                                                        />
                                                    </div>

                                                    <DialogFooter className="flex justify-between mt-4">
                                                        <DialogClose asChild>
                                                            <Button variant="outline" onClick={() => setOpenDialog(false)}>
                                                                Cancel
                                                            </Button>
                                                        </DialogClose>
                                                        <Button
                                                            onClick={handleEmailSubmit}
                                                            disabled={!recipient || loading}
                                                        >
                                                            {loading ? (
                                                                <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                                                            ) : (
                                                                'Submit'
                                                            )}
                                                        </Button>
                                                    </DialogFooter>
                                                </DialogContent>
                                            </Dialog>
                                        )}
                                    </DropdownMenuContent>
                                </div>
                            </DropdownMenu>
                        </div>
                    </TooltipTrigger>
                    {!isSendDisabled && (
                        <TooltipContent className="bg-[#5B5BD6] py-2.5 px-4 flex flex-col">
                            {isSendDisabled ?
                                "Send message!" : "Add text and check that all variables have been populated."}
                        </TooltipContent>
                    )}
                </Tooltip>
            </TooltipProvider >
        </div >
    );
}
