import { Card } from "@/component/shadcn/ui/card";
import type { Workflow } from "@/interfaces/serverData";
import { Badge } from "@radix-ui/themes";
import { ReactFlowProvider, useEdgesState, useNodesState } from "@xyflow/react";

import WorkflowEditor from "./WorkflowEditor";
import { lastTriggered, workflowEnabled, workflowFrequencyBadge, workflowTypeBadge } from "@/utilities/methods";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import { CaretUpIcon } from "@radix-ui/react-icons";

interface WorkflowDisplayProps {
    workflow: Workflow;
}

function WorkflowDisplay({ workflow }: WorkflowDisplayProps) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    return (
        <ReactFlowProvider>
            <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-4 py-2 shadow-sm border relative bg-white">
                <div className="flex items-center justify-between my-3">
                    <div className="flex flex-col items-start gap-1">
                        <div className="text-lg font-semibold flex items-center gap-2">
                            {workflowEnabled(workflow)}
                            {workflow.name}
                            {workflowTypeBadge(workflow)}
                            {lastTriggered(workflow)}
                            {workflowFrequencyBadge(workflow)}
                        </div>
                        <div className="text-sm text-gray11">
                            {workflow.description}
                        </div>
                    </div>
                </div>
                <div>
                    <WorkflowEditor
                        nodes={nodes}
                        edges={edges}
                        setNodes={setNodes}
                        setEdges={setEdges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        workflow={workflow}
                    />
                </div>
            </Card>
        </ReactFlowProvider>
    );
}

export default WorkflowDisplay;
