import { Checkbox } from "@/component/shadcn/ui/checkbox";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import type { Account, GetUserResponse, IconEntry } from "@/interfaces/serverData";
import { assigneeBadge, getBadgeForAccountsLabel, lastInteractionBadge } from "@/utilities/methods";
import { Badge } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import { GlobeIcon, ImageIcon, MailIcon } from "lucide-react";
import React from "react";
import { DataTableColumnHeader } from "../../../WorkspacePreferences/DataTable/data-table-column-header";
import { integrationBackEndDataMappingToSvg } from "../../Integrations/constant";
import { queryToLabel } from "./constants";

export function generateCustomerColumms(
    rowState: Map<string, Account>,
    users: GetUserResponse[],
): ColumnDef<Account>[] {
    const columns: ColumnDef<Account>[] = [
        {
            accessorKey: "select",
            header: ({ table }) => {
                const isAllSelected = table.getIsAllRowsSelected();
                const isSomeSelected = table.getIsSomeRowsSelected(); // Some rows selected, not all

                return (
                    <TooltipProvider><Tooltip>
                        <TooltipTrigger asChild>
                            <div className="flex items-center justify-center">
                                <Checkbox
                                    checked={isAllSelected || isSomeSelected}
                                    onCheckedChange={(checked) => {
                                        // Handle toggling all rows selection based on the checkbox state
                                        if (checked) {
                                            table.toggleAllRowsSelected(true);  // Select all rows
                                        } else {
                                            table.toggleAllRowsSelected(false); // Deselect all rows
                                        }
                                    }}
                                    className={`${(isAllSelected || isSomeSelected)
                                        ? "opacity-100"
                                        : "opacity-0"
                                        } ml-1 hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 lb-comment-details data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer`} // You can customize the appearance as needed
                                />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-[#5B5BD6]">
                            {(isAllSelected || isSomeSelected) ? "Deselect All" : "Select All"}
                        </TooltipContent>
                    </Tooltip>
                    </TooltipProvider>

                );
            },
            cell: ({ row, column }) => {
                // return <div className="hidden" />
                return (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={row.getToggleSelectedHandler()}
                        onClick={(event) => {
                            event.stopPropagation(); // Stop click event from bubbling up to the row
                        }}
                    />
                );
            },
            enableSorting: false,
            size: 0,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.name}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div
                        className="flex items-center gap-2 py-0.5"
                    >
                        {row.original.image_url !== "" &&
                            row.original.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-5 h-5">
                                <img
                                    className="lb-avatar-image"
                                    src={row.original.image_url}
                                    alt={row.original.name}
                                />

                                <span>{row.original.name ?? ""}</span>
                            </div>
                        ) : (
                            <div className="lb-avatar rounded w-6 h-6">
                                <ImageIcon className="w-5 h-5 mx-1" />
                            </div>
                        )}
                        {row.original.name !== "" &&
                            row.original.name !== undefined ? (
                            <p className="text-[13px] pl-1 font-semibold">
                                {row.original.name}
                            </p>
                        ) : (
                            <p className="text-xs pl-1 font-semibold">
                                <i>Untitled</i>
                            </p>
                        )}
                        {row.original.domain && (
                            row.original.account_type === "Customer" ? (
                                <Badge
                                    color="iris"
                                    size="1"
                                    radius="full"
                                    variant="outline"
                                    className="text-[11px] gap-1"
                                >
                                    <MailIcon className="w-2.5 h-2.5" />
                                    {row.original.domain}
                                </Badge>
                            ) : (
                                <Badge
                                    color="blue"
                                    size="1"
                                    radius="full"
                                    variant="outline"
                                    className="text-[11px] gap-1"
                                >
                                    <GlobeIcon className="w-2.5 h-2.5" />
                                    {row.original.domain}
                                </Badge>
                            )
                        )}
                    </div>
                );
            },

            filterFn: (row, id, value) => {
                // Return true to include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const query = row.original.name;
                return query.toLowerCase().includes(value.toLowerCase());
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.name.localeCompare(rowB.original.name);
            },
            enableSorting: true,
            size: 50,
        },
        {
            accessorKey: "accountType",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.accountType}
                    className="text-xs"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div
                        className="flex flex-row ml-3"
                    >
                        <Badge
                            color={
                                row.original.account_type === "Company"
                                    ? "blue"
                                    : "orange"
                            }
                            size="2"
                            radius="medium"
                            variant="outline"
                            className="text-xs items-center"
                        >
                            {row.original.account_type}
                        </Badge>
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.account_type.localeCompare(rowB.original.account_type);
            },
            enableSorting: true,
            size: 10,
        },
        {
            accessorKey: "labels",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.label}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex items-center gap-1">
                        {row.original.labels?.map(label => getBadgeForAccountsLabel(label, true)) || null}
                    </div>
                );
            },
            filterFn: (row, id, value) => {
                return row.original.labels?.some(label => label.name === value) || false;
            },
            enableResizing: true,
            size: 30,
        },
        {
            accessorKey: "Contract Value",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.contractValue}
                />
            ),
            cell: ({ row }) => {
                return (
                    row.original.contract_value !== 0 &&
                    row.original.contract_value !== undefined && (
                        <Badge
                            color="mint"
                            size="2"
                            radius="medium"
                            variant="soft"
                            className="text-xs items-center ml-2"
                        >
                            <p>
                                {row.original.contract_type} | $
                                {row.original.contract_value}{" "}
                            </p>
                        </Badge>
                    )
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.contract_value);
            },
            sortingFn: (rowA, rowB, columnId) => {
                return (
                    rowA.original.contract_value - rowB.original.contract_value
                );
            },
            enableSorting: true,
            enableResizing: true,
            size: 10,
        },
        {
            accessorKey: "last_interaction",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.lastInteraction}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="ml-3">
                        {row.original.last_ticket && lastInteractionBadge(row.original.last_ticket)}
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                const dateA = rowA.original.last_ticket?.created_at ? new Date(rowA.original.last_ticket.created_at).getTime() : 0;
                const dateB = rowB.original.last_ticket?.created_at ? new Date(rowB.original.last_ticket.created_at).getTime() : 0;
                return dateA - dateB;
            },
            enableResizing: true,
            size: 10,
        },
        {
            accessorKey: "sources",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.sources}
                    className="text-xs"
                />
            ),
            cell: ({ row }) => {
                const metadata = row.original.metadata ?? {};
                const keys = Object.keys(metadata);

                const serviceIcons = new Set<IconEntry>();
                // biome-ignore lint/complexity/noForEach: <explanation>
                keys.forEach((serviceName) => {
                    const IconComponent =
                        integrationBackEndDataMappingToSvg.get(serviceName);
                    if (IconComponent) {
                        serviceIcons.add({
                            Component: IconComponent,
                            props: {
                                width: 20,
                                height: 20,
                                style: {
                                    marginLeft: "-2px",
                                    marginRight: "-2px",
                                },
                            },
                        });
                    }
                });
                return (
                    <div
                        className="flex flex-row"
                    >
                        {Array.from(serviceIcons).map((icon) =>
                            React.createElement(icon.Component, icon.props),
                        )}
                    </div>
                );
            },
            enableSorting: false,
            size: 12,
        },
        {
            accessorKey: "contacts",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.contacts}
                    className="text-xs"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row overflow-x-hidden">
                        {row.original.associated_customers?.slice(0, 3).map(ac => {
                            return (
                                <div
                                    className="-mr-1"
                                    key={ac.id}
                                >
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                {ac.image_url !== "" &&
                                                    ac.image_url !== undefined ? (
                                                    <div className="lb-avatar rounded w-5 h-5">
                                                        <img
                                                            className="lb-avatar-image"
                                                            src={ac.image_url}
                                                            alt={ac.name}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="lb-avatar rounded w-6 h-6">
                                                        <ImageIcon className="w-5 h-5 mx-1" />
                                                    </div>
                                                )}
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6]">
                                                <p>{ac.name}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            )
                        })}
                        {/* Show ellipsis if there are more than 3 contacts */}
                        {row.original.associated_customers?.length && row.original.associated_customers?.length > 3 && (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <span className="ml-1">...</span>
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-[#5B5BD6]">
                                        <p>{`+${row.original.associated_customers?.length - 3} More Contact${row.original.associated_customers?.length - 3 > 1 ? "s" : ""}`}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </div>
                );
            },
            enableSorting: false,
            size: 15,
        },
        {
            accessorKey: "assignee",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.assignee}
                    className="text-xs"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-row overflow-x-hidden">
                        {assigneeBadge(row.original.assignee_user_id, users)}
                    </div>
                );
            },
            enableSorting: false,
            size: 15,
        },
    ];
    return columns;
}
