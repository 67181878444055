import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent, CardTitle } from "@/component/shadcn/ui/card";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";

import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";

import type {
    CustomerGroup,
    CustomerGroupDeletePayload,
    ScopeResponse,
} from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
    DotsHorizontalIcon,
    Pencil2Icon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
import CustomerGroupPopup from "./CustomerGroupPopup";
import type { CustomerGroupInfo } from "./CustomerGroups";

interface CustomerGroupProps {
    customerGroup: CustomerGroup;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    setCustomerGroups: React.Dispatch<React.SetStateAction<CustomerGroup[]>>;
}

const CustomerGroupCard: React.FC<CustomerGroupProps> = ({
    customerGroup,
    setOpen,
    setToastSymbol,
    setToastText,
    setCustomerGroups,
}) => {
    const timerRef = useRef(0);
    const api = useApi();

    const [sourceMetadata, setSourceMetadata] = useState<CustomerGroupInfo[]>(
        [],
    );

    const [sourceMetadataLoading, setSourceMetadataLoading] =
        useState<loadingTypes>(loadingTypes.loading);

    useEffect(() => {
        const fetchData = async () => {
            if (!customerGroup) {
                return;
            }

            if (
                customerGroup.metadata === "" ||
                customerGroup.metadata === undefined
            ) {
                setSourceMetadataLoading(loadingTypes.loaded);
                return;
            }

            try {
                const data = JSON.parse(customerGroup.metadata);
                const infos: CustomerGroupInfo[] = await Promise.all(
                    Object.entries(data).map(
                        async ([serviceName, serviceData]: [
                            string,
                            ScopeResponse[],
                        ]) => {
                            const icon =
                                integrationBackEndDataMappingToSvg.get(
                                    serviceName,
                                );
                            const response = await api.get(
                                `${URLS.serverUrl}${API.getBotSettingsV2}/${serviceName}`,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                },
                            );

                            const options: ScopeResponse[] =
                                response.status === 200
                                    ? response.data.data?.asm_ticket_channels ||
                                    []
                                    : [];
                            const selectedChannels: ScopeResponse[] =
                                serviceData;
                            const filters: Map<string, Set<string>> = new Map();

                            return {
                                selectedSource: serviceName,
                                icon,
                                options,
                                selectedChannels,
                                filters,
                            };
                        },
                    ),
                );
                setSourceMetadata(infos);
            } catch (error) {
                setSourceMetadataLoading(loadingTypes.error);
                console.error("Error fetching or parsing data:", error);
            } finally {
                console.log("loader set to loaded");
                setSourceMetadataLoading(loadingTypes.loaded);
            }
        };

        fetchData();
    }, [customerGroup, api]);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const deleteCustomerGroup = async () => {
        const requestData: CustomerGroupDeletePayload = {
            operation: "DELETE",
            id: customerGroup.id ?? "",
        };
        api.post(`${URLS.serverUrl}${API.saveCustomerGroup}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastSymbol(CheckCircledIcon);
                    setToastText("Updated Settings!");
                    setCustomerGroups(res.data.data);
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setOpen(true);
                }, 100);
            });
    };

    const SvgIcon = integrationBackEndDataMappingToSvg.get(
        customerGroup.integration,
    );

    return (
        <Card className="shadow-none p-3">
            <CardTitle className="pb-1 pl-1">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col gap-1">
                        {customerGroup.user_id !== "Web" ? (
                            <p className="text-sm font-medium">
                                {customerGroup.group_name}
                            </p>
                        ) : (
                            <div className="flex flex-row gap-1 items-center">
                                <p className="text-sm font-medium">
                                    {customerGroup.group_name}
                                </p>
                                {SvgIcon !== undefined && (
                                    <SvgIcon className="w-4 h-4" />
                                )}
                            </div>
                        )}

                        <p className="text-xs font-small text-gray10">
                            {customerGroup.description}
                        </p>
                    </div>
                    {customerGroup.editable && (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button
                                    variant="ghost"
                                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                >
                                    <DotsHorizontalIcon className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                align="end"
                                className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <DropdownMenuItem asChild>
                                    <AlertDialog
                                        open={deleteDialogOpen}
                                        onOpenChange={setDeleteDialogOpen}
                                    >
                                        <AlertDialogTrigger asChild>
                                            <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                                <p className="flex-grow">
                                                    Delete
                                                </p>
                                                <TrashIcon className="justify-self-end" />
                                            </div>
                                        </AlertDialogTrigger>
                                        <AlertDialogContent>
                                            <div>
                                                <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                    <AlertDialogTitle>
                                                        Are you absolutely
                                                        sure?
                                                    </AlertDialogTitle>
                                                    <AlertDialogDescription>
                                                        This action cannot
                                                        be undone. This will
                                                        permanently delete
                                                        this CustomerGroup.
                                                    </AlertDialogDescription>
                                                </AlertDialogHeader>
                                            </div>
                                            <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                <AlertDialogCancel>
                                                    Cancel
                                                </AlertDialogCancel>
                                                <AlertDialogAction
                                                    onClick={
                                                        deleteCustomerGroup
                                                    }
                                                >
                                                    Delete
                                                </AlertDialogAction>
                                                <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                    <Cross2Icon />
                                                </AlertDialogCancel>
                                            </AlertDialogFooter>
                                        </AlertDialogContent>
                                    </AlertDialog>
                                </DropdownMenuItem>
                                <DropdownMenuItem asChild>
                                    <CustomerGroupPopup
                                        triggerElement={
                                            <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                                <p className="flex-grow">
                                                    Edit
                                                </p>
                                                <Pencil2Icon className="justify-self-end" />
                                            </div>
                                        }
                                        editing={true}
                                        sourceFields={sourceMetadata}
                                        customerGroup={customerGroup}
                                        setToastText={setToastText}
                                        setToastSymbol={setToastSymbol}
                                        setToastOpen={setOpen}
                                        setCustomerGroups={setCustomerGroups}
                                    />
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
            </CardTitle>
            <CardContent>
                <div className="flex flex-row flex-wrap pt-1">
                    {sourceMetadata.map((sourceInfo, index) => {
                        return (
                            <div key={`index-${index}`}>
                                {sourceInfo.selectedChannels.map((item) => (
                                    <Badge
                                        color="gray"
                                        size="1"
                                        radius="full"
                                        variant="outline"
                                        className="gap-1 m-2 px-2 py-1"
                                        key={item.name}
                                    >
                                        {sourceInfo.icon && (
                                            <sourceInfo.icon className="w-4 h-4" />
                                        )}
                                        {item.name}
                                    </Badge>
                                ))}{" "}
                            </div>
                        );
                    })}
                </div>
            </CardContent>
        </Card>
    );
};

export default CustomerGroupCard;
