import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    CustomerGroup,
    CustomerGroupMetadata,
    GetTopicsResponse,
    GetUserResponse,
    QueriesWithPaginationResponse,
    Query,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { arraysAreEqual } from "@/utilities/methods";
import {
    saveAssignee,
    saveStatus,
    saveTag,
    toggleTopicSelection,
} from "@/utilities/methods";
import {
    type InfiniteData,
    type QueryObserverResult,
    type RefetchOptions,
    type UseQueryResult,
    useQuery,
} from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import FilterBar from "./FilterBar";
import IssuesListCard from "./IssuesListCard";
import { IssuesListHeaderCard } from "./IssuesListHeaderCard";
import { HeaderType } from "./constants";

const areTopicsEqual = (topic1: Topic, topic2: Topic): boolean => {
    return (
        topic1.color === topic2.color &&
        topic1.label === topic2.label &&
        topic1.value === topic2.value
    );
};

const getStableItemData = (
    listItems: { key: string; content: JSX.Element }[],
    selectedIssues: IssueGroupInfo[],
    handleIssueSelect: (
        checked: boolean,
        issueId: string,
        group: string,
    ) => void,
) => ({
    items: listItems,
    selectedIssues,
    handleIssueSelect,
});

const areEqual = (prevProps: IssuesListProps, nextProps: IssuesListProps) => {
    return (
        arraysAreEqual(prevProps.issues, nextProps.issues) &&
        prevProps.topics.length === nextProps.topics.length &&
        prevProps.topics.every((topic, index) =>
            areTopicsEqual(topic, nextProps.topics[index]),
        ) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.userID === nextProps.userID &&
        arraysAreEqual(
            prevProps.usersQuery.data ?? [],
            nextProps.usersQuery.data ?? [],
        ) &&
        arraysAreEqual(
            prevProps.customerGroupsQuery.data ?? [],
            nextProps.customerGroupsQuery.data ?? [],
        ) &&
        prevProps.refetch === nextProps.refetch &&
        prevProps.onFilterChange === nextProps.onFilterChange &&
        prevProps.onGroupChange === nextProps.onGroupChange &&
        prevProps.inputFilters === nextProps.inputFilters &&
        prevProps.inputGrouping === nextProps.inputGrouping &&
        prevProps.listType === nextProps.listType &&
        prevProps.viewID === nextProps.viewID &&
        prevProps.listHeight === nextProps.listHeight
    );
};

export enum Grouping {
    Status = "ticket_status",
    Owner = "assignee_user_id",
    Source = "source",
    None = "None",
}

interface IssuesListProps {
    issues: Query[];
    topics: Topic[];
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    listType: IssueListType;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
    customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
    teamsQuery: UseQueryResult<Teams[], Error>;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<
        QueryObserverResult<
            InfiniteData<QueriesWithPaginationResponse, unknown>,
            Error
        >
    >;
    onFilterChange?: (filters: Map<string, Set<string>>) => void; // New prop
    onGroupChange?: (grouping: Grouping) => void; // New prop
    inputFilters?: Map<string, Set<string>>;
    inputGrouping?: string;
    viewID?: string;
    teamID?: string;
    listHeight?: number;
}

export interface IssueGroupInfo {
    issueId: string;
    group: string;
}

function IssuesList({
    issues,
    topics,
    topicsMap,
    userID,
    listType,
    usersQuery,
    channelsQuery,
    customerGroupsQuery,
    teamsQuery,
    refetch,
    onFilterChange,
    onGroupChange,
    inputFilters,
    inputGrouping,
    viewID,
    teamID,
    listHeight,
}: IssuesListProps) {
    const [filters, setFilters] = useState<Map<string, Set<string>>>(
        inputFilters ?? new Map(),
    );

    const api = useApi();
    const categoryOptionsQuery = useQuery<Category[]>({
        queryKey: ["categories"],
        queryFn: async () => {
            const response = await api.get(
                `${URLS.serverUrl}${API.getCategories}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        },
    });

    const [searchQuery, setSearchQuery] = useState<string>("");
    // Default: Grouping by status
    const [grouping, setGrouping] = useState<Grouping>(
        (inputGrouping as Grouping) ?? Grouping.Status,
    );
    const [groupingExpanded, setGroupingExpanded] = useState<
        Map<string, boolean>
    >(new Map());
    const [issuesByGroup, setIssuesByGroup] = useState<
        Map<string, Map<string, Query>>
    >(new Map()); // Map<groupKey, Map<issueId, issue>>
    const listRef = useRef<VariableSizeList>(null);

    const [, setListWidth] = useState<number>(window.innerWidth);
    const containerRef = useRef<HTMLDivElement>(null);

    const [selectedIssues, setSelectedIssues] = useState<IssueGroupInfo[]>([]);
    const [lastSelectedIssue, setLastSelectedIssue] =
        useState<IssueGroupInfo | null>(null);

    const handleIssueSelect = useCallback(
        (
            checked: boolean,
            issueId: string,
            group: string,
            event?: React.MouseEvent,
        ) => {
            const currentIssue = { issueId, group };

            setSelectedIssues((prev) => {
                if (event?.shiftKey && lastSelectedIssue) {
                    // Flatten the issues into a single array with their indices
                    const flattenedIssues: {
                        issueId: string;
                        group: string;
                    }[] = [];
                    issuesByGroup.forEach((groupIssues, groupKey) =>
                        groupIssues.forEach((_, id) =>
                            flattenedIssues.push({
                                issueId: id,
                                group: groupKey,
                            }),
                        ),
                    );

                    // Find the range of indices between the last selected issue and the current one
                    const startIdx = flattenedIssues.findIndex(
                        (issue) =>
                            issue.issueId === lastSelectedIssue.issueId &&
                            issue.group === lastSelectedIssue.group,
                    );
                    const endIdx = flattenedIssues.findIndex(
                        (issue) =>
                            issue.issueId === currentIssue.issueId &&
                            issue.group === currentIssue.group,
                    );

                    if (startIdx === -1 || endIdx === -1) return prev;

                    const [rangeStart, rangeEnd] = [
                        Math.min(startIdx, endIdx),
                        Math.max(startIdx, endIdx),
                    ];

                    const issuesInRange = flattenedIssues.slice(
                        rangeStart,
                        rangeEnd + 1,
                    );

                    if (checked) {
                        // Add new range to the selection, avoiding duplicates
                        const newSelection = new Map(
                            prev.map((issue) => [
                                `${issue.group}-${issue.issueId}`,
                                issue,
                            ]),
                        );
                        for (const issue of issuesInRange) {
                            newSelection.set(
                                `${issue.group}-${issue.issueId}`,
                                issue,
                            );
                        }
                        return Array.from(newSelection.values());
                    } else {
                        // Remove range from the selection
                        const rangeSet = new Set(
                            issuesInRange.map(
                                (issue) => `${issue.group}-${issue.issueId}`,
                            ),
                        );
                        return prev.filter(
                            (issue) =>
                                !rangeSet.has(
                                    `${issue.group}-${issue.issueId}`,
                                ),
                        );
                    }
                }

                if (checked) {
                    return [...prev, currentIssue];
                } else {
                    return prev.filter(
                        (issue) =>
                            issue.issueId !== issueId || issue.group !== group,
                    );
                }
            });

            setLastSelectedIssue(currentIssue);
        },
        [lastSelectedIssue, issuesByGroup],
    );

    // const handleSelectAll = useCallback( // add support later
    //     (checked: boolean) => {
    //         if (checked) {
    //             const allVisibleIssueIds = new Set<string>();
    //             issuesByGroup.forEach((groupIssues) => {
    //                 groupIssues.forEach((issue) => {
    //                     allVisibleIssueIds.add(issue.id);
    //                 });
    //             });
    //             setSelectedIssues(allVisibleIssueIds);
    //         } else {
    //             setSelectedIssues(new Set());
    //         }
    //     },
    //     [issuesByGroup],
    // );

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                const newWidth = containerRef.current.offsetWidth;
                setListWidth(newWidth);
                if (listRef.current) {
                    listRef.current.resetAfterIndex(0);
                }
            }
        };

        updateWidth();

        const resizeObserver = new ResizeObserver(updateWidth);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const updateFilters = (newFilters: Map<string, Set<string>>) => {
        setFilters(newFilters);
        if (onFilterChange) {
            onFilterChange(newFilters); // Pass filters up to parent
        }
    };

    const updateGrouping = (newGrouping: Grouping) => {
        setGrouping(newGrouping);
        if (onGroupChange) {
            onGroupChange(newGrouping); // Pass grouping up to parent
        }
    };

    useEffect(() => {
        setFilters(inputFilters ?? new Map());
    }, [viewID]);

    useEffect(() => {
        const result = new Map<string, Map<string, Query>>();
        const mustKeys = ["Breaching", "NeedsResponse", "Open", "Closed"];

        // Initialize with empty arrays for mustKeys if grouping by Status
        if (grouping === Grouping.Status) {
            // biome-ignore lint/complexity/noForEach: <explanation>
            mustKeys.forEach((key) => {
                result.set(key, new Map());
            });
        }

        // Filter issues based on the current filters and search query
        const visibleIssues = issues.filter((issue) => {
            let matchesFilters = true;
            const entries = Array.from(filters.entries());
            for (const [type, values] of entries) {
                matchesFilters =
                    matchesFilters && shouldInclude(type, values, issue);
            }

            // Check if the issue matches the search query
            const matchesSearch =
                searchQuery.trim() === "" ||
                issue.title
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                issue.query
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                `${issue.ticket_identifier}-${issue.ticket_number}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());

            return matchesFilters && matchesSearch;
        });

        for (const issue of visibleIssues) {
            // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
            let groupVal;

            // Determine group value based on current grouping
            if (grouping === Grouping.Owner) {
                const assignedUser = usersQuery.data?.find(
                    (user) => user.id === issue.assignee_user_id,
                );
                groupVal = assignedUser
                    ? `${assignedUser.first_name} ${assignedUser.last_name}`
                    : "Unassigned";
            } else {
                groupVal =
                    grouping === Grouping.None
                        ? "All"
                        : issue[grouping as keyof Query];
            }

            const groupKey = groupVal ? groupVal.toString() : "None";
            if (!result.has(groupKey)) {
                result.set(groupKey, new Map());
            }
            if (!result.get(groupKey)?.has(issue.id)) {
                result.get(groupKey)?.set(issue.id, issue);
            } else {
                result.get(groupKey)?.set(issue.id, issue);
            }
        }
        setIssuesByGroup(result);

        // Initialize groupingExpanded
        const newGroupingExpanded = new Map<string, boolean>();
        result.forEach((_, key) => {
            newGroupingExpanded.set(key, true);
        });
        setGroupingExpanded(newGroupingExpanded);
    }, [grouping, issues, usersQuery.data, filters, searchQuery]);

    function shouldInclude(
        type: string,
        values: Set<string>,
        issue: Query,
    ): boolean {
        switch (type) {
            case "Source": {
                const issueChannel = issue.source_specific_id.split("_")[0];
                const channelsTotal = channelsQuery?.data ?? new Map();

                let isIncluded = false;
                for (const value of Array.from(values)) {
                    const [source, channel_name, channel_id] =
                        value.split(" -- ");
                    if (!channel_name && !channel_id) {
                        isIncluded =
                            isIncluded || issue.source === source?.trim();
                    } else {
                        const ch =
                            channelsTotal instanceof Map
                                ? channelsTotal
                                    .get(source?.trim())
                                    ?.find(
                                        (elem: ScopeResponse) =>
                                            elem.name ===
                                            channel_name?.trim() &&
                                            elem.key === channel_id?.trim(),
                                    )
                                : undefined;
                        if (
                            channel_name === "All Channels" ||
                            channel_name === "All Emails" ||
                            channel_name === "All API Keys"
                        ) {
                            isIncluded =
                                isIncluded || issue.source === source?.trim();
                        } else {
                            switch (issue.source) {
                                case "CommunitySlack":
                                case "Discord":
                                case "Slack": {
                                    isIncluded =
                                        isIncluded ||
                                        (issue.source === source?.trim() &&
                                            issueChannel === ch?.key);
                                    break;
                                }
                                case "Gmail":
                                case "API": {
                                    isIncluded =
                                        isIncluded ||
                                        (issue.source === source?.trim() &&
                                            issue.integration_id === ch?.key);
                                    break;
                                }
                            }
                        }
                    }
                }
                return isIncluded;
            }
            case "Tag": {
                return !!values.has(issue.bot_category);
            }
            case "Topic": {
                for (const topic of issue.topic) {
                    if (values.has(topic)) {
                        return true; // At least one topic is in the filter set
                    }
                }
                return false;
            }
            case "Status": {
                return !!values.has(issue.ticket_status);
            }
            case "Assignee": {
                return !!(
                    (!issue.assignee_user_id && values.has("noAssignee")) ||
                    (issue.assignee_user_id &&
                        values.has(issue.assignee_user_id))
                );
            }
            case "Assembly Bot": {
                return !!issue.assembly_responded; // Convert to boolean
            }
            case "Customer Group": {
                // Will only contain channels from slack, discord, and community slack
                const channel = issue.source_specific_id.split("_")[0];
                const customerGroups = customerGroupsQuery.data ?? [];
                for (const value of Array.from(values)) {
                    const cg = customerGroups.find(
                        (elem) => elem.group_name === value,
                    );
                    let slackChannels: string[] = [];
                    let discordChannels: string[] = [];
                    let communitySlackChannels: string[] = [];
                    if (cg?.metadata) {
                        const metadata: CustomerGroupMetadata = JSON.parse(
                            cg?.metadata ?? "",
                        );
                        slackChannels =
                            metadata.Slack?.map((elem) => elem.key ?? "") ?? [];
                        discordChannels =
                            metadata.Discord?.map((elem) => elem.key ?? "") ??
                            [];
                        communitySlackChannels =
                            metadata.CommunitySlack?.map(
                                (elem) => elem.key ?? "",
                            ) ?? [];
                        switch (issue.source) {
                            case "Slack": {
                                return slackChannels.includes(channel);
                            }
                            case "Discord": {
                                return discordChannels.includes(channel);
                            }
                            case "CommunitySlack": {
                                return communitySlackChannels.includes(channel);
                            }
                            default: {
                                return false;
                            }
                        }
                    } else if (cg?.group_name === "All Slack Accounts") {
                        return issue.source === "Slack";
                    } else if (cg?.group_name === "All Discord Accounts") {
                        return issue.source === "Discord";
                    } else if (
                        cg?.group_name === "All Community Slack Accounts"
                    ) {
                        return issue.source === "CommunitySlack";
                    }
                }
                return true;
            }
            case "Team":
                // All issues should be included in the general team
                if (values.has("General")) {
                    return true;
                }
                for (const team of issue.teams) {
                    if (values.has(team.team_name)) {
                        return true; // At least one team is in the filter set
                    }
                }
                return false;
            default:
                return false; // Handle any unmatched case
        }
    }

    const updateExpanded = useCallback((groupKey: string, value: boolean) => {
        setGroupingExpanded((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(groupKey, value);
            return newMap;
        });
    }, []);

    const headerHeight = 50;
    const customHeight = 80;

    const getIdealHeight = (height: number): number => {
        if (height > 800) {
            return height * 0.73;
        } else {
            const digit = Math.floor(height / 100);
            console.log(0.1 * digit);

            return height * (0.1 * digit);
        }
    };

    const getItemSize = useCallback(
        (index: number) => {
            const groupKeys = Array.from(issuesByGroup.keys());
            let currentIndex = 0;

            for (const groupKey of groupKeys) {
                if (index === currentIndex) {
                    return headerHeight;
                }
                currentIndex++;
                if (groupingExpanded.get(groupKey)) {
                    const groupSize = issuesByGroup.get(groupKey)?.size || 0;
                    if (index < currentIndex + groupSize) {
                        return customHeight;
                    }
                    currentIndex += groupSize;
                }
            }
            return 0;
        },
        [issuesByGroup, groupingExpanded],
    );

    const setIssueStateHelper = useCallback(
        (
            newState: Partial<Query>,
            updatedMap: Map<string, Map<string, Query>>,
            issueGroup: IssueGroupInfo,
        ) => {
            const currentIssue = updatedMap
                .get(issueGroup.group)
                ?.get(issueGroup.issueId);

            if (!currentIssue) {
                console.error(`Issue with ID ${issueGroup.issueId} not found.`);
                return;
            }

            const updatedIssue = {
                ...currentIssue,
                ...newState,
                bot_category:
                    newState.bot_category || currentIssue.bot_category,
                topic: newState.topic
                    ? newState.topic.sort()
                    : currentIssue.topic,
                ticket_status:
                    newState.ticket_status || currentIssue.ticket_status,
                assignee_user_id:
                    newState.assignee_user_id || currentIssue.assignee_user_id,
            };

            updatedMap
                .get(issueGroup.group)
                ?.set(issueGroup.issueId, updatedIssue);
        },
        [],
    );

    const saveIssue = useCallback(
        (
            type: string,
            value: string,
            api: AxiosInstance,
            updateIssueState: (newState: Partial<Query>) => void,
            userID: string,
            refetch: (
                options?: RefetchOptions,
            ) => Promise<
                QueryObserverResult<
                    InfiniteData<QueriesWithPaginationResponse, unknown>,
                    Error
                >
            >,
            issueId: string,
            issueState: Query,
            topicsMap?: Map<string, GetTopicsResponse>,
        ) => {
            let selectedIssueIds: string[];
            if (selectedIssues.length === 0) {
                selectedIssueIds = [issueId];
            } else {
                selectedIssueIds = selectedIssues.map((issue) => issue.issueId);
            }
            switch (type) {
                case "Tag":
                    return saveTag(
                        value,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        userID,
                    );
                case "Topic": // only support single topic for one row (no multi selection temporarily)
                    return toggleTopicSelection(
                        value,
                        issueState,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        userID,
                        topicsMap ?? new Map(),
                    );
                case "Status":
                    return saveStatus(
                        value,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        userID,
                        refetch,
                    );
                case "Assignee":
                    return saveAssignee(
                        value,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        userID,
                    );
                default:
                    return null;
            }
        },
        [selectedIssues],
    );

    const setIssueState = useCallback(
        (newState: Partial<Query>, issueGroup: IssueGroupInfo) => {
            setIssuesByGroup((prevIssuesMap) => {
                const updatedMap = new Map(prevIssuesMap);
                if (selectedIssues.length === 0) {
                    // single issue
                    setIssueStateHelper(newState, updatedMap, issueGroup);
                } else {
                    // selected issues with selector
                    for (const val of selectedIssues) {
                        setIssueStateHelper(newState, updatedMap, val);
                    }
                }
                return updatedMap;
            });
            setSelectedIssues([]);
        },
        [selectedIssues, setIssueStateHelper],
    );

    const listItems = useMemo(() => {
        const items: { key: string; content: JSX.Element }[] = [];
        issuesByGroup.forEach((groupIssues, groupKey) => {
            // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
            let headerKey;
            if (
                ["Closed", "NeedsResponse", "Open", "Breaching"].includes(
                    groupKey,
                )
            ) {
                headerKey = HeaderType.Status;
            } else {
                headerKey = HeaderType.Other;
            }
            items.push({
                key: `header-${groupKey}`,
                content: (
                    <IssuesListHeaderCard
                        key={`header-${
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            groupKey
                            }`}
                        title={groupKey}
                        value={groupKey}
                        header_type={headerKey}
                        isExpanded={groupingExpanded}
                        updateExpanded={updateExpanded}
                    />
                ),
            });

            if (groupingExpanded.get(groupKey)) {
                // biome-ignore lint/complexity/noForEach: <explanation>
                groupIssues.forEach((issue) => {
                    items.push({
                        key: issue.id,
                        content: (
                            <IssuesListCard
                                key={issue.id}
                                categories={categoryOptionsQuery.data ?? []}
                                issue={issue}
                                topics={topics}
                                topicsMap={topicsMap}
                                userID={userID}
                                users={usersQuery?.data ?? []}
                                teams={teamsQuery?.data ?? []}
                                refetch={refetch}
                                listType={listType}
                                teamID={teamID}
                                viewID={viewID}
                                isSelected={selectedIssues.some(
                                    (selectedIssue) =>
                                        selectedIssue.issueId === issue.id &&
                                        selectedIssue.group === groupKey,
                                )}
                                onSelect={handleIssueSelect}
                                issueUpdate={setIssueState}
                                saveIssue={saveIssue}
                            />
                        ),
                    });
                });
            }
        });
        return items;
    }, [
        issuesByGroup,
        groupingExpanded,
        updateExpanded,
        topics,
        categoryOptionsQuery.data,
        refetch,
        teamID,
        teamsQuery?.data,
        listType,
        topicsMap,
        userID,
        usersQuery?.data,
        selectedIssues,
        handleIssueSelect,
        setIssueState,
        saveIssue,
    ]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [grouping, groupingExpanded]);

    const itemData = useMemo(
        () => getStableItemData(listItems, selectedIssues, handleIssueSelect),
        [listItems, selectedIssues, handleIssueSelect],
    );

    // Create a stable item renderer
    const ItemRenderer = useCallback(
        ({ index, style }: { index: number; style: React.CSSProperties }) => {
            const item = itemData.items[index];
            return (
                <div style={style} key={item.key}>
                    {item.content}
                </div>
            );
        },
        [itemData],
    );

    return (
        <div
            className="my-2 rounded-sm w-full flex flex-col"
            ref={containerRef}
        >
            <FilterBar
                issues={issues}
                setFilters={updateFilters}
                categories={categoryOptionsQuery.data ?? []}
                filters={filters}
                topics={topics}
                users={usersQuery?.data ?? []}
                customerGroups={customerGroupsQuery.data ?? []}
                teams={teamsQuery?.data ?? []}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                savedViewFilters={inputFilters ?? new Map()}
                isGeneralTeam={
                    listType === IssueListType.Issues ||
                    listType === IssueListType.Inbox
                }
                channels={channelsQuery?.data ?? new Map()}
            />

            <ScrollArea className="w-full">
                <VariableSizeList
                    ref={listRef}
                    width={"100%"}
                    height={listHeight ?? getIdealHeight(window.innerHeight)}
                    itemCount={listItems.length}
                    itemSize={getItemSize}
                    overscanCount={5}
                    itemData={itemData}
                >
                    {ItemRenderer}
                </VariableSizeList>
            </ScrollArea>
        </div>
    );
}

export default memo(IssuesList, areEqual);
