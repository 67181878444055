import { Action } from "@/Ticket/Action";
import { Button } from "@/component/shadcn/ui/button";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
} from "@/component/shadcn/ui/sheet";
import type {
    GetOnboardingResponse,
    GetTopicsResponse,
    GetUserResponse,
    HistoryResponse,
    OnboardingChecklist,
    OnboardingChecklistPayload,
} from "@/interfaces/serverData";
import { type RefetchOptions, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { OnboardingCard } from "./OnboardingCard";

import { URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import { useApi } from "@/interfaces/api";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";

interface OnboardingDrawerProps {
    item: OnboardingChecklist;
    users: GetUserResponse[];
    saveIssue: (
        type: string,
        payload: Partial<OnboardingChecklistPayload>,
        userID: string,
        updateIssueState: (
            newState: Partial<OnboardingChecklist>,
            oldItem: OnboardingChecklist,
        ) => void,
        refetch: (options?: RefetchOptions) => Promise<OnboardingChecklist[]>,
        issueId: string,
        item: OnboardingChecklist,
        topicsMap?: Map<string, GetTopicsResponse>,
    ) => void;
    userID: string;
    isSelected: boolean;
    onSelect: (
        checked: boolean,
        issueId: string,
        event?: React.MouseEvent,
    ) => void;
    refetch: (options?: RefetchOptions) => Promise<GetOnboardingResponse>;
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void;
    deleteIssue: (
        userID: string,
        issueId: string,
        item: OnboardingChecklist,
    ) => void;
    onAddChild: (parentId: string) => void;
    setTopLevelEditing: (editing: { edit: boolean; parentId: string }) => void;
}

export const OnboardingWrapper = ({
    item,
    users,
    saveIssue,
    userID,
    isSelected,
    onSelect,
    refetch,
    updateIssueState,
    deleteIssue,
    onAddChild,
    setTopLevelEditing,
}: OnboardingDrawerProps) => {
    const renderComment = (comment: HistoryResponse) => {
        switch (comment.type) {
            case "Action":
                return (
                    <li className="mb-4 ml-6 ms-6">
                        <Action
                            key={comment.id}
                            comment={comment}
                            type="onboarding"
                            className="px-0"
                        />
                    </li>
                );
            default:
        }
    };

    const api = useApi();

    const fetchOnboardingChecklistHistory = async (): Promise<
        HistoryResponse[]
    > => {
        try {
            const { url } = ContactsAPI.getOnboardingChecklistHistory;
            const response = await api.get(
                `${URLS.serverUrl}${url}/${item.id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return [];
        } catch (error) {
            console.error("Error fetching queries:", error);
            return [];
        }
    };

    const {
        data: onboardingChecklistHistory,
        isLoading: loadingOnboardingChecklistHistory,
        isError: errorOnboardingChecklistHistory,
        refetch: refetchOnboardingChecklistHistory,
    } = useQuery({
        queryKey: ["onboardingChecklistHistory", item.id],
        queryFn: fetchOnboardingChecklistHistory,
        refetchInterval: 100000,
        refetchOnWindowFocus: true,
    });

    console.log(errorOnboardingChecklistHistory);

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [onboardingChecklistHistory]);

    const [showAll, setShowAll] = useState(false);

    const [sheetOpen, setSheetOpen] = useState(false);

    return (
        <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
            <div className="w-full">
                <OnboardingCard
                    setTopLevelEditing={setTopLevelEditing}
                    item={item}
                    users={users}
                    saveIssue={saveIssue}
                    userID={userID}
                    isSelected={isSelected}
                    onSelect={onSelect}
                    refetch={refetch}
                    updateIssueState={updateIssueState}
                    deleteIssue={deleteIssue}
                    setSheetOpen={setSheetOpen}
                    onAddChild={onAddChild}
                />
            </div>
            <SheetContent side="right" className="min-w-[500px]">
                <SheetHeader>
                    <SheetTitle>{item.name}</SheetTitle>
                    <SheetDescription>{item.description}</SheetDescription>
                </SheetHeader>

                {loadingOnboardingChecklistHistory ? (
                    <div>Loading...</div>
                ) : (
                    onboardingChecklistHistory && (
                        <>
                            <div
                                className="mt-4 max-h-[300px] overflow-y-auto"
                                ref={scrollRef}
                            >
                                <ol className="ml-5 relative border-s border-gray-200 dark:border-gray-700">
                                    {onboardingChecklistHistory
                                        .slice(showAll ? 0 : -3)
                                        .map((comment) =>
                                            renderComment(comment),
                                        )}
                                </ol>
                            </div>

                            {onboardingChecklistHistory.length > 3 && (
                                <div className="flex justify-center mt-4">
                                    <Button
                                        variant="outline"
                                        onClick={() => setShowAll(!showAll)}
                                        className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                        size="sm"
                                    >
                                        {showAll
                                            ? "Show Less"
                                            : `Show ${onboardingChecklistHistory.length - 3} More`}
                                    </Button>
                                </div>
                            )}
                        </>
                    )
                )}
                {errorOnboardingChecklistHistory && <AssemblyErrorMessage />}
            </SheetContent>
        </Sheet>
    );
};
