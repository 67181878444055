import { Button } from "@/component/shadcn/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import { Input } from "@/component/shadcn/ui/input";
import { Separator } from "@/component/shadcn/ui/separator";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { Pencil2Icon } from "@radix-ui/react-icons";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { Switch } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

export interface AutocloseCardProps {
    team_id?: string;
}

export type TimeUnit = "seconds" | "minutes" | "hours" | "days" | "never";

interface AutocloseState {
    id: string;
    enabled: boolean;
    time: number;
    unit: TimeUnit;
}

export function AutocloseCard({ team_id = "" }: AutocloseCardProps) {
    const api = useApi();
    const [isEditing, setIsEditing] = useState(false);

    const { data, isLoading, isError, refetch } = useQuery<AutocloseState>({
        queryKey: ["autoclose", team_id],
        queryFn: async () => {
            let url = `${URLS.serverUrl}${API.getAutocloseSettings}`;
            if (team_id !== "") {
                url += `/${team_id}`;
            }
            const resp = await api.get(url);
            if (resp.status === 200) {
                return resp.data.data as AutocloseState;
            } else {
                throw new Error("Failed to fetch autoclose settings");
            }
        },
    });

    const [enabled, setEnabled] = useState<boolean>(false);
    const [notificationTimeInput, setNotificationTimeInput] =
        useState<string>("30");
    const [notificationTimeUnit, setNotificationTimeUnit] =
        useState<TimeUnit>("days");

    useEffect(() => {
        if (data) {
            setEnabled(data.enabled);
            setNotificationTimeInput(data.time?.toString() || "30");
            setNotificationTimeUnit(data.unit || "days");
        }
    }, [data]);

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [toastText, setToastText] = useState<string>("");
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);
    const timerRef = useRef(0);

    const handleSave = async (enabledParam: boolean) => {
        if (
            notificationTimeUnit !== "never" &&
            Number.parseInt(notificationTimeInput) <= 0
        ) {
            setToastText("Please enter a valid time and try again.");
            setToastSymbol(CrossCircledIcon);

            window.clearTimeout(timerRef.current);
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
            return;
        }

        const dataToSave: Partial<AutocloseState> = {
            id: team_id,
            enabled: enabledParam,
        };

        if (notificationTimeUnit && notificationTimeUnit !== "never") {
            dataToSave.time = Number.parseInt(notificationTimeInput);
            dataToSave.unit = notificationTimeUnit;
        }

        try {
            const resp = await api.post(
                `${URLS.serverUrl}${API.saveAutocloseSettings}`,
                dataToSave,
            );

            if (resp.status === 200) {
                const data = resp.data.data as AutocloseState;
                setEnabled(data.enabled);
                setNotificationTimeInput(data.time?.toString() || "30");
                setNotificationTimeUnit(data.unit || "days");
                setToastText("Updated Settings!");
                setToastSymbol(CheckCircledIcon);
                setToastOpen(true);
                setIsEditing(false);
            } else {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
                setToastOpen(true);
            }

            setToastOpen(false);
            window.clearTimeout(timerRef.current);
            timerRef.current = window.setTimeout(() => {
                setToastOpen(true);
            }, 100);
        } catch (error) {
            console.error("Error saving auto-close settings:", error);
            alert("An error occurred while saving settings.");
        }
    };

    if (isLoading) return <p>Loading...</p>;
    if (isError) return <p>Error loading settings</p>;

    return (
        <div>
            <Card className="shadow-none ">
                <CardHeader>
                    <div className="flex flex-row justify-between items-start">
                        <div>
                            <CardTitle>Autoclose Settings</CardTitle>
                            <CardDescription>
                                Set up a duration for tickets to be
                                automatically closed.
                            </CardDescription>
                        </div>
                        <Switch
                            checked={enabled}
                            onCheckedChange={(value) => {
                                setEnabled(value);
                                handleSave(value);
                            }}
                            className="rounded-full"
                        />
                    </div>
                </CardHeader>

                <CardContent className="flex flex-row justify-between items-center gap-4">
                    {enabled && (
                        <div className="flex flex-row items-center gap-2">
                            <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 shadow-sm bg-white h-11 w-fit">
                                <Input
                                    type="number"
                                    value={notificationTimeInput}
                                    onChange={(event) =>
                                        setNotificationTimeInput(
                                            event.target.value,
                                        )
                                    }
                                    className={`px-3 min-w-[5rem] max-w-full text-center bg-transparent border-none outline-none ${
                                        !isEditing
                                            ? "text-gray-700"
                                            : "text-iris10"
                                    }`}
                                    min="1"
                                    style={{
                                        width: `${Math.max(5, notificationTimeInput.length)}rem`,
                                    }}
                                    disabled={!isEditing}
                                />

                                <Separator
                                    orientation="vertical"
                                    className="bg-[#D8D8D8] w-[1px] h-8 mx-3"
                                />

                                <select
                                    value={notificationTimeUnit}
                                    onChange={(event) => {
                                        const value = event.target
                                            .value as TimeUnit;
                                        setNotificationTimeUnit(value);
                                    }}
                                    className={`text-xs ${
                                        !isEditing
                                            ? "text-gray-700"
                                            : "text-iris10"
                                    } bg-white border-none focus:outline-none cursor-pointer`}
                                    disabled={!isEditing}
                                >
                                    <option value="days">days</option>
                                    <option value="hours">hours</option>
                                    <option value="minutes">minutes</option>
                                    <option value="seconds">seconds</option>
                                </select>
                            </div>
                            <div>
                                <Button
                                    onClick={() => setIsEditing(true)}
                                    variant="ghost"
                                >
                                    <Pencil2Icon className="justify-self-end" />
                                </Button>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-end">
                        {isEditing && enabled && (
                            <div className="flex flex-row gap-2">
                                <Button
                                    onClick={() => setIsEditing(false)}
                                    variant="outline"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => handleSave(enabled)}
                                    variant="default"
                                    className="bg-iris10"
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>

            <AssemblyToastProvider
                open={toastOpen}
                toastSymbol={ToastSymbol}
                toastText={toastText}
                setOpen={setToastOpen}
            />
        </div>
    );
}

export default AutocloseCard;
